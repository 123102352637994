import request from './https/https.js'

export function GetAllUserInfo(page, pageSize,) {
    return request({
      url: 'website/news/' + page + '/' + pageSize,
      method: 'get',
    })
  }
  export function GetAllUserInfoNewsType(page, pageSize, newsType = null,) {
    return request({
      url: 'website/news/' + page + '/' + pageSize + '/' + newsType,
      method: 'get',
    })
  }
  export function GetAllUsernewsStatus(page, pageSize, newsStatus = null,) {
    return request({
      url: 'website/news/status/' + page + '/' + pageSize + '/' + newsStatus,
      method: 'get',
    })
  }
  export function GetAllUserInfoNewStatus(page, pageSize, newsType = null, newStatus = null) {
    return request({
      url: 'website/news/' + page + '/' + pageSize + '/' + newsType + '/' + newStatus,
      method: 'get',
    })
  }
  // 查询新闻列表
  export function newsList(page, pageSize, newStatus = null) {
    return request({
      url: 'website/news2/status/' + page + '/' + pageSize + '/' + newStatus,
      method: 'get',
    })
  }
  export function addNews(parameter) {
    return request({
      url: 'website/news',
      method: 'post',
      data: parameter,
    })
  }
  export function acquireNewsInfo(parameter) {
    return request({
      url: 'website/news/find/' + parameter,
      method: 'get',
    })
  }
