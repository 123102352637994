<template>
  <div class="home-box">
    <!-- <div style="width: 100%; height: 0.3333rem"></div> -->
    <!-- 首页login与导航栏 -->
    <div :class="daohangtanFlag ? 'daohangtan' : ''">
      <div v-if="daohangtanFlag" class="menu"><el-menu
      :default-active="activeIndexNew"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      text-color=""
      @close="handleClose">
      
      <el-menu-item @click="shouye" index="2">
        <span slot="title">首页</span>
      </el-menu-item>
      <el-menu-item @click="jishu" index="5">
        <span   slot="title">核心技术</span>
      </el-menu-item>
      <el-submenu index="1">
        <template slot="title">
        
          <span>产品服务</span>
        </template>
        <el-menu-item-group>
          <template slot="title">分组一</template>
          <el-menu-item @click="jop('/home/IndustrialControl')" index="1-1">密文智能工控</el-menu-item>
          <el-menu-item @click="jop('/home/Archive')"  index="1-2">密文数据库</el-menu-item>
          <el-menu-item @click="jop('/home/CloudStorage')"   index="1-3">密文云存储</el-menu-item>
          <el-menu-item @click="jop('/home/Ciphertextface')"   index="1-4">密文人脸识别</el-menu-item>
          <el-menu-item @click="jop('/home/EntranceGuard')"  index="1-5">密文身份识别</el-menu-item>
          <el-menu-item @click="jop('/home/BrainPower')"   index="1-6">智能汽车个人信息保护</el-menu-item>
          <el-menu-item @click="jop('/home/ProductCar')"   index="1-7">网约车密文监管</el-menu-item>
          <el-menu-item @click="jop('/home/Rfid')"   index="1-8">RFID密文文件管理</el-menu-item>
        </el-menu-item-group>
        <!-- <el-menu-item-group title="分组2">
          <el-menu-item index="1-3">选项3</el-menu-item>
        </el-menu-item-group>
        <el-submenu index="1-4">
          <template slot="title">选项4</template>
          <el-menu-item index="1-4-1">选项1</el-menu-item>
        </el-submenu> -->
      </el-submenu>
      <el-submenu index="6">
        <template slot="title">
        
          <span>解决方案</span>
        </template>
        <el-menu-item-group>
          <template slot="title">分组一</template>
          <el-menu-item @click="jop('/home/Privacy')" index="6-1">隐私计算综合解决方案</el-menu-item>
          <el-menu-item @click="jop('/home/DataInteraction')"  index="6-2">数据交易全链路安全解决方案</el-menu-item>
          <el-menu-item @click="jop('/home/GovernmentAffairs')"  index="6-3">政务数据全链路安全解决方案</el-menu-item>
          <el-menu-item @click="jop('/home/Industry')"  index="6-4">工业互联网数据 全链路安全解决方案</el-menu-item>
          <el-menu-item @click="jop('/home/Electricity')"  index="6-5">电力数据全链路安全解决方案</el-menu-item>
          <el-menu-item @click="jop('/home/Finance')"  index="6-6">金融数据全链路安全解决方案</el-menu-item>
          <el-menu-item @click="jop('/home/MedicalTreatment')"  index="6-7">医疗数据全链路安全解决方案</el-menu-item>
        </el-menu-item-group>
        <!-- <el-menu-item-group title="分组2">
          <el-menu-item index="6-3">选项3</el-menu-item>
        </el-menu-item-group>
        <el-submenu index="1-4">
          <template slot="title">选项4</template>
          <el-menu-item index="1-4-1">选项1</el-menu-item>
        </el-submenu> -->
      </el-submenu>
      <el-menu-item @click="xinwen(0)" index="3" >
        <!-- <i class="el-icon-document"></i> -->
        <span slot="title">新闻动态</span>
      </el-menu-item>
      <el-menu-item @click="guanyu" index="4">
        <span slot="title">关于我们</span>
      </el-menu-item>
    </el-menu></div>
      </div>
    <div
      style="
        background-color: transparent;
        width: 100%;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 10;
      "
      :class="pipei[showBackToTop]"
    >

      <div @mouseleave="yichuchanp(0)" :class="isShowyiru ? 'yiru' : ''">
        <div class="home-top">
          <div v-if="!daohangtanFlag" class="home-login"></div>
          <div v-else class="home-login1"></div>
          <div class="home-tab" style="margin-left: -0.0833rem">
            <!-- <el-menu
            :default-active="
              $route.path == '/home/JoinUs'
                ? '/home/CompanyIntroduction'
                : $route.path
            "
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
          >
            <el-menu-item
              v-for="item in Navigation"
              :key="item.url"
              :index="item.url"
              @click="$router.push(item.url)"
            >
              {{ item.name }}
              <el-submenu
                :index="item.url"
                v-if="item.submenuItems && item.submenuItems.length > 0"
              >
                <template slot="title">{{ item.name }}</template>
                <el-menu-item
                  v-for="subItem in item.submenuItems"
                  :key="subItem.url"
                  :index="subItem.url"
                  @click="$router.push(subItem.url)"
                >
                  {{ subItem.name }}
                </el-menu-item>
              </el-submenu>
            </el-menu-item>
          </el-menu> -->
            <el-menu
              :default-active="activeIndex"
              class="el-menu-demo"
              mode="horizontal"
              @select="handleSelect"
            >
              <el-menu-item @click="shouye" index="1"
                ><span @mouseenter="yiruchanp(3)">首页</span></el-menu-item
              >
              <el-menu-item @click="jishu" index="5"
                ><span @mouseenter="yiruchanp(3)">核心技术</span></el-menu-item
              >
              <el-menu-item @click="chanping(1)" index="2"
                ><span @mouseenter="yiruchanp(1)">产品服务</span></el-menu-item
              >
              <el-menu-item @click="jiejue(0)" index="6"
                ><span @mouseenter="yiruchanp(0)">解决方案</span></el-menu-item
              >
              <el-menu-item @click="xinwen(0)" index="3"
                ><span @mouseenter="yiruchanp(3)">新闻动态</span></el-menu-item
              >
              <el-menu-item @click="guanyu" index="4"
                ><span @mouseenter="yiruchanp(3)">关于我们</span></el-menu-item
              >
              <!-- <el-submenu index="4">
                <template slot="title">关于我们</template>
                <el-menu-item
                  @click="$router.push('/home/CompanyIntroduction')"
                  index="4-1"
                  >公司介绍</el-menu-item
                >
                <el-menu-item @click="$router.push('/home/JoinUs')" index="4-2"
                  >加入我们</el-menu-item
                >
              </el-submenu> -->
            </el-menu>
          </div>
          <div class="daohang">
            <div class="connection" @click="$router.push('/Customization')">
            定制化服务
          </div>
          <div v-if="!daohangtanFlag" @click="daohangtanFlag = true"  class="shoujidaohang" :class="$route.path == '/home/HomePage' ? 'shoujidaohang': 'shoujidaohang2'">
    
            <!-- <i class="el-icon-s-operation"></i> -->
          </div>
          <div v-else @click="daohangtanFlag = false"  class="shoujidaohangNo">
          </div>
          </div>
    
          <!-- <div
          v-if="!ismenu"
          @click="drawer = true"
          style="float: right; margin-right: 0px; font-size: 36px; color:#1d2088 "
        >
          <i class="el-icon-s-unfold"></i>
        </div> -->
          <el-drawer
            title="我是标题"
            :visible.sync="drawer"
            :with-header="false"
          >
            <div style="width: 100%; height: 0.2083rem">
              <img
                style="width: 0.5rem; margin-left: 0.0833rem"
                src="../assets/image/home-login.png"
                alt=""
              />
            </div>
            <el-row class="tac">
              <el-col :span="12">
                <el-menu default-active="2" class="el-menu-vertical-demo">
                  <el-menu-item
                    index="1"
                    @click="$router.push('/home/HomePage')"
                  >
                    <!-- <i class="el-icon-s-home"></i> -->
                    <span slot="title">首页</span>
                  </el-menu-item>
                  <el-menu-item
                    index="2"
                    @click="$router.push('/home/ProductCenter')"
                  >
                    <!-- <i class="el-icon-s-management"></i> -->
                    <span slot="title">产品中心</span>
                  </el-menu-item>
                  <el-menu-item
                    index="3"
                    @click="$router.push('/home/NewsTrends')"
                  >
                    <!-- <i class="el-icon-news"></i> -->
                    <span slot="title">新闻动态</span>
                  </el-menu-item>
                  <el-menu-item
                    index="3"
                    @click="$router.push('/home/NewsTrends')"
                  >
                    <!-- <i class="el-icon-news"></i> -->
                    <span slot="title">新闻动态</span>
                  </el-menu-item>
                  <el-menu-item
                    index="4"
                    @click="$router.push('/home/CompanyIntroduction')"
                  >
                    <!-- <i class="el-icon-office-building"></i> -->
                    <span slot="title">公司介绍</span>
                  </el-menu-item>
                  <el-menu-item index="5" @click="$router.push('/home/JoinUs')">
                    <!-- <i class="el-icon-user-solid"></i> -->
                    <span slot="title">加入我们</span>
                  </el-menu-item>
                </el-menu>
              </el-col>
            </el-row>
          </el-drawer>
        </div>
        <div v-if="isShowyiru && tankuangneirong == 1" class="chanpingdaohang">
          <img src="../assets/image/anquan.jpg" alt="" />
          <h2>数据安全产品</h2>
        </div>
        <div v-if="isShowyiru && tankuangneirong == 1" class="erjidaohang">
          <ul>
            <li @click="erjichanp(7)">密文智能工控</li>
            <li @click="erjichanp(6)">密文数据库</li>
            <li @click="erjichanp(8)">密文云存储</li>
            <li @click="erjichanp(2)">密文人脸识别</li>
            <li @click="erjichanp(5)">密文身份识别</li>
            <li @click="erjichanp(3)">智能汽车个人信息保护</li>
            <li @click="erjichanp(4)">网约车密文监管</li>
            <li @click="erjichanp(1)">RFID密文文件管理</li>
          
          </ul>
        </div>
        <div v-if="isShowyiru && tankuangneirong == 0" class="chanpingdaohang">
          <img src="../assets/image/anquan.jpg" alt="" />
          <h2>专项解决方案</h2>
        </div>
        <div v-if="isShowyiru && tankuangneirong == 0" class="erjidaohang">
          <ul>
            <li @click="erjijiejeu(7)">隐私计算综合解决方案</li>
            <li @click="erjijiejeu(6)">数据交易全链路安全解决方案</li>
            <li @click="erjijiejeu(2)">政务数据全链路安全解决方案</li>
            <li @click="erjijiejeu(5)">工业互联网数据 全链路安全解决方案</li>
            <li @click="erjijiejeu(3)">电力数据全链路安全解决方案</li>
            <li @click="erjijiejeu(4)">金融数据全链路安全解决方案</li>
            <li @click="erjijiejeu(1)">医疗数据全链路安全解决方案</li>
         
          </ul>
        </div>
      </div>

      <!-- <div v-if="!ismenu" class="home-top" style="width: 5rem; display:flex;justify-content: end;">
        <el-menu
            :default-active="
              $route.path == '/home/JoinUs'
                ? '/home/CompanyIntroduction'
                : $route.path
            "
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
          >
            <el-menu-item
              v-for="item in Navigation"
              :key="item.url"
              :index="item.url"
              @click="$router.push(item.url)"
            >
              {{ item.name }}
              <el-submenu
                :index="item.url"
                v-if="item.submenuItems && item.submenuItems.length > 0"
              >
                <template slot="title">{{ item.name }}</template>
                <el-menu-item
                  v-for="subItem in item.submenuItems"
                  :key="subItem.url"
                  :index="subItem.url"
                  @click="$router.push(subItem.url)"
                >
                  {{ subItem.name }}
                </el-menu-item>
              </el-submenu>
            </el-menu-item>
          </el-menu>
      </div> -->
    </div>
    <router-view></router-view>

    <!-- 底部信息 -->
    <div class="info-box">
      <div class="info-content">
        <div class="login-bottom"></div>
        <div class="daohang">
          <ul>
            <li class="biaoti" style="cursor: inherit">网页导航</li>
            <li @click="scrollToTop"><span>首页</span></li>
            <li @click="$router.push('/home/ProductCenter')">
              <span>核心技术</span>
            </li>
            <li @click="$router.push('/home/Rfid')"><span>产品服务</span></li>
            <li @click="$router.push('/home/MedicalTreatment')"><span>解决方案</span></li>
            <li @click="$router.push('/home/NewsTrends')">
              <span>新闻动态</span>
            </li>
            <li @click="$router.push('/home/CompanyIntroduction')">
              <span>关于我们</span>
            </li>
          </ul>
        </div>
        <div class="lianxi">
          <ul>
            <li class="biaoti">联系我们</li>
            <li>
              <i class="el-icon-location-outline"></i>
              公司地址：北京市大兴区盛坊路1号北京隐算科技有限公司
            </li>
            <li><i class="el-icon-phone-outline"></i> 联系方式：18310976836</li>
            <li>
              <i class="el-icon-message"></i> 邮箱地址：liran@yinsuankeji.com
            </li>
            <li class="an1">
              <a
                style="color: #fff"
                href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011502006734"
                target="_blank"
                title="公安机关"
                ><img
                  src="../assets/image/publicSecurityRecord.png"
                  style="vertical-align: middle"
                  alt=""
                />
                京公网安备 11011502006734号</a
              >
            </li>
            <li class="an">
              <a
                style="color: #fff"
                href="https://beian.miit.gov.cn/#/Integrated/index"
                target="_blank"
                title="工信部"
                textvalue="京ICP备2023019789号"
              >
              
                <!-- <img
                  src="../assets/image/publicSecurityRecord.png"
                  style="float: left; vertical-align: middle"
                  alt=""
                /> -->
                <!-- <img
                  src=""
                  alt=""
                  style="float: left; vertical-align: middle"
                /> -->
                <div  class="info-record"><span class="img"></span>备案号 : 京ICP备2023019789号</div></a
              >
            </li>
          </ul>
        </div>
        <div class="ma">
          <div class="title">扫码关注公众号</div>
        </div>
        <!-- <h2>链接</h2>
          <ul>
            <li
              @click="$router.push('/home/productCenter')"
              style="cursor: pointer"
            >
              产品介绍
            </li>
            <li
              @click="$router.push('/home/NewsTrends')"
              style="cursor: pointer"
            >
              新闻动态
            </li>
            <li
              @click="$router.push('/home/CompanyIntroduction')"
              style="cursor: pointer"
            >
              关于我们
            </li>
          </ul> -->
      </div>
      <!-- <div class="info-right">
        <h2 style="margin-top: 0; font-size: 0.1rem">联系方式</h2>
        <ul>
          <li>
            <i class="el-icon-location-outline"></i>
            公司地址：北京市大兴区盛坊路1号北京隐算科技有限公司
          </li>
          <li><i class="el-icon-phone-outline"></i> 联系方式：13801309931</li>
          <li>
            <i class="el-icon-message"></i> 邮箱地址：liyi@yinsuankeji.com
          </li>
        </ul>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      daohangtanFlag: false,
      tankuangneirong: "", // 弹框内容标识
      isShowyiru: false,
      showBackToTop: 0, // 控制返回顶部按钮显示与隐藏
      pipei: {
        0: "",
        1: "bianhua",
        2: "bianhua2",
        3: "bianhua3",
      },
      // 导航标识
      tabflag: false,
      activeIndex: "1",
      activeIndexNew: '999',
      ismenu: true,
      screenWidth: 0,
      drawer: false,
      // 导航栏数据
      Navigation: [
        {
          id: 1,
          name: "首页",
          url: "/home/HomePage",
          active: true,
          showSubmenu: false,
          submenuItems: [],
        },
        {
          id: 2,
          name: "产品中心",
          url: "/home/ProductCenter",
          active: false,
          showSubmenu: false,
          submenuItems: [],
        },
        {
          id: 3,
          name: "新闻动态",
          url: "/home/NewsTrends",
          active: false,
          showSubmenu: false,
          submenuItems: [],
        },
        {
          id: 4,
          name: "关于我们",
          url: "/home/CompanyIntroduction",
          active: false,
          showSubmenu: true,
          submenuItems: [
            {
              id: 1,
              name: "公司介绍",
              url: "/home/CompanyIntroduction",
            },
            { id: 2, name: "加入我们", url: "/home/JoinUs" },
          ],
        },
      ],
      activeIndexRouter: {
        "/home/HomePage": "1",
        "/home/ProductCenter": "2",
        "/home/NewsTrends": "3",
        "/home/CompanyIntroduction": "4",
      },
    };
  },
  created() {
    this.activeIndex = window.sessionStorage.getItem("active");
    this.showBackToTop = window.sessionStorage.getItem("showBackToTop");
    console.log(window.sessionStorage.getItem("active"));
    if (this.$route.path) {
      // this.activeIndex = this.activeIndexRouter[this.$route.path];
    }
  },
  mounted() {
    console.log(this.$route.path);
    // if (this.$route.path == "/home/HomePage") {
    //   this.showBackToTop = 3;
    //   this.tabflag = true;
    // } else {
    //   this.showBackToTop = 2;
    //   this.tabflag = false;
    // }
    // 监听页面滚动事件
    window.addEventListener("scroll", this.handleScroll);
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  watch: {
    $route(to, from) {
      console.log("新闻");
      console.log(window.sessionStorage.getItem("active"));
      console.log(window.sessionStorage.getItem("showBackToTop"));
      console.log(window.sessionStorage.getItem("tabflag"));
      this.activeIndex = window.sessionStorage.getItem("active");
      this.tabflag = Boolean(window.sessionStorage.getItem("tabflag"));
      console.log(this.tabflag);
      setTimeout(() => {
        this.showBackToTop = window.sessionStorage.getItem("showBackToTop");
      }, 100);
      // if (to.path == from.path) return;
      // // 监听路由的变化
      // if (from.path == "/home/HomePage") {
      //   this.tabflag = false;
      //   this.showBackToTop = 2;
      // } else {
      //   this.showBackToTop = 0;
      //   this.tabflag = true;
      // }
      // console.log(`Route changed from ${from.path} to ${to.path}`);
      // 在这里可以执行一些逻辑，比如更新数据、发送请求等
    },
  },
  methods: {
    // 手机版跳转
    jop(val) {
      this.$router.push(val);
      this.daohangtanFlag = false;
    },
    // 点击二级产品
    erjichanp(val) {
      this.activeIndex = "2";
      this.showBackToTop = 2;
      this.tabflag = false;
      if (val == 4) {
        this.$router.push("/home/ProductCar");
      } else if (val == 2) {
        this.$router.push("/home/Ciphertextface");
      } else if (val == 1) {
        this.$router.push("/home/Rfid");
      } else if (val == 3) {
        this.$router.push("/home/BrainPower");
      } else if (val == 5) {
        this.$router.push("/home/EntranceGuard");
      } else if (val == 6) {
        this.$router.push("/home/Archive");
      } else if (val == 7) {
        this.$router.push("/home/IndustrialControl");
      } else if (val == 8) {
        this.$router.push("/home/CloudStorage");
      }
    },
    // 点击二级解决
    erjijiejeu(val) {
      this.activeIndex = "6";
      this.showBackToTop = 2;
      this.tabflag = false;
      if (val == 4) {
        this.$router.push("/home/Finance");
      } else if (val == 2) {
        this.$router.push("/home/GovernmentAffairs");
      } else if (val == 1) {
        this.$router.push("/home/MedicalTreatment");
      } else if (val == 3) {
        this.$router.push("/home/Electricity");
      } else if (val == 5) {
        this.$router.push("/home/Industry");
      } else if (val == 6) {
        this.$router.push("/home/DataInteraction");
      } else if (val == 7) {
        this.$router.push("/home/Privacy");
      }
    },
    yiruchanp(val) {
      if (val == 1 || val == 0) {
        this.tankuangneirong = val;
        console.log("移入");
        this.isShowyiru = true;
      } else {
        this.isShowyiru = false;
      }
    },
    yichuchanp() {
      console.log("移出");
      this.isShowyiru = false;
    },
    shouye() {
      this.$router.push("/home/HomePage");
      this.tabflag = true;
      this.showBackToTop = 3;
      this.daohangtanFlag = false;
    },
    jishu() {
      this.$router.push("/home/ProductCenter");
      this.showBackToTop = 2;
      this.tabflag = false;
      this.daohangtanFlag = false;
    },
    chanping(val) {
      if (val == 1 || val == 0) {
        this.tankuangneirong = val;
        console.log("移入");
        this.isShowyiru = true;
      } else {
        this.isShowyiru = false;
      }
      this.$router.push("/home/Rfid");
      this.showBackToTop = 2;
      this.tabflag = false;
    },
    jiejue(val) {
      if (val == 1 || val == 0) {
        this.tankuangneirong = val;
        console.log("移入");
        this.isShowyiru = true;
      } else {
        this.isShowyiru = false;
      }
      this.$router.push("/home/MedicalTreatment");
      this.showBackToTop = 2;
      this.tabflag = false;
    },
    xinwen() {
      this.$router.push("/home/NewsTrends");
      this.showBackToTop = 2;
      this.tabflag = false;
      this.daohangtanFlag = false;
    },
    guanyu() {
      this.$router.push("/home/CompanyIntroduction");
      this.showBackToTop = 2;
      this.tabflag = false;
      this.daohangtanFlag = false;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 平滑滚动效果
      });
    },
    handleScroll() {
      // 当页面滚动超过200px时，显示返回顶部按钮，否则隐藏
      if (window.pageYOffset > 200) {
        this.showBackToTop = 1;
      } else {
        this.showBackToTop = window.sessionStorage.getItem("showBackToTop");
      }
    },
    handleResize() {
      this.screenWidth = window.innerWidth;
      if (this.screenWidth < 1700) {
        this.ismenu = false;
      } else {
        this.ismenu = true;
      }
    },
    handleSelect(key, keyPath) {
      // console.log(key, keyPath);
    },
  },
  handleOpenIn(key, keyPath) {
    console.log(key, keyPath);
  },
  handleCloseIn(key, keyPath) {
    console.log(key, keyPath);
  },
  beforeRouteUpdate(to, from, next) {
    // 在路由变化时，重置所有菜单项的 active 状态
    // this.menuItems.forEach(item => {
    //   item.active = false;
    // });

    // 根据当前路由，设置对应菜单项的 active 状态
    const currentPath = to.path;
    // console.log(currentPath);
    // const menuItem = this.menuItems.find(item => item.url === currentPath);
    // if (menuItem) {
    //   menuItem.active = true;
    // }

    next();
  },
  beforeDestroy() {
    // 组件销毁前移除滚动事件监听
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="less" scoped>
/* 在小屏幕上的样式 */
@media (max-width: 767px) {
  /* 添加适合小屏幕的样式 */
  body {
    font-family: "myfont";
  }
  .home-box {
    font-family: myfont;
    background: linear-gradient(135deg, #eef3fd 0%, #eef3fd 100%);
    background: #FAFCFF;
    // text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    .daohangtan {
      position: fixed;
      z-index: 5;
      width: 100%;
      height: 100%;
      background: #fafcff;
      ::v-deep .el-submenu__title {
        height: 205px;
        line-height: 205px;
        font-size: 61px;
        //  display: flex;
        //  align-items: center;
        i {
          font-size: 61px;
          width: 70px;
          height: 205px;
          line-height: 205px;
          vertical-align: middle;
          font-family: "myfont";
        }
        .el-submenu__icon-arrow {
          line-height: 1;
          font-size: 61px;
          margin-right: 80px;
          font-family: "myfont";
        }
      }
      // li {
      //   height: 205px;
      //   line-height: 205px;
      //   i {
      //     font-size: 61px;
      //     vertical-align: middle;
      //   }
      // }
      .el-menu {
        background: #fafcff;
      }
      ::v-deep .el-submenu__title {
        color: #202124;
        font-family: 'refont';
      }

      ::v-deep .el-menu-item {
        border-bottom: none;
        font-size: 61px;
        font-family: "refont";
        height: 205px;
        line-height: 205px;
        background: #fafcff;
        color: #202124;
        i {
          font-size: 61px;
          width: 70px;
          height: 205px;
          line-height: 205px;
          vertical-align: middle;
        }
        // font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        // font-weight: bold;
        // color: rgba(0, 0, 0, .6) !important;
        // color: #000000 !important;
      }
     ::v-deep .el-menu--inline .el-menu-item {
        color: #666666 !important;
      }
      .el-menu--inline  ::v-deep  .el-menu-item {
        color: #666666;
      }
      // ::v-deep .el-submenu__title {
      //   height: 205px !important;
      //   line-height: 205px;
      //   font-size: 61px;
      //   font-family: "refont";
      // }
    }
    .menu {
      margin: 287px 0 0;
      font-size: 61px !important;
    }

    .home-login1 {
      // position: absolute;
      width: 416px;
      height: 128px;
      // left: 50px;
      // top: 13px;
      background: url("../assets/image/home-login2.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
    .yiru {
      display: none;
      width: 100%;
      height: 332px;
      background: #ffffff;
      border-radius: 0px 0px 0px 0px;
      .home-login {
        // position: absolute;
        width: 416px;
        height: 128px;
        // left: 50px;
        // top: 13px;
        background: url("../assets/image/home-login2.png");
        background-repeat: no-repeat;
        background-size: cover;
      }

      .el-menu-item {
        border-bottom: none;
        font-size: 14px;
        color: #000;
        font-family: "refont";
        // font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        // font-weight: bold;
        // color: rgba(0, 0, 0, .6) !important;
        // color: #000000 !important;
      }
      .el-menu--horizontal > .el-submenu /deep/ .el-submenu__title {
        font-size: 14px;
        font-family: "refont";
        font-weight: 500;
        color: #000;
        
      }
      .chanpingdaohang {
        width: 1200px;
        margin: 0 auto;
        padding: 30px 0 25px;
        box-sizing: border-box;
        h2 {
          display: inline-block;
          font-size: 14px;
          color: #202124;
          font-family: "blodfont";
          margin: 0;
        }
        img {
          margin-right: 6px;
          width: 24px;
          height: 24px;
          vertical-align: middle;
        }
      }
      .erjidaohang {
        ul {
          width: 1200px;
          margin: 0 auto;
          overflow: hidden;
          padding: 0;
          li {
            float: left;
            width: 282px;
            height: 56px;
            line-height: 56px;
            text-align: center;
            font-size: 16px;
            color: #202124;
            font-family: "myfont";
          }
          li:hover {
            cursor: pointer;
            background: url("../assets/image/Slice 89.jpg");
            background-repeat: no-repeat;
            background-size: cover;
          }
        }
      }
    }

    // 底部信息
    .info-box {
      display: flex;
      bottom: 0;
      width: 100%;
      height: 2058px;
      background: linear-gradient(135deg, #252d45 0%, #1e2843 100%);

      .info-content {
        position: relative;
        overflow: hidden;
        color: #fff;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        margin: 0px auto;
        .login-bottom {
          display: none;
          position: absolute;
          left: 0;
          top: 44px;
          width: 143px;
          height: 44px;
          background: url("../assets/image/home-login.png");
          background-repeat: no-repeat;
          background-size: cover;
        }
        .daohang {
          display: none;
          position: absolute;
          left: 254px;
          top: 61px;
          font-size: 61px;
          li {
            cursor: pointer;
          }
          span:hover {
            border-bottom: 1px solid #fff;
          }
        }
        .biaoti {
          display: none;
          font-size: 14px;
          margin-bottom: 32px;
          cursor: none;
        }
        .lianxi {
          position: absolute;
          left: 174px;
          top: 1111px;
          font-size: 61px;
        }
        .ma {
          position: absolute;
          left: 50%;
          top: 184px;
          width: 666px;
          height: 666px;
          background: #f4f4f4;
          transform: translateX(-50%);
          background: url("../assets/image/erweima.png");
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: 0px 0px 0px 0px;
          .title {
            position: absolute;
            bottom: -140px;
            left: 50%;
            width: 100%;
            text-align: center;
            transform: translateX(-50%);
            font-size: 61px;
            color: #ffffff;
          }
        }
        ul {
          margin: 0;
          padding: 0;

          li {
            font-size: 61px;
            margin-bottom: 22px;
            line-height: 80px;
            i {
              display: none;
              width: 15px;
              height: 16px;
              margin-right: 10px;
            }
            img {
              display: none;
              width: 15px;
              height: 16px;
              margin-right: 10px;
            }
            .img {
              display: none;
              display: inline-block;
              width: 15px;
              height: 16px;
              margin-right: 12px;
            }
          }
          .an1 {
            color: #9b9da5;
            margin-top: 82px;
            a {
              color: #9b9da5 !important;
            }
          }
          .an {
            color: #9b9da5;
            a {
              color: #9b9da5 !important;
            }
            .info-record {
              margin-left: -25px;
            }
          }
        }

        > div {
          float: left;
        }
        .info-left {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 354px;
          height: 160px;
          .info-login {
            width: 254px;
            height: 80px;
            background: url("../assets/image/info-login.png");
            background-repeat: no-repeat;
            background-size: cover;
          }
          .info-record {
            font-size: 14px;
          }
        }
        .info-middle {
          width: 94px;
          h2 {
            font-size: 24px;
            margin-top: 0;
          }
        }
        .info-right {
          i {
            margin-right: 5px;
          }
        }
      }
    }
  }
  .home-login {
    // position: absolute;
    width: 416px;
    height: 128px;
    // left: 50px;
    // top: 13px;
    background: url("../assets/image/home-login.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  // 滚动200px 导航栏变化样式
  // .bianhua {
  //   background: rgba(0, 0, 0, 0.6) !important;
  // }
  .bianhua {
    background: #fff !important;
    .home-login {
      // position: absolute;
      width: 416px;
      height: 128px;
      // left: 50px;
      // top: 13px;
      background: url("../assets/image/home-login2.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
    .el-menu-item {
      border-bottom: none;
      font-size: 14px;
      color: #000;
      font-family: "refont";
      // font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      // font-weight: bold;
      // color: rgba(0, 0, 0, .6) !important;
      // color: #000000 !important;
    }
    .el-menu--horizontal > .el-submenu /deep/ .el-submenu__title {
      font-size: 14px;
      font-family: "refont";
      font-weight: 500;
      color: #000;
    }
  }
  .bianhua3 {
    background: transparent !important;
    .home-login {
      // position: absolute;
      width: 416px;
      height: 128px;
      // left: 50px;
      // top: 13px;
      background: url("../assets/image/home-login.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
    .el-menu-item {
      border-bottom: none;
      font-size: 14px;
      color: #fff;
      font-family: "refont";
      // font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      // font-weight: bold;
      // color: rgba(0, 0, 0, .6) !important;
      // color: #000000 !important;
    }
    .el-menu--horizontal > .el-submenu /deep/ .el-submenu__title {
      font-size: 14px;
      font-family: "refont";
      font-weight: 500;
      color: #000;
    }
  }
  .bianhua2 {
    background: transparent !important;
    .home-login {
      // position: absolute;
      width: 416px;
      height: 128px;
      // left: 50px;
      // top: 13px;
      background: url("../assets/image/home-login2.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
    .el-menu-item {
      border-bottom: none;
      font-size: 14px;
      color: #000;
      font-family: "refont";
      // font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      // font-weight: bold;
      // color: rgba(0, 0, 0, .6) !important;
      // color: #000000 !important;
    }
    .el-menu--horizontal > .el-submenu /deep/ .el-submenu__title {
      font-size: 14px;
      font-family: "refont";
      font-weight: 500;
      color: #000;
    }
  }
  .home-top {
    position: fixed;
    width: 100%;
    padding: 82px;
    box-sizing: border-box;
    margin: 0 auto;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    // height: 80px;
    line-height: 80px;

    .home-tab {
      display: none;

      float: left;
    }
    .daohang {
      display: flex;
      align-items: center;
      .connection {
        cursor: pointer;
        width: 451px;
        height: 143px;
        background: linear-gradient(
            189deg,
            rgba(0, 178, 255, 0) 0%,
            #00ffff 100%
          ),
          linear-gradient(5deg, rgba(0, 194, 255, 0) 0%, #1400ff 100%),
          linear-gradient(327deg, rgba(175, 74, 255, 0) 0%, #af4aff 100%),
          #003bff;
        border-radius: 41px 41px 41px 41px;
        color: #fff;
        line-height: 143px;
        text-align: center;
        font-size: 61px;
        font-family: "myfont";
      }
      .shoujidaohang {
        margin-left: 41px;
        width: 123px;
        height: 123px;
        color: #fff;
        vertical-align: middle;
        background: url("../assets/image/Slice 105@2x.png");
        background-repeat: no-repeat;
        background-size: cover;
      }
      .shoujidaohang2 {
        margin-left: 41px;
        width: 123px;
        height: 123px;
        color: #fff;
        vertical-align: middle;
        background: url("../assets/image/Slice 105@2x (2).png");
        background-repeat: no-repeat;
        background-size: cover;
      }
      .shoujidaohangNo {
        margin-left: 41px;
        width: 123px;
        height: 123px;
        color: #fff;
        vertical-align: middle;
        background: url("../assets/image/Slice 105@2x (1).png");
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }

  // .el-carousel__container {
  //   height: aspectRatio + 'px';
  // }
  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  .el-carousel__item {
    // position: absolute;
    width: 100%;
  }
  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }
  // .el-menu {
  //   background: transparent;
  // }
  // .el-menu.el-menu--horizontal {
  //   position: relative;
  //   border: none;
  // }
  // .el-menu--horizontal > .el-menu-item.is-active {
  //   background: transparent;
  //   border-bottom: none;
  //   font-size: 61px;
  //   color: #016eff;
  //   font-weight: bold;
  //   border-bottom: 2px solid #016eff;
  //   font-family: "myfont";
  // }
  // .el-menu--horizontal > .el-menu-item:hover {
  //   background: transparent;
  //   color: #016eff;
  //   font-size: 61px;
  //   font-weight: bold;
  //   border-bottom: 2px solid #016eff;
  //   font-family: "myfont";
  // }

  // /deep/ .el-submenu {
  //   // opacity: 0;
  // }
  // .el-menu--horizontal > .el-menu-item.is-active:before {
  //   // content: "";
  //   // width: 0px; /* 图片宽度 */
  //   // height: 0px; /* 图片高度 */
  //   // // background-image: url("../assets/image/home-tab-active.png"); /* 图片路径 */
  //   // // background-size: cover; /* 背景尺寸适应 */
  //   // border: none;
  //   // border-bottom: 10px solid #003bff;
  //   // border-top: 0px solid #003bff;
  //   // border-right: 5px solid transparent;
  //   // border-left: 5px solid transparent;
  //   // position: absolute;
  //   // left: 50%;
  //   // top: 40px;
  //   // z-index: 999;
  //   // transform: translateX(-50%);
  // }
  // .el-submenu.is-active {
  //   background: transparent;
  //   position: relative;
  //   color: #016eff;
  //   font-family: "myfont";
  //   border-bottom: 2px solid #016eff;
  // }
}
/* 在中屏幕上的样式 */
@media (min-width: 768px) and (max-width: 1023px) {
  /* 添加适合中屏幕的样式 */
  body {
    font-family: "myfont";
  }
  .home-box {
    font-family: myfont;
    background: linear-gradient(135deg, #eef3fd 0%, #eef3fd 100%);
    // text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    .yiru {
      width: 100%;
      height: 332px;
      background: #ffffff;
      border-radius: 0px 0px 0px 0px;
      .home-login {
        // position: absolute;
        width: 148px;
        height: 44px;
        // left: 50px;
        // top: 13px;
        background: url("../assets/image/home-login2.png");
        background-repeat: no-repeat;
        background-size: cover;
      }
      .el-menu-item {
        border-bottom: none;
        font-size: 14px;
        color: #000;
        font-family: "refont";
        // font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        // font-weight: bold;
        // color: rgba(0, 0, 0, .6) !important;
        // color: #000000 !important;
      }
      .el-menu--horizontal > .el-submenu /deep/ .el-submenu__title {
        font-size: 14px;
        font-family: "refont";
        font-weight: 500;
        color: #000;
      }
      .chanpingdaohang {
        width: 1200px;
        margin: 0 auto;
        padding: 30px 0 25px;
        box-sizing: border-box;
        h2 {
          display: inline-block;
          font-size: 14px;
          color: #202124;
          font-family: "blodfont";
          margin: 0;
        }
        img {
          margin-right: 6px;
          width: 24px;
          height: 24px;
          vertical-align: middle;
        }
      }
      .erjidaohang {
        ul {
          width: 1200px;
          margin: 0 auto;
          overflow: hidden;
          padding: 0;
          li {
            float: left;
            width: 282px;
            height: 56px;
            line-height: 56px;
            text-align: center;
            font-size: 16px;
            color: #202124;
            font-family: "myfont";
          }
          li:hover {
            cursor: pointer;
            background: url("../assets/image/Slice 89.jpg");
            background-repeat: no-repeat;
            background-size: cover;
          }
        }
      }
    }

    // 底部信息
    .info-box {
      display: flex;
      bottom: 0;
      width: 100%;
      height: 418px;
      background: linear-gradient(135deg, #252d45 0%, #1e2843 100%);

      .info-content {
        position: relative;
        overflow: hidden;
        color: #fff;
        box-sizing: border-box;
        width: 1200px;
        height: 100%;
        margin: 0px auto;
        .login-bottom {
          position: absolute;
          left: 0;
          top: 44px;
          width: 143px;
          height: 44px;
          background: url("../assets/image/home-login.png");
          background-repeat: no-repeat;
          background-size: cover;
        }
        .daohang {
          position: absolute;
          left: 254px;
          top: 61px;
          font-size: 12px;
          li {
            cursor: pointer;
          }
          span:hover {
            border-bottom: 1px solid #fff;
          }
        }
        .biaoti {
          font-size: 14px;
          margin-bottom: 32px;
          cursor: none;
        }
        .lianxi {
          position: absolute;
          left: 409px;
          top: 61px;
        }
        .ma {
          position: absolute;
          left: 839px;
          top: 66px;
          width: 140px;
          height: 140px;
          background: #f4f4f4;

          background: url("../assets/image/erweima.png");
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: 0px 0px 0px 0px;
          .title {
            position: absolute;
            bottom: -24px;
            left: 50%;
            width: 100%;
            text-align: center;
            transform: translateX(-50%);
            font-size: 12px;
            color: #ffffff;
          }
        }
        ul {
          margin: 0;
          padding: 0;
        }
        ul li {
          font-size: 12px;
          margin-bottom: 22px;
          i {
            width: 15px;
            height: 16px;
            margin-right: 10px;
          }
          img {
            width: 15px;
            height: 16px;
            margin-right: 10px;
          }
          .img {
            display: inline-block;
            width: 15px;
            height: 16px;
            margin-right: 12px;
          }
        }
        > div {
          float: left;
        }
        .info-left {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 354px;
          height: 160px;
          .info-login {
            width: 254px;
            height: 80px;
            background: url("../assets/image/info-login.png");
            background-repeat: no-repeat;
            background-size: cover;
          }
          .info-record {
            font-size: 14px;
          }
        }
        .info-middle {
          width: 94px;
          h2 {
            font-size: 24px;
            margin-top: 0;
          }
        }
        .info-right {
          i {
            margin-right: 5px;
          }
        }
      }
    }
  }
  .home-login {
    // position: absolute;
    width: 148px;
    height: 44px;
    // left: 50px;
    // top: 13px;
    background: url("../assets/image/home-login.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  // 滚动200px 导航栏变化样式
  // .bianhua {
  //   background: rgba(0, 0, 0, 0.6) !important;
  // }
  .bianhua {
    background: #fff !important;
    .home-login {
      // position: absolute;
      width: 148px;
      height: 44px;
      // left: 50px;
      // top: 13px;
      background: url("../assets/image/home-login2.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
    .el-menu-item {
      border-bottom: none;
      font-size: 14px;
      color: #000;
      font-family: "refont";
      // font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      // font-weight: bold;
      // color: rgba(0, 0, 0, .6) !important;
      // color: #000000 !important;
    }
    .el-menu--horizontal > .el-submenu /deep/ .el-submenu__title {
      font-size: 14px;
      font-family: "refont";
      font-weight: 500;
      color: #000;
    }
  }
  .bianhua3 {
    background: transparent !important;
    .home-login {
      // position: absolute;
      width: 148px;
      height: 44px;
      // left: 50px;
      // top: 13px;
      background: url("../assets/image/home-login.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
    .el-menu-item {
      border-bottom: none;
      font-size: 14px;
      color: #fff;
      font-family: "refont";
      // font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      // font-weight: bold;
      // color: rgba(0, 0, 0, .6) !important;
      // color: #000000 !important;
    }
    .el-menu--horizontal > .el-submenu /deep/ .el-submenu__title {
      font-size: 14px;
      font-family: "refont";
      font-weight: 500;
      color: #000;
    }
  }
  .bianhua2 {
    background: transparent !important;
    .home-login {
      // position: absolute;
      width: 148px;
      height: 44px;
      // left: 50px;
      // top: 13px;
      background: url("../assets/image/home-login2.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
    .el-menu-item {
      border-bottom: none;
      font-size: 14px;
      color: #000;
      font-family: "refont";
      // font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      // font-weight: bold;
      // color: rgba(0, 0, 0, .6) !important;
      // color: #000000 !important;
    }
    .el-menu--horizontal > .el-submenu /deep/ .el-submenu__title {
      font-size: 14px;
      font-family: "refont";
      font-weight: 500;
      color: #000;
    }
  }
  .home-top {
    width: 1200px;
    margin: 0 auto;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    height: 80px;
    line-height: 80px;

    .home-tab {
      float: left;
    }
    .daohang {
      .connection {
        cursor: pointer;
        width: 100px;
        height: 32px;
        background: linear-gradient(
            189deg,
            rgba(0, 178, 255, 0) 0%,
            #00ffff 100%
          ),
          linear-gradient(5deg, rgba(0, 194, 255, 0) 0%, #1400ff 100%),
          linear-gradient(327deg, rgba(175, 74, 255, 0) 0%, #af4aff 100%),
          #003bff;
        border-radius: 8px 8px 8px 8px;
        color: #fff;
        line-height: 32px;
        text-align: center;
        font-size: 14px;
        font-family: "myfont";
      }
      .shoujidaohang {
        display: none;
      }
    }
  }

  // .el-carousel__container {
  //   height: aspectRatio + 'px';
  // }
  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  .el-carousel__item {
    // position: absolute;
    width: 100%;
  }
  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }
  .el-menu {
    background: transparent;
  }
  .el-menu.el-menu--horizontal {
    position: relative;
    border: none;
  }
  .el-menu--horizontal > .el-menu-item.is-active {
    background: transparent;
    border-bottom: none;
    font-size: 14px;
    color: #016eff;
    font-weight: bold;
    border-bottom: 2px solid #016eff;
    font-family: "myfont";
  }
  .el-menu--horizontal > .el-menu-item:hover {
    background: transparent;
    color: #016eff;
    font-size: 14px;
    font-weight: bold;
    border-bottom: 2px solid #016eff;
    font-family: "myfont";
  }
  .el-menu-item {
    border-bottom: none;
    font-size: 14px;
    color: #fff;
    font-family: "refont";
    // font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    // font-weight: bold;
    // color: rgba(0, 0, 0, .6) !important;
    // color: #000000 !important;
  }
  /deep/ .el-submenu {
    // opacity: 0;
  }
  .el-menu--horizontal > .el-menu-item.is-active:before {
    // content: "";
    // width: 0px; /* 图片宽度 */
    // height: 0px; /* 图片高度 */
    // // background-image: url("../assets/image/home-tab-active.png"); /* 图片路径 */
    // // background-size: cover; /* 背景尺寸适应 */
    // border: none;
    // border-bottom: 10px solid #003bff;
    // border-top: 0px solid #003bff;
    // border-right: 5px solid transparent;
    // border-left: 5px solid transparent;
    // position: absolute;
    // left: 50%;
    // top: 40px;
    // z-index: 999;
    // transform: translateX(-50%);
  }
  .el-submenu.is-active {
    background: transparent;
    position: relative;
    color: #016eff;
    font-family: "myfont";
    border-bottom: 2px solid #016eff;
  }
}

/* 在大屏幕上的样式 */
@media (min-width: 1024px) {
  /* 添加适合大屏幕的样式 */
  body {
    font-family: "myfont";
  }
  .home-box {
    font-family: myfont;
    background: linear-gradient(135deg, #eef3fd 0%, #eef3fd 100%);
    // text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    .yiru {
      width: 100%;
      height: 332px;
      background: #ffffff;
      border-radius: 0px 0px 0px 0px;
      .home-login {
        // position: absolute;
        width: 148px;
        height: 44px;
        // left: 50px;
        // top: 13px;
        background: url("../assets/image/home-login2.png");
        background-repeat: no-repeat;
        background-size: cover;
      }
      .el-menu-item {
        border-bottom: none;
        font-size: 14px;
        color: #000;
        font-family: "refont";
        // font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        // font-weight: bold;
        // color: rgba(0, 0, 0, .6) !important;
        // color: #000000 !important;
      }
      .el-menu--horizontal > .el-submenu /deep/ .el-submenu__title {
        font-size: 14px;
        font-family: "refont";
        font-weight: 500;
        color: #000;
      }
      .chanpingdaohang {
        width: 1200px;
        margin: 0 auto;
        padding: 30px 0 25px;
        box-sizing: border-box;
        h2 {
          display: inline-block;
          font-size: 14px;
          color: #202124;
          font-family: "blodfont";
          margin: 0;
        }
        img {
          margin-right: 6px;
          width: 24px;
          height: 24px;
          vertical-align: middle;
        }
      }
      .erjidaohang {
        ul {
          width: 1200px;
          margin: 0 auto;
          overflow: hidden;
          padding: 0;
          li {
            float: left;
            width: 282px;
            height: 56px;
            line-height: 56px;
            text-align: center;
            font-size: 16px;
            color: #202124;
            font-family: "myfont";
          }
          li:hover {
            cursor: pointer;
            background: url("../assets/image/Slice 89.jpg");
            background-repeat: no-repeat;
            background-size: cover;
          }
        }
      }
    }

    // 底部信息
    .info-box {
      display: flex;
      bottom: 0;
      width: 100%;
      height: 418px;
      background: linear-gradient(135deg, #252d45 0%, #1e2843 100%);

      .info-content {
        position: relative;
        overflow: hidden;
        color: #fff;
        box-sizing: border-box;
        width: 1200px;
        height: 100%;
        margin: 0px auto;
        .login-bottom {
          position: absolute;
          left: 0;
          top: 44px;
          width: 143px;
          height: 44px;
          background: url("../assets/image/home-login.png");
          background-repeat: no-repeat;
          background-size: cover;
        }
        .daohang {
          position: absolute;
          left: 254px;
          top: 61px;
          font-size: 12px;
          li {
            cursor: pointer;
          }
          span:hover {
            border-bottom: 1px solid #fff;
          }
        }
        .biaoti {
          font-size: 14px;
          margin-bottom: 32px;
          cursor: none;
        }
        .lianxi {
          position: absolute;
          left: 409px;
          top: 61px;
        }
        .ma {
          position: absolute;
          left: 839px;
          top: 66px;
          width: 140px;
          height: 140px;
          background: #f4f4f4;

          background: url("../assets/image/erweima.png");
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: 0px 0px 0px 0px;
          .title {
            position: absolute;
            bottom: -24px;
            left: 50%;
            width: 100%;
            text-align: center;
            transform: translateX(-50%);
            font-size: 12px;
            color: #ffffff;
          }
        }
        ul {
          margin: 0;
          padding: 0;
        }
        ul li {
          font-size: 12px;
          margin-bottom: 22px;
          i {
            width: 15px;
            height: 16px;
            margin-right: 10px;
          }
          img {
            width: 15px;
            height: 16px;
            margin-right: 10px;
          }
          .img {
            display: inline-block;
            width: 15px;
            height: 16px;
            margin-right: 12px;
          }
        }
        > div {
          float: left;
        }
        .info-left {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 354px;
          height: 160px;
          .info-login {
            width: 254px;
            height: 80px;
            background: url("../assets/image/info-login.png");
            background-repeat: no-repeat;
            background-size: cover;
          }
          .info-record {
            font-size: 14px;
          }
        }
        .info-middle {
          width: 94px;
          h2 {
            font-size: 24px;
            margin-top: 0;
          }
        }
        .info-right {
          i {
            margin-right: 5px;
          }
        }
      }
    }
  }
  .home-login {
    // position: absolute;
    width: 148px;
    height: 44px;
    // left: 50px;
    // top: 13px;
    background: url("../assets/image/home-login.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  // 滚动200px 导航栏变化样式
  // .bianhua {
  //   background: rgba(0, 0, 0, 0.6) !important;
  // }
  .bianhua {
    background: #fff !important;
    .home-login {
      // position: absolute;
      width: 148px;
      height: 44px;
      // left: 50px;
      // top: 13px;
      background: url("../assets/image/home-login2.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
    .el-menu-item {
      border-bottom: none;
      font-size: 14px;
      color: #000;
      font-family: "refont";
      // font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      // font-weight: bold;
      // color: rgba(0, 0, 0, .6) !important;
      // color: #000000 !important;
    }
    .el-menu--horizontal > .el-submenu /deep/ .el-submenu__title {
      font-size: 14px;
      font-family: "refont";
      font-weight: 500;
      color: #000;
    }
  }
  .bianhua3 {
    background: transparent !important;
    .home-login {
      // position: absolute;
      width: 148px;
      height: 44px;
      // left: 50px;
      // top: 13px;
      background: url("../assets/image/home-login.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
    .el-menu-item {
      border-bottom: none;
      font-size: 14px;
      color: #fff;
      font-family: "refont";
      // font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      // font-weight: bold;
      // color: rgba(0, 0, 0, .6) !important;
      // color: #000000 !important;
    }
    .el-menu--horizontal > .el-submenu /deep/ .el-submenu__title {
      font-size: 14px;
      font-family: "refont";
      font-weight: 500;
      color: #000;
    }
  }
  .bianhua2 {
    background: transparent !important;
    .home-login {
      // position: absolute;
      width: 148px;
      height: 44px;
      // left: 50px;
      // top: 13px;
      background: url("../assets/image/home-login2.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
    .el-menu-item {
      border-bottom: none;
      font-size: 14px;
      color: #000;
      font-family: "refont";
      // font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      // font-weight: bold;
      // color: rgba(0, 0, 0, .6) !important;
      // color: #000000 !important;
    }
    .el-menu--horizontal > .el-submenu /deep/ .el-submenu__title {
      font-size: 14px;
      font-family: "refont";
      font-weight: 500;
      color: #000;
    }
  }
  .home-top {
    width: 1200px;
    margin: 0 auto;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    height: 80px;
    line-height: 80px;
    z-index: 9999;
    .home-tab {
      float: left;
    }
    .daohang {
      .connection {
        cursor: pointer;
        width: 100px;
        height: 32px;
        background: linear-gradient(
            189deg,
            rgba(0, 178, 255, 0) 0%,
            #00ffff 100%
          ),
          linear-gradient(5deg, rgba(0, 194, 255, 0) 0%, #1400ff 100%),
          linear-gradient(327deg, rgba(175, 74, 255, 0) 0%, #af4aff 100%),
          #003bff;
        border-radius: 8px 8px 8px 8px;
        color: #fff;
        line-height: 32px;
        text-align: center;
        font-size: 14px;
        font-family: "myfont";
      }
      .shoujidaohang {
        display: none;
      }
    }
  }

  // .el-carousel__container {
  //   height: aspectRatio + 'px';
  // }
  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  .el-carousel__item {
    // position: absolute;
    width: 100%;
  }
  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }
  .el-menu {
    background: transparent;
  }
  .el-menu.el-menu--horizontal {
    position: relative;
    border: none;
  }
  .el-menu--horizontal > .el-menu-item.is-active {
    background: transparent;
    border-bottom: none;
    font-size: 14px;
    color: #016eff;
    font-weight: bold;
    border-bottom: 2px solid #016eff;
    font-family: "myfont";
  }
  .el-menu--horizontal > .el-menu-item:hover {
    background: transparent;
    color: #016eff;
    font-size: 14px;
    font-weight: bold;
    border-bottom: 2px solid #016eff;
    font-family: "myfont";
  }
  .el-menu-item {
    border-bottom: none;
    font-size: 14px;
    color: #fff;
    font-family: "refont";
    // font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    // font-weight: bold;
    // color: rgba(0, 0, 0, .6) !important;
    // color: #000000 !important;
  }
  /deep/ .el-submenu {
    // opacity: 0;
  }
  .el-menu--horizontal > .el-menu-item.is-active:before {
    // content: "";
    // width: 0px; /* 图片宽度 */
    // height: 0px; /* 图片高度 */
    // // background-image: url("../assets/image/home-tab-active.png"); /* 图片路径 */
    // // background-size: cover; /* 背景尺寸适应 */
    // border: none;
    // border-bottom: 10px solid #003bff;
    // border-top: 0px solid #003bff;
    // border-right: 5px solid transparent;
    // border-left: 5px solid transparent;
    // position: absolute;
    // left: 50%;
    // top: 40px;
    // z-index: 999;
    // transform: translateX(-50%);
  }
  .el-submenu.is-active {
    background: transparent;
    position: relative;
    color: #016eff;
    font-family: "myfont";
    border-bottom: 2px solid #016eff;
  }
}
// @font-face {
//   font-family: "myfont";
//   src: url(~@/assets/font/SourceHanSansCN-Medium.otf) format("truetype");
// }

// .el-submenu.is-active::before {
//   content: "";
//   width: 16px; /* 图片宽度 */
//   height: 12px; /* 图片高度 */
//   background-image: url("../assets/image/home-tab-active.png"); /* 图片路径 */
//   background-size: cover; /* 背景尺寸适应 */
//   position: absolute;
//   left: 50%;
//   transform: translateX(-50%);
//   border-bottom: 1px solid #016EFF;
// }
// /deep/.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
//   border-bottom: 2px solid transparent !important;
//   color: #016eff;
// }
// // .el-menu--horizontal .el-submenu .el-submenu__title  .is-active:before {
// //   content: "";
// //   width: 16px; /* 图片宽度 */
// //   height: 12px; /* 图片高度 */
// //   background-image: url("../assets/image/home-tab-active.png"); /* 图片路径 */
// //   background-size: cover; /* 背景尺寸适应 */
// //   position: absolute;
// //   left: 50%;
// //   transform: translateX(-50%);
// // }
// .el-menu--horizontal > .el-submenu /deep/ .el-submenu__title {
//   font-size: 18px;
//   font-family: "refont";
//   font-weight: 500;
//   color: #fff;
// }
// .el-menu--horizontal > .el-submenu /deep/ .el-submenu__title:hover {
//   background: transparent;
// }
// .el-menu--horizontal > .el-submenu /deep/ .el-submenu__icon-arrow {
//   // display: none !important;
// }
// /deep/.el-carousel__button {
//   width: 24px;
//   height: 6px;
// }
// /deep/.el-carousel__indicator--horizontal {
//   padding: 12px 16px 90px 16px;
// }
// /deep/.el-submenu__icon-arrow {
//   // display: none !important;
// }
// /deep/ .el-submenu {
//   // margin-top: -51px;
//   // opacity: 0;
// }
// /deep/ .el-submenu__title {
//   // padding: 0 !important;
//   // width: 106px;
//   // height: 60px;
//   // margin: -60px 0 0 -35px;
// }
// /deep/.el-drawer__open .el-drawer.rtl {
//   min-width: 250px;
// }
</style>