<template>
  <div
    style="
      width: 100%;
      background-color: #eef3fd;
      display: flex;
      flex-direction: column;
      align-items: center;
    "
  >
    <!-- 产品中心背景图 -->
    <div class="news-bg">
      <div class="news-fontBox">
        <div class="news-font">行业动态</div>
        <div class="back" @click="$router.go(-1)">
          <i
            class="el-icon-arrow-left"
            style="font-size: .2rem; margin: auto; color: #fff"
          ></i>
        </div>
      </div>
    </div>
    <!-- 产品介绍 -->
    <div class="news-news">
      <div class="news-top"></div>
      <div class="detailsOne-content">
        <h3 style="text-align: center;font-family: 'boldfont'">
          安全域盘点 | 2023年上半年全球数据安全 及个人信息泄露事件回顾
        </h3>
        <p class="textCenter">2023年07月14号</p>
        <p style="margin: .25rem 0 .1667rem">
          随着数字经济的发展，数据也高速增长，数据安全风险也不断攀增，侵犯数据权益的同时，对社会公共利益乃至国家安全也造成严重威胁。2023年全球的恶数据泄漏事件还处于高发的态势。安全域盘点了2023年上半年在全球发生的重大数据安全及个人信息泄漏相关事件和案例，供大家阅读。
        </p>

        <p style="font-family: 'boldfont'" class="textCenter">
          1.印尼3400万护照数据遭泄露！
        </p>
        <p class="textIndent">
          有3400万本新护照数据被泄露和进行交易。确认被泄露的数据包括护照号码、有效护照日期、姓名、出生日期、性别等。在门户网站上，黑客还提供了100万数据的样本。从提供的样本数据来看，数据看起来是有效的。时间是从2009年到2020年。数据卖方比约卡（Bjorka)声称，已收集到3400万印尼公民护照数据，压缩状态下约为9GB。CSV格式的数据以1万美元的价格出售。
        </p>
        <p style=";margin-top: .1667rem;font-family: 'boldfont'" class="textCenter">
            2.敏感数据信息存在泄露风险，本溪银行原副行长被处罚！
        </p>
        <p class="textIndent">
            国家金融监督管理总局信息显示，本溪银保监分局发布一则行政处罚告知书送达公告。经查，李志刚在本溪银行工作期间，对敏感数据信息存在泄露风险、瞒报信息系统突发事件、监管要求落实严重不到位等违法违规事实负有责任。根据《中华人民共和国银行业监督管理法》第四十八条规定，该分局拟对其作出行政处罚。
        </p>
        <p style="font-family: 'boldfont';margin-top: .1667rem;" class="textCenter">
            3.罚款5万元！中山警方开出该市首张违反《数据安全法》罚单
        </p>
        <p class="textIndent">
            近日，中山三乡公安分局鹤湾派出所在对辖区内的公司进行数据安全检查过程中，发现一家信息科技公司疑似存在网络数据泄露隐患。鹤湾派出所通过询问相关责任人、调取网络设备日志信息、开展技术检测等方式，发现该公司在没有依法建立数据安全管理制度和操作规程等数据保护措施的前提下，对存储的公民敏感信息数据未采取去标识化和加密保护。通过现场检查，发现该公司用于存储公民敏感信息的服务器也存在未授权访问的漏洞，用户隐私数据存在泄露风险。
        </p>
        <p style="font-family: 'boldfont';margin-top: .1667rem;" class="textCenter">
            4.最新情况！人大学生信息泄露事件，警方通报：刑拘！
        </p>
        <p class="textIndent">
            据网络爆料，涉事男子疑似中国人民大学高瓴人工智能学院2019级研究生，目前已经毕业，而他在读硕士研究生期间，利用专业技术盗取全校学生个人信息，包括照片、姓名、学号、籍贯、生日等，并搭建了给全校学生颜值打分的网站。针对“中国人民大学部分学生信息被非法获取”的情况，海淀警方接到报警后，立即开展调查。经查，嫌疑人马某某（男，25岁，该校毕业生）涉嫌非法获取该校部分学生个人信息等违法犯罪行为。目前，马某某已被海淀公安分局依法刑事拘留，案件正在进一步调查中。
        </p>
        <p style="font-family: 'boldfont';margin-top: .1667rem;" class="textCenter">
            5.OpenAI被指控窃取大量信息！潜在损失高达30亿美元！
        </p>
        <p class="textIndent">
            2023年6月29日，人工智能(AI)聊天机器人ChatGPT的研发公司OpenAI在美国旧金山面对集体诉讼。诉状指OpenAI为追求利润，违反隐私法，从互联网上窃取大量个人信息，用于训练人工智能模型。美国克拉克森律师事务所星期三(6月28日)向旧金山联邦法院提交一份厚达175页的诉讼书，指OpenAI秘密从互联网窃取了3000亿个单词，挖掘范围包括书籍、文章、网站和贴文，以及未经同意获取的个人信息。
        </p>
        <p style="font-family: 'boldfont';margin-top: .1667rem;" class="textCenter">
            6.世界上两家最大航空公司的飞行员数据泄漏！
        </p>
        <p class="textIndent">
            美国航空和西南航空是世界上最大的两家航空公司，它们在近日披露了一起数据泄露事件，该事件是由一家第三方供应商Pilot Credentials的黑客攻击造成的。Pilot Credentials是一家管理多家航空公司的飞行员申请和招聘门户的供应商。美国航空在一份通知信中表示，Pilot Credentials于2023年6月18日通知了美国航空，称其系统于2023年5月24日遭到了未经授权的访问，导致部分美国航空飞行员的个人信息被泄露。
        </p>
        <p style="font-family: 'boldfont';margin-top: 0.1667rem;" class="textCenter">
            7.网络犯罪团伙窃走公民个人信息百万条！
        </p>
        <p class="textIndent">
            2023年6月21日，从警方获悉，近日，厦门市公安局网安支队联合思明公安分局跨四省市，成功打掉一个集黑客攻击、数据清洗、买卖信息、提供资金、数据使用等为一体的全链条网络犯罪团伙，破获某公司被侵犯公民个人信息案，抓获犯罪嫌疑人7名，查获非法获取的公民个人信息100余万条。
        </p>
        <p style="font-family: 'boldfont';margin-top: 0.1667rem;" class="textCenter">
            8.衡阳一医院因数据保护不力造成泄露，当地网信办开出罚单！
        </p>
        <p class="textIndent">
            经查，衡南县某医院未履行数据安全保护义务，造成部分数据泄露，违反《中华人民共和国数据安全法》第二十九条规定。衡南县网信办依据《中华人民共和国数据安全法》第四十五条规定，对该医院作出责令整改，给予警告，并处罚款5万元的行政处罚。同时，对第三方技术公司及相关责任人处以1.2万元罚款。据悉，这是衡阳市县级网信部门开出的首张“罚单”，也是衡阳网信部门在数据安全领域开出的首张“罚单”。
        </p>
        <p style="font-family: 'boldfont';margin-top: 0.1667rem;" class="textCenter">
            9.浙江某公司因违反《数据安全法》，被罚款100万元！
        </p>
        <p class="textIndent">
            浙江某科技有限公司为浙江某县级市政府部门开发运维信息管理系统的过程中，在未经建设单位同意的情况下，将建设单位采集的敏感业务数据擅自上传至租用的公有云服务器上，且未采取安全保护措施，造成了严重的数据泄露。
浙江温州公安机关根据《中华人民共和国数据安全法》国家标准支撑《数据安全法》落地实施一周年第四十五条的规定，对公司及项目主管人员、直接责任人员分别作出罚款100万元、8万元、6万元的行政处罚。
        </p>
        <p style="font-family: 'boldfont';margin-top: 0.1667rem;" class="textCenter">
            10.美国政府正在购买个人信息，包括位置数据！
        </p>
        <p class="textIndent">
            根据美国国家情报总监办公室（ODNI）的一份报告，该办公室由艾薇儿·海恩斯（Avril Haines）主任领导，艾薇儿是美国内阁官员，负责监督美国情报界，包括许多军事情报机构，以及联邦调查局、中央情报局、国家安全局和其他机构。报告称，美国情报机构购买了大量可供出售的美国人个人数据，为美国政府提供了丰富的情报资料，但此举也对美国民众私隐造成了重大威胁。
        </p>
        <p style="font-family: 'boldfont';margin-top: 0.1667rem;" class="textCenter">
            11.处罚！违规泄露有权部门查询信息，柳州银行被罚
        </p>
        <p class="textIndent">
            国家金融监督管理总局6月5日发布的行政处罚信息显示，柳州银行南宁分行存在“员工行为管理不到位”“黄明喆违规泄露有权部门查询信息”等主要违法违规行为，依据《中华人民共和国银行业监督管理法》第四十六条第（五）项、第四十八条第（二）项，被广西银保监局处以罚款30万元，该员工则被警告。
        </p>
        <p style="font-family: 'boldfont';margin-top: 0.1667rem;" class="textCenter">
            12.江西某公司因违反《数据安全法》被网信办处罚！主管人同罚！
        </p>
        <p class="textIndent">
            近日，江西省南昌市网信办，对履行数据安全保护义务不到位，未履行风险监测、补救处置等义务的公司，作出行政处罚，罚款50万元，对直接负责的主管人员处以罚款5万元的行政处罚。罚款50万元，是网信办依据《数据安全法》 第四十五条，就该公司数据安全违法行为作出的顶格处罚！
        </p>
        <p style="font-family: 'boldfont';margin-top: 0.1667rem;" class="textCenter">
            13.特斯拉被调查，涉嫌数据泄露！
        </p>   
        <p class="textIndent">
            5月25日消息，特斯拉公司正在接受德国和荷兰政府关于潜在数据隐私安全风险的审查，据悉，此次调查事件涉及该公司的自动驾驶辅助驾驶产品。据此前相关媒体报道的信息，有关隐私数据包括2015年至2022年3月期间数千起有关自动驾驶仪的投诉，此外还包括超过10万名员工的机密信息、客户数据。报道称，特斯拉前雇员曾向德国勃兰登堡州的数据保护机构表达了对俄罗斯等国家可访问数据的担忧。
        </p>
        <p style="font-family: 'boldfont';margin-top: 0.1667rem;" class="textCenter">
            14.91亿元！因非法向美国转移用户数据，Meta被欧盟重罚！
        </p>
        <p class="textIndent">
            5月22日，布鲁塞尔--在欧盟数据保护委员会（EDPB）于2023年4月13日做出具有约束力的争端解决决定后，爱尔兰数据保护局（IE DPA）对Meta爱尔兰的Facebook服务进行调查后，对其开出了12亿欧元（超过91亿人民币）的罚款。这笔罚款是有史以来最大的GDPR罚款，因为Meta自2020年7月16日起根据标准合同条款（SCC）向美国转移个人数据。此外，Meta公司被命令使其数据转移符合GDPR的规定。
        </p>
        <p style="font-family: 'boldfont';margin-top: 0.1667rem;" class="textCenter">
            15.丰田致歉！200万车主车辆数据遭泄露长达10年
        </p>
        <p class="textIndent">
            据央视财经报道，丰田汽车近日表示因云环境中的设置错误，大约有215万用户的车辆数据在日本存在被泄露的风险。丰田汽车方面表示，泄露的信息或包括车辆设备的识别号码和车辆位置。不过，同时表示目前并没有恶意使用的报告。
        </p>
        <p style="font-family: 'boldfont';margin-top: 0.1667rem;" class="textCenter">
            16.英特尔CPU被曝安全漏洞，攻击者大量窃取数据！
        </p>
        <p class="textIndent">
            近日，在 Arxiv.org 上发表的一篇技术论文揭示了一种针对多代英特尔CPU的攻击手法—利用新的侧信道攻击，让数据通过 EFLAGS 寄存器泄露。这一与众不同的侧信道攻击由清华大学、马里兰大学和中国教育部计算机实验室 (BUPT) 的研究人员共同发现，它不像许多其他侧信道攻击那样依赖缓存系统，而是利用瞬态执行中 EFLAGS 寄存器变化的缺陷，影响JCC（条件代码跳转）指令的时序，进而通过时序分析从用户内存空间中提取数据。
        </p>
        <p style="font-family: 'boldfont';margin-top: 0.1667rem;" class="textCenter">
            17.下一个是谁？ChatGPT持续引发数据泄露风波！
        </p>
        <p class="textIndent">
            据报道，三星引进ChatGPT不到二十天，便爆出机密数据被泄漏涉及半导体设备、测量资料产品良率等相关机密内容，据传被存入ChatGPT数据库。
        </p>
        <p style="font-family: 'boldfont';margin-top: 0.1667rem;" class="textCenter">
            18.非法出售万份员工简历, 男子因侵犯公民个人信息罪受刑罚
        </p>
        <p class="textIndent">
            4月11日，北京市顺义区人民法院当日公开审理了一起非法出售员工简历案。据了解，于某和孙某共同在北京某人力资源公司任职，在2022年6月29日至7月15日期间，于某利用孙某的账号，从公司招聘系统内下载了包含个人信息的简历13000余份，通过将部分简历出售给他人，非法获利人民币1.64万元。
        </p>
        <p style="font-family: 'boldfont';margin-top: 0.1667rem;" class="textCenter">
            19.涉嫌危害国家安全 国内知名咨询企业被查
        </p>
        <p class="textIndent">
            经执法调查，咨询企业凯盛融英因涉嫌危害国家安全，已被国家安全机关进行依法依规处理。专业人士表示，这类咨询调查公司在承担涉外咨询项目过程中，频繁联系接触地方党政机关、重要国防科工等涉密人员，并以高额报酬聘请行业咨询专家之名，非法获取我国各类敏感数据，对我国家安全构成了重大风险隐患。
        </p>
        <p style="font-family: 'boldfont';margin-top: 0.1667rem;" class="textCenter">
            20.智能充电桩面临信息安全隐患，余额容易被盗刷？
        </p>
        <p class="textIndent">
            工信部通报2023年第3批（总第29批）存在问题的APP（SDK）名单，其中10家企业11款APP(SDK)涉及新能源汽车充换电运营相关的平台，包括铁塔充电、益虫充电、小象充电等。问题包括“违规收集个人信息”“强制用户使用定向推送功能”“APP强制、频繁、过度索取权限”等。
        </p>
        <p style="margin-top: .1667rem">文章来源于微信公众号“隐私计算联盟”</p>
      </div>
    </div>
  </div>
</template>
          
          <script>
export default {};
</script>
          
          <style lang="less" scoped>
.news-bg {
  color: #fff;
  width: 100%;
  height: 400px;
  background: url("../assets/image/news-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  .news-fontBox {
    width: 1200px;
    margin: 0 auto;
  }
  .news-font {
    float: left;
    height: 96px;
    width: 100%;
    margin-top: 104px;
    font-size: 64px;
    letter-spacing: 8px;
  }
  .back {
    cursor: pointer;
    line-height: 80px;
    text-align: center;
    float: left;
    margin-top: 28px;
    width: 60px;
    height: 60px;
    background: rgba(89, 235, 255, 0.2);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid #59ebff;
  }
}
.news-news {
  width: 1200px;
  margin: 80px 0;
  background-color: #fff;
  border-radius: 20px 20px 20px 20px;
  box-sizing: border-box;
  .news-top {
    float: left;
    left: 0;
    width: 1200px;
    height: 40px;
    background: linear-gradient(117deg, #c9daeb 0%, #dcebfa 100%);
    border-radius: 20px 20px 0px 0px;
  }
  .detailsOne-content {
    width: 100%;
    height: 100%;
    padding: 78px 40px 80px;
    box-sizing: border-box;
    .img-newsbox {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      width: 100%;
      height: 100%;
    }
    h3 {
      text-align: center;
      font-size: 40px;
      width: 725px;
      margin: 0 auto;
    }
    p {
      font-size: 20px;
      margin: 16px 0;
      font-family:'refont'
    }
  }
}
.textCenter {
  text-align: center;
}
.textIndent {
  text-indent: 2em;
}
</style>