<template>
  <div
    style="
      width: 100%;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
    "
  >
    <div class="hexin-bg">
      <div class="title">
        <el-button @click="$router.push('/Customization')">立即咨询</el-button>
      </div>
    </div>
    <div class="contant">
      <div class="contant-box">
        <div class="chuantong">
          <div class="text">
            <h2>密文云存储</h2>
            <p>
              通过使用隐算全同态加密技术，用户可以在不泄露数据内容的前提下，对存储在云端的数据进行加密处理，确保数据的安全性和隐私性；即使在云端进行数据处理和分析，数据的敏感信息也不会暴露给云服务提供商或其他第三方；此外，隐算全同态密文云存储可提高数据处理效率，在云计算和大数据领域具有重要的应用潜力，尤其是在金融、医疗等需要处理大量个人隐私数据的行业:创建-采集-存储-计算-融合-销毁
            </p>
          </div>
          <div class="img">
            <img
              width="100%"
              src="../../assets/image/fuwu/Slice 83@2x.png"
              alt=""
            />
          </div>
        </div>
        <div class="chuantong">
          <div class="text">
            <h2>隐算全同态密文个人云存储</h2>
            <p>
              在云端安全地存储自己的敏感信息，如个人身份数据、财务信息等。当需要对这些数据进行搜索、排序或其他计算操作时，可以在密文状态下直接进行，云端服务提供商不会接触到任何明文数据:创建-采集-存储-计算-融合-销毁
            </p>
          </div>
          <div class="img">
            <img
              width="100%"
              src="../../assets/image/fuwu/Slice 83@2x(1).png"
              alt=""
            />
          </div>
        </div>
        <!-- <div class="chuantong2">
          <h2>产品架构图</h2>
          <div class="img" style="margin: 0;">
            <img width="100%" src="../../assets/image/fuwu/Slice 98 (5).jpg" alt="" />
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      showBox1: null,
    };
  },
  methods: {
    toggleBox(e) {
      const liElement = e.target; // 获取当前点击的元素
      const index = Array.from(liElement.parentNode.children).indexOf(
        liElement
      ); // 获取当前元素在父元素中的索引
      this.showBox1 = index;
    },
    toggleBoxout() {
      this.showBox1 = 5;
    },
  },
};
</script>
  
  <style lang="less" scoped>
/* 在小屏幕上的样式 */
@media (max-width: 767px) {
  /* 添加适合小屏幕的样式 */
  .hexin-bg {
    position: relative;
    width: 100%;
    height: 2048px;
    background: url("../../assets/image/fuwu/Slice 80@2x (8).png");
    background-repeat: no-repeat;
    background-size: cover;
    .title {
      position: absolute;
      left: 50%;
      top: 922px;
      // width: 100%;
      transform: translateX(-50%);
      .el-button {
        width: 614px;
        height: 184px;
        background: #016eff;
        color: #fff;
        font-size: 72px;
        border-radius: 41px 41px 41px 41px;
      }
    }
  }
  .contant {
    width: 100%;
    // height: 1160px;
    background: #fafcff;
    .contant-box {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      margin-top: 164px;
      padding: 0 82px;
      box-sizing: border-box;
      .chuantong {
        // display: flex;
        margin-bottom: 164px;
        .text {
          // flex: 1;
          h2 {
            font-size: 82px;
            color: #202124;
            font-weight: 400;
            font-family: "boldfont";
            margin: 0;
            margin-bottom: 61px;
          }
          p {
            font-family: "refont";
            margin-top: 0;
            font-size: 61px;
            color: #566884;
            line-height: 123px;
          }
        }
        .img {
          position: relative;
          width: 1121px;
          height: 696px;
          background: #e2edff;
          padding: 41px;
          margin: 0 auto;
          box-sizing: border-box;
          p {
            position: absolute;
            bottom: -202px;
            font-size: 61px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
      .chuantong2 {
        display: flex;
        margin-bottom: 307px;
        flex-direction: column;
        h2 {
          font-size: 82px;
            color: #202124;
            font-weight: 400;
            font-family: "boldfont";
            margin: 0;
            margin-bottom: 61px;
        }
        .text {
          flex: 1;
          h2 {
            font-size: 24px;
            color: #202124;
            font-weight: 400;
            font-family: "boldfont";
            margin-bottom: 32px;
          }
          p {
            font-size: 20px;
            font-family: "myfont";
            margin-top: 24px;
          }
        }
        img {
          width: 100%;
        }
      }
    }
  }
  .quan {
    position: relative;
    h2 {
      font-size: 24px;
      color: #202124;
      font-weight: 400;
      font-family: "boldfont";
      margin-bottom: 22px;
      text-align: center;
    }
    .tu {
      margin-bottom: 28px;
      overflow: hidden;
      .tu-l {
        float: left;
        width: 295px;
        height: 290px;
        margin-left: 146px;
      }
      .tu-r {
        float: left;
        width: 627px;
        height: 162px;
        margin: 28px 0 0 73px;
      }
    }
  }
  .botton-text {
    p {
      font-size: 20px;
      color: #566884;
      font-family: "refont";
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        font-family: "refont";
        color: #566884;
        font-size: 20px;
        margin-bottom: 20px;
        img {
          width: 16px;
          height: 16px;
          margin-right: 13px;
        }
      }
    }
    .bold {
      color: #202124;
      font-family: "boldfont";
    }
  }
  .fade-in {
    animation: fadeIn 1.5s;
  }
  .fade-in2 {
    animation: fadeIn2 1.5s;
  }

  .fade-out {
    animation: fadeOut 1.5s;
  }
  .fade-out2 {
    animation: fadeOut2 1.5s;
  }

  @keyframes fadeIn {
    from {
      margin-left: 360px;
      opacity: 0;
    }
    to {
      margin-left: 0;
      opacity: 1;
    }
  }
  @keyframes fadeIn2 {
    from {
      margin-left: 0;
      opacity: 1;
    }
    to {
      margin-left: 360px;
      opacity: 0;
    }
  }

  @keyframes fadeOut {
    from {
      margin-left: 0;
      opacity: 1;
    }
    to {
      margin-left: -360px;
      opacity: 0;
    }
  }
  @keyframes fadeOut2 {
    from {
      margin-left: -360px;
      opacity: 0;
    }
    to {
      margin-left: 0;
      opacity: 1;
    }
  }
}

/* 在中屏幕上的样式 */
@media (min-width: 768px) and (max-width: 1023px) {
  /* 添加适合中屏幕的样式 */
  .hexin-bg {
    position: relative;
    width: 100%;
    height: 550px;
    background: url("../../assets/image/fuwu/Slice 82@2x.png");
    background-repeat: no-repeat;
    background-size: cover;
    .title {
      position: absolute;
      left: 50%;
      top: 352px;
      width: 1200px;
      transform: translateX(-50%);
      .el-button {
        width: 240px;
        height: 60px;
        background: #016eff;
        color: #fff;
        font-size: 18px;
        border-radius: 8px 8px 8px 8px;
      }
    }
  }
  .contant {
    width: 100%;
    // height: 1160px;
    background: #fafcff;
    .contant-box {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      margin-top: 72px;
      .chuantong {
        display: flex;
        margin-bottom: 72px;
        .text {
          flex: 1;
          h2 {
            font-size: 24px;
            color: #202124;
            font-weight: 400;
            font-family: "boldfont";
            margin-bottom: 32px;
          }
          p {
            font-size: 20px;
            font-family: "refont";
            margin-top: 24px;
            color: #566884;
            line-height: 40px;
          }
        }
        .img {
          width: 457px;
          height: 280px;
          background: #e2edff;
          margin-left: 52px;
          padding: 14px 16px;
          box-sizing: border-box;
        }
      }
      .chuantong2 {
        display: flex;
        margin-bottom: 72px;
        flex-direction: column;
        align-items: center;
        h2 {
          font-size: 24px;
          color: #202124;
          font-weight: 400;
          font-family: "boldfont";
          margin: 0 0 32px;
        }
        .text {
          flex: 1;
          h2 {
            font-size: 24px;
            color: #202124;
            font-weight: 400;
            font-family: "boldfont";
            margin-bottom: 32px;
          }
          p {
            font-size: 20px;
            font-family: "myfont";
            margin-top: 24px;
          }
        }
        img {
          width: 812px;
        }
      }
    }
  }
  .quan {
    position: relative;
    h2 {
      font-size: 24px;
      color: #202124;
      font-weight: 400;
      font-family: "boldfont";
      margin-bottom: 22px;
      text-align: center;
    }
    .tu {
      margin-bottom: 28px;
      overflow: hidden;
      .tu-l {
        float: left;
        width: 295px;
        height: 290px;
        margin-left: 146px;
      }
      .tu-r {
        float: left;
        width: 627px;
        height: 162px;
        margin: 28px 0 0 73px;
      }
    }
  }
  .botton-text {
    p {
      font-size: 20px;
      color: #566884;
      font-family: "refont";
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        font-family: "refont";
        color: #566884;
        font-size: 20px;
        margin-bottom: 20px;
        img {
          width: 16px;
          height: 16px;
          margin-right: 13px;
        }
      }
    }
    .bold {
      color: #202124;
      font-family: "boldfont";
    }
  }
  .fade-in {
    animation: fadeIn 1.5s;
  }
  .fade-in2 {
    animation: fadeIn2 1.5s;
  }

  .fade-out {
    animation: fadeOut 1.5s;
  }
  .fade-out2 {
    animation: fadeOut2 1.5s;
  }

  @keyframes fadeIn {
    from {
      margin-left: 360px;
      opacity: 0;
    }
    to {
      margin-left: 0;
      opacity: 1;
    }
  }
  @keyframes fadeIn2 {
    from {
      margin-left: 0;
      opacity: 1;
    }
    to {
      margin-left: 360px;
      opacity: 0;
    }
  }

  @keyframes fadeOut {
    from {
      margin-left: 0;
      opacity: 1;
    }
    to {
      margin-left: -360px;
      opacity: 0;
    }
  }
  @keyframes fadeOut2 {
    from {
      margin-left: -360px;
      opacity: 0;
    }
    to {
      margin-left: 0;
      opacity: 1;
    }
  }
}

/* 在大屏幕上的样式 */
@media (min-width: 1024px) {
  /* 添加适合大屏幕的样式 */
  .hexin-bg {
    position: relative;
    width: 100%;
    height: 550px;
    background: url("../../assets/image/fuwu/Slice 82@2x.png");
    background-repeat: no-repeat;
    background-size: cover;
    .title {
      position: absolute;
      left: 50%;
      top: 352px;
      width: 1200px;
      transform: translateX(-50%);
      .el-button {
        width: 240px;
        height: 60px;
        background: #016eff;
        color: #fff;
        font-size: 18px;
        border-radius: 8px 8px 8px 8px;
      }
    }
  }
  .contant {
    width: 100%;
    // height: 1160px;
    background: #fafcff;
    .contant-box {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      margin-top: 72px;
      .chuantong {
        display: flex;
        margin-bottom: 72px;
        .text {
          flex: 1;
          h2 {
            font-size: 24px;
            color: #202124;
            font-weight: 400;
            font-family: "boldfont";
            margin-bottom: 32px;
          }
          p {
            font-size: 20px;
            font-family: "refont";
            margin-top: 24px;
            color: #566884;
            line-height: 40px;
          }
        }
        .img {
          width: 457px;
          height: 280px;
          background: #e2edff;
          margin-left: 52px;
          padding: 14px 16px;
          box-sizing: border-box;
        }
      }
      .chuantong2 {
        display: flex;
        margin-bottom: 72px;
        flex-direction: column;
        align-items: center;
        h2 {
          font-size: 24px;
          color: #202124;
          font-weight: 400;
          font-family: "boldfont";
          margin: 0 0 32px;
        }
        .text {
          flex: 1;
          h2 {
            font-size: 24px;
            color: #202124;
            font-weight: 400;
            font-family: "boldfont";
            margin-bottom: 32px;
          }
          p {
            font-size: 20px;
            font-family: "myfont";
            margin-top: 24px;
          }
        }
        img {
          width: 812px;
        }
      }
    }
  }
  .quan {
    position: relative;
    h2 {
      font-size: 24px;
      color: #202124;
      font-weight: 400;
      font-family: "boldfont";
      margin-bottom: 22px;
      text-align: center;
    }
    .tu {
      margin-bottom: 28px;
      overflow: hidden;
      .tu-l {
        float: left;
        width: 295px;
        height: 290px;
        margin-left: 146px;
      }
      .tu-r {
        float: left;
        width: 627px;
        height: 162px;
        margin: 28px 0 0 73px;
      }
    }
  }
  .botton-text {
    p {
      font-size: 20px;
      color: #566884;
      font-family: "refont";
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        font-family: "refont";
        color: #566884;
        font-size: 20px;
        margin-bottom: 20px;
        img {
          width: 16px;
          height: 16px;
          margin-right: 13px;
        }
      }
    }
    .bold {
      color: #202124;
      font-family: "boldfont";
    }
  }
  .fade-in {
    animation: fadeIn 1.5s;
  }
  .fade-in2 {
    animation: fadeIn2 1.5s;
  }

  .fade-out {
    animation: fadeOut 1.5s;
  }
  .fade-out2 {
    animation: fadeOut2 1.5s;
  }

  @keyframes fadeIn {
    from {
      margin-left: 360px;
      opacity: 0;
    }
    to {
      margin-left: 0;
      opacity: 1;
    }
  }
  @keyframes fadeIn2 {
    from {
      margin-left: 0;
      opacity: 1;
    }
    to {
      margin-left: 360px;
      opacity: 0;
    }
  }

  @keyframes fadeOut {
    from {
      margin-left: 0;
      opacity: 1;
    }
    to {
      margin-left: -360px;
      opacity: 0;
    }
  }
  @keyframes fadeOut2 {
    from {
      margin-left: -360px;
      opacity: 0;
    }
    to {
      margin-left: 0;
      opacity: 1;
    }
  }
}
</style>