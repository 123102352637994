<template>
  <div
    style="
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    "
  >
    <!-- 轮播图区域 -->
    <div class="block">
      <div class="home-bg">
        <div class="bg-info">
          <h2>数据安全领域先锋</h2>
          <p>AI智能与全同态加密技术为数据隐私保驾护航</p>
          <el-button @click="$router.push('/Customization')"
            >技术咨询</el-button
          >
        </div>
        <!-- 浮动块介绍 -->
        <div class="floatInfo">
          <div class="floatInfo-item">
            <img src="../assets/image/Slice 87.png" alt="" />
            <span>数据底层安全金字塔底座</span>
          </div>
          <div class="floatInfo-item">
            <img src="../assets/image/Slice 87 (1).png" alt="" />
            <span>数据可用不可见</span>
          </div>
          <div class="floatInfo-item">
            <img src="../assets/image/Slice 87 (2).png" alt="" />
            <span>全新数据安全生态体系</span>
          </div>
          <div class="floatInfo-item">
            <img src="../assets/image/Slice 87 (3).png" alt="" />
            <span>前沿技术团队</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 技术模型展示图区域 -->
    <div class="technology-demonstration" style="height: 0px"></div>
    <!-- 隐算技术全景图 -->
    <div class="product-panorama">
      <div class="product-panorama-box">
        <h2>隐算<strong>产品</strong>示例</h2>
        <!-- 数据安全产品 -->
        <div>
          <div>
            <!-- <span class="text-with-gradient">01</span
            > -->
            <span class="two-title">数据安全产品</span>
          </div>
          <div class="shili">
            <ul>
              <li
                v-for="(item, index) in productExample"
                :key="item.path"
                @click="$router.push(item.path)"
              >
                <div v-if="index != productExample.length - 1">
                  <strong>{{ item.title }}</strong>
                  <i style="border-bottom: 1px solid #a9a9a9"></i>
                  <p>
                    {{ item.content }}
                  </p>
                  <img :src="item.img" alt="" />
                </div>
                <div v-else>更多</div>
              </li>
            </ul>
          </div>
        </div>
        <!-- 专项解决方案 -->
        <div class="zhuangxiang">
          <div>
            <!-- <span class="text-with-gradient">02</span> -->
            <span class="two-title">专项解决方案</span>
          </div>
          <div class="shili1">
            <ul>
              <li
                v-for="(item, index) in specialProgram"
                :key="item.path"
                @click="$router.push(item.path)"
              >
                <div v-if="index != specialProgram.length - 1">
                  <strong>{{ item.title }}</strong>
                  <i style="border-bottom: 1px solid #a9a9a9"></i>
                  <p>
                    {{ item.content }}
                  </p>
                  <img :src="item.img" alt="" />
                </div>
                <div v-else>更多</div>
              </li>
            </ul>
          </div>
          <!-- <div
            @click="$router.push('/home/MedicalTreatment')"
            class="acquiesce eight"
          >
            <strong>医疗数据全链路安全解决方案</strong>
            <i style="border-bottom: 1px solid #a9a9a9"></i>
            <p>
              通过加密数据的智能分析与处理，实现数据在传输、存储、使用各环节的全面保护，确保患者隐私和敏感信息的绝对安全，同时提升医疗服务的智能化和效率
            </p>
            <img src="../assets/image/quanjing/Slice 78(1).png" alt="" />
          </div>
          <div
            @click="$router.push('/home/Electricity')"
            class="acquiesce nine"
          >
            <strong>电力数据全链路安全解决方案</strong>
            <i style="border-bottom: 1px solid #a9a9a9"></i>
            <p>
              在数据加密状态下进行智能分析和处理，确保电力数据在传输、存储和使用过程中的全面安全保护，同时提升电力系统的智能化管理和运行效率
            </p>
            <img src="../assets/image/quanjing/Slice 78(4).png" alt="" />
          </div>
          <div @click="$router.push('/home/Finance')" class="acquiesce ten">
            <strong>金融数据全链路安全解决方案</strong>
            <i style="border-bottom: 1px solid #a9a9a9"></i>
            <p>
              通过先进的加密和AI智能分析技术，确保金融数据在所有阶段的安全性和隐私保护，提高数据管理效率和系统安全性，以应对日益复杂的安全挑战和监管要求
            </p>
            <img src="../assets/image/quanjing/Slice 78(5).png" alt="" />
          </div>
          <div
            @click="$router.push('/home/GovernmentAffairs')"
            class="acquiesce eleven"
          >
            <strong>政务数据全链路安全解决方案</strong>
            <i style="border-bottom: 1px solid #a9a9a9"></i>
            <p>
              通过全同态加密和AI数据分析，保障政府数据在整个生命周期中的安全性和隐私保护，有效提升管理效能和服务水平
            </p>
            <img src="../assets/image/quanjing/Slice 78(2).png" alt="" />
          </div>
          <div @click="$router.push('/home/Industry')" class="acquiesce twelve">
            <strong>工业互联网数据全链路安全解决方案</strong>
            <i style="border-bottom: 1px solid #a9a9a9"></i>
            <p>
              利用先进的加密技术和智能分析能力，确保工业数据在各环节的安全传输、存储和分析，有效应对数据泄露和网络威胁，同时提升生产效率和智能化管理水平
            </p>
            <img src="../assets/image/quanjing/Slice 78(3).png" alt="" />
          </div>
          <div
            @click="$router.push('/home/DataInteraction')"
            class="acquiesce thirteen"
          >
            <strong>数据交易全链路安全解决方案</strong>
            <i style="border-bottom: 1px solid #a9a9a9"></i>
            <p>
              通过全同态加密技术确保数据在交易过程中的隐私保护和安全传输，有效防范数据泄露和篡改风险，提升交易平台的安全性和信任度
            </p>
            <img src="../assets/image/quanjing/Slice 78(6).png" alt="" />
          </div> -->
        </div>
      </div>
    </div>
    <!-- 新闻动态 -->
    <div class="company-dynamics">
      <h2>新闻<span>动态</span></h2>
      <div class="news">
        <div class="news-text">
          <ul>
            <li
              :class="newsIndex == item.newsId ? 'news-active' : ''"
              @click="
                $router.push({
                  path: '/home/NewDetailsOne',
                  query: { id: item.newsId },
                })
              "
              v-for="item in newsData"
              :key="item.newsId"
              class="li-hover"
              @mousemove="newsMove(item)"
            >
              <div class="default">
                <img src="../assets/image/Slice 84.png" alt="" />
                <span class="time-top">{{
                  item.newsCreateDate.substring(5, 7)
                }}</span>
                <span class="time-gang">/</span>
                <span class="time-bottom">{{
                  item.newsCreateDate.substring(8, 10)
                }}</span>
                <span class="news-contant">{{ item.newsTitle }}</span>
              </div>
            </li>
          </ul>
          <el-button @click="$router.push('/home/NewsTrends')"
            >查看更多</el-button
          >
        </div>
        <div class="news-img">
          <img :src="flagImg" alt="" />
        </div>
      </div>
    </div>
    <!-- 合作伙伴 -->
    <div class="partner">
      <h2>合作<span>伙伴</span></h2>
      <div class="partner-box">
        <div class="partner-top">
          <ul>
            <li v-for="item in 6" :key="item">
              <img
                :src="require('@/assets/image/buddy/buddy' + item + '.png')"
                alt=""
              />
            </li>
            <li v-for="item in 6" :key="item + 'a'">
              <img
                :src="require('@/assets/image/buddy/buddy' + item + '.png')"
                alt=""
              />
            </li>
          </ul>
        </div>
        <div class="partner-bottom">
          <ul>
            <li v-for="item in 6" :key="item">
              <img
                :src="
                  require('@/assets/image/buddy/buddy' + (item + 6) + '.png')
                "
                alt=""
              />
            </li>
            <li v-for="item in 6" :key="item + 'b'">
              <img
                :src="
                  require('@/assets/image/buddy/buddy' + (item + 6) + '.png')
                "
                alt=""
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 合作伙伴 -->
    <!-- <div class="buddy-box">
      <div class="buddy-content">
        <h2 class="buddy-title" style="font-family: 'boldfont'">合作伙伴</h2>
        <div class="buddy-partner">
          <div
            style="overflow: hidden"
            :style="`width:${acquirePartnerList.length * 1.35}rem`"
          >
            <div
              v-for="(item, index) in acquirePartnerList"
              :key="index"
              style="height: 0.8rem"
              :style="{ transform: `translateX(${xOffset}px)` }"
            >
              <img :src="item.partnerPic" style="height: 100%" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { GetAllUserInfoNewStatus, newsList } from "../api/news.js";
import { acquirePartner } from "../api/partner";
import { acquirehome } from "../api/homePage";
export default {
  data() {
    return {
      timerNews: null,
      // 产品示例数据
      productExample: [
        {
          title: "密文智能工控",
          content:
            "专为工控场景定制，提供抗量子加密、同态加密等多种加密方案，并在边缘层提供TEE可信执行环境支持，为工控领域量身打造的全新一代全方位加密协议",
          img: require("../assets/image/quanjing/Slice 78(12).png"),
          path: "/home/IndustrialControl",
        },
        {
          title: "密文数据库",
          content:
            "通过隐算全同态加密算法和密钥管理技术，确保数据在存储和传输过程中的安全性，有效防止明文存储引起的数据泄密、外部黑客攻击以及内部高权限用户的数据窃取",
          img: require("../assets/image/quanjing/Slice 78(8).png"),
          path: "/home/Archive",
        },
        {
          title: "密文云存储",
          content:
            "通过使用隐算全同态加密技术，用户可以在不泄露数据内容的前提下，对存储在云端的数据进行加密处理，确保数据的安全性和隐私性",
          img: require("../assets/image/quanjing/Slice 78@2x.png"),
          path: "/home/CloudStorage",
        },
        {
          title: "密文人脸识别",
          content:
            "在隐算全同态加密下结合AI模型进行人脸特征匹配，整个系统无需解密人脸特征模板即可完成认证，以实现智能安全管理但不泄露人脸特征信息",
          img: require("../assets/image/quanjing/Slice 78(9).png"),
          path: "/home/Ciphertextface",
        },
        {
          title: "密文身份识别",
          content:
            "使用隐算全同态加密算法对身份信息进行密态处理，实现在密文状态下对该信息进行搜索、筛选等计算操作，而不暴露身份信息，保障个人隐私及身份信息安全",
          img: require("../assets/image/quanjing/Slice 78(10).png"),
          path: "/home/EntranceGuard",
        },
        {
          title: "智能汽车个人信息保护",
          content:
            "融合全同态加密与AI大模型技术的智能汽车个人信息保护系统，可加密检测并识别敏感行为，确保隐私安全",
          img: require("../assets/image/quanjing/Slice 78(11).png"),
          path: "/home/BrainPower",
        },
        {
          title: "网约车密文监管",
          content:
            "基于抗量子加密及隐算同态密文下适配AI行为检测模型等模式保护网约车平台中数据安全",
          img: require("../assets/image/quanjing/Slice 78(11).png"),
          path: "/home/ProductCar",
        },
        {
          title: "RFID密文文件管理",
          content:
            "利用隐算全同态加密算法和访问控制，确保文件在传输、存储和使用过程中的机密性、完整性和可用性，防止未经授权的访问和数据泄露",
          img: require("../assets/image/quanjing/Slice 78.png"),
          path: "/home/Rfid",
        },
        {},
      ],
      // 专项解决方案
      specialProgram: [
        {
          title: "隐私计算综合解决方案",
          content:
            "隐算全同态加密技术作为底层核心算法，形成多个产品集群，并广泛应用、适配于各领域的数据安全场景",
          img: require("../assets/image/quanjing/Slice 78@2x (1).png"),
          path: "/home/privacy",
        },
        {
          title: "数据交易全链路安全解决方案",
          content:
            "通过全同态加密技术确保数据在交易过程中的隐私保护和安全传输，有效防范数据泄露和篡改风险，提升交易平台的安全性和信任度",
          img: require("../assets/image/quanjing/Slice 78(6).png"),
          path: "/home/DataInteraction",
        },
        {
          title: "政务数据全链路安全解决方案",
          content:
            "通过全同态加密和AI数据分析，保障政府数据在整个生命周期中的安全性和隐私保护，有效提升管理效能和服务水平",
          img: require("../assets/image/quanjing/Slice 78(2).png"),
          path: "/home/GovernmentAffairs",
        },
        {
          title: "工业互联网数据全链路安全解决方案",
          content:
            "利用先进的加密技术和智能分析能力，确保工业数据在各环节的安全传输、存储和分析，有效应对数据泄露和网络威胁，同时提升生产效率和智能化管理水平",
          img: require("../assets/image/quanjing/Slice 78(3).png"),
          path: "/home/Industry",
        },
        {
          title: "电力数据全链路安全解决方案",
          content:
            "在数据加密状态下进行智能分析和处理，确保电力数据在传输、存储和使用过程中的全面安全保护，同时提升电力系统的智能化管理和运行效率",
          img: require("../assets/image/quanjing/Slice 78(4).png"),
          path: "/home/Electricity",
        },
        {
          title: "金融数据全链路安全解决方案",
          content:
            "通过先进的加密和AI智能分析技术，确保金融数据在所有阶段的安全性和隐私保护，提高数据管理效率和系统安全性，以应对日益复杂的安全挑战和监管要求",
          img: require("../assets/image/quanjing/Slice 78(5).png"),
          path: "/home/Finance",
        },
        {
          title: "医疗数据全链路安全解决方案",
          content:
            "通过加密数据的智能分析与处理，实现数据在传输、存储、使用各环节的全面保护，确保患者隐私和敏感信息的绝对安全，同时提升医疗服务的智能化和效率",
          img: require("../assets/image/quanjing/Slice 78(1).png"),
          path: "/home/MedicalTreatment",
        },
        {
          title: "医疗数据全链路安全解决方案",
          content:
            "通过加密数据的智能分析与处理，实现数据在传输、存储、使用各环节的全面保护，确保患者隐私和敏感信息的绝对安全，同时提升医疗服务的智能化和效率",
          img: require("../assets/image/quanjing/Slice 78(1).png"),
          path: "",
        },
      ],
      newsIndex: null, // 新闻选中状态
      flagImg: "",
      newsData: [], // 新闻列表
      conditions: {
        total: 0,
        page: 1,
        pageSize: 10,
        newsType: 0,
      },
      news: [{ newsTitle: null }], // 行业动态
      acquirePartnerList: [], // 合作伙伴数组
      acquirehomeList: [], // 轮播图数组
      rootFontSize: null, // 用于存储根标签的字体大小
      timeProduct: null,
      switchPictureIndex: 0,
      cameraHome: "camera-home1",
      productdata: [
        {
          id: 1,
          path: "/home/CiphertextCamera",
          title: "隐算密文摄像头",
          content: "隐算密文摄像头基于隐算全同态技术，将采集的视频......",
        },
        {
          id: 2,
          path: "/home/IdentityReading",
          title: "隐算加密身份读取设备",
          content: "隐算全同态加密身份读取设备，使用全同态加密算法......",
        },
        {
          id: 3,
          path: "/home/EncryptedDatabase",
          title: "隐算加密数据库",
          content: "隐算加密数据库通过 对整个数据库进行全同态加密 ，......",
        },
        {
          id: 4,
          path: "/home/EncryptionServer",
          title: "隐算加密服务器",
          content: "隐算加密服务器是一种特殊的可信计算服务器，能够......",
        },
        {
          id: 5,
          path: "/home/EncryptionChip",
          title: "隐算加密芯片",
          content: "隐算加密芯片是针对隐算全同态加密算法进行优化设......",
        },
      ],
      items: [
        // 盒子的数据
      ],

      xOffset: 0, // 水平偏移量
      interval: null, // 定时器
    };
  },
  computed: {
    imagePath() {
      return this.cameraHome;
    },
  },
  created() {
    this.newGetAllUserInfoList();
    // this.timeFn();
    // this.acquirePartnerEvent();
    // this.acquirehomeEvent();
  },
  mounted() {
    this.xinwenlunbo()
    this.getRootFontSize(); // 初始化时获取一次根标签字体大小
    window.addEventListener("resize", this.getRootFontSize); // 监听窗口的resize事件
    this.startScroll(); // 页面加载后开始滚动
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getRootFontSize); // 组件销毁前移除resize事件监听
    clearInterval(this.timerNews)
  },
  methods: {
    // 新闻轮播
    xinwenlunbo() {
      var num = 0
      this.timerNews = setInterval(() => {
        if(num == 4) {
          num= 0
        }
        this.newsIndex = this.newsData[num].newsId
        this.flagImg = this.newsData[num].newsPic
   
        num++
      }, 2000);
    },
    newsMove(val) {
      this.flagImg = val.newsPic;
      this.newsIndex = val.newsId;
    },
    // 查询公司新闻列表
    async newGetAllUserInfoList() {
      const { data } = await newsList(1, 4, 1);
      console.log(data);
      this.newsData = data.data.content;
      this.newsIndex = data.data.content[0].newsId;
      this.flagImg = data.data.content[0].newsPic;
    },
    // // 查询新闻列表
    // async GetAllUserInfoList() {
    //   const { data } = await GetAllUserInfoNewStatus(
    //     this.conditions.page,
    //     this.conditions.pageSize,
    //     this.conditions.newsType,
    //     1
    //   );
    //   this.news = data.data.content;
    // },
    // 获取合作伙伴列表
    async acquirePartnerEvent() {
      const { data } = await acquirePartner(1, 100, 1);
      this.acquirePartnerList = data.data.content.concat(data.data.content);
    },
    // 获取轮播图列表
    async acquirehomeEvent() {
      const { data } = await acquirehome(1, 100, 1);
      this.acquirehomeList = data.data.content;
    },
    getRootFontSize() {
      const computedStyle = getComputedStyle(document.documentElement);
      this.rootFontSize = parseFloat(computedStyle.fontSize);
    },
    timeFn() {
      this.switchPictureIndex = -1;
      this.timeProduct = setInterval(() => {
        if (this.switchPictureIndex == 4) {
          this.switchPictureIndex = -1;
        }
        if (this.switchPictureIndex == -1) {
          this.cameraHome = "camera-home1";
        } else if (this.switchPictureIndex == 0) {
          this.cameraHome = "camera-home2";
        } else if (this.switchPictureIndex == 1) {
          this.cameraHome = "camera-home3";
        } else if (this.switchPictureIndex == 2) {
          this.cameraHome = "camera-home4";
        } else if (this.switchPictureIndex == 3) {
          this.cameraHome = "camera-home5";
        }
        this.switchPictureIndex++;
      }, 3000);
    },
    timeFnClear() {
      clearInterval(this.timeProduct); // 清除定时器
      this.timeProduct = null; // 将定时器变量置为null
    },
    startScroll() {
      this.interval = setInterval(() => {
        if (
          this.xOffset <=
          (-this.acquirePartnerList.length / 2) * 1.35 * this.rootFontSize
        ) {
          this.xOffset = 0;
        }
        this.xOffset--; // 每秒移动的距离，你可以根据需要自行调整
      }, 1000 / 120); // 60帧每秒，这样可以实现平滑的动画效果
    },
    switchPictureOut(e) {
      this.timeFn();
      const liElement = e.target; // 获取当前点击的元素
      const index = Array.from(liElement.parentNode.children).indexOf(
        liElement
      ); // 获取当前元素在父元素中的索引
      this.switchPictureIndex = index;
    },
    switchPicture(e) {
      const liElement = e.target; // 获取当前点击的元素
      const index = Array.from(liElement.parentNode.children).indexOf(
        liElement
      ); // 获取当前元素在父元素中的索引
      this.switchPictureIndex = index;
      this.timeFnClear();
      if (index == 0) {
        this.cameraHome = "camera-home1";
      } else if (index == 1) {
        this.cameraHome = "camera-home2";
      } else if (index == 2) {
        this.cameraHome = "camera-home3";
      } else if (index == 3) {
        this.cameraHome = "camera-home4";
      } else if (index == 4) {
        this.cameraHome = "camera-home5";
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.interval); // 组件销毁前清除定时器
    clearInterval(this.timeProduct); // 组件销毁前清除定时器
  },
};
</script>

<style lang="less" scoped>
/* 在小屏幕上的样式 */
@media (max-width: 767px) {
  /* 添加适合小屏幕的样式 */
  /* 添加适合大屏幕的样式 */
  @keyframes moveX {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-3438px);
    }
  }
  @keyframes the-moveX {
    0% {
      transform: translateX(-3438px);
    }
    100% {
      transform: translateX(0);
    }
  }
  .block {
    width: 100%;
    height: 100%;
    // 首页背景图
    .home-bg {
      position: relative;
      width: 100%;
      height: 2437px;
      background: url("../assets/image/Slice 80@2x (2).png");
      background-repeat: no-repeat;
      background-size: cover;
      .bg-info {
        position: absolute;
        width: 100%;
        left: 50%;
        top: 465px;
        transform: translate(-50%, 0);
        text-align: center;
        h2 {
          color: #fff;
          font-family: "boldfont";
          font-weight: 400;
          font-size: 40px;
          margin: 0;
          font-size: 123px;
        }
        p {
          margin: 0;
          margin-top: 61px;
          font-size: 61px;

          margin-bottom: 111px;
          color: #fff;
          font-family: "refont";
        }
        .el-button {
          width: 613px;
          height: 184px;
          margin-top: 169px;
          background: url("../assets/image/Slice 85.png");
          background-repeat: no-repeat;
          background-size: cover;
          color: #fff;
          border: none;
          font-size: 72px;
        }
      }
      // 浮动块父盒子
      .floatInfo {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
        width: 1459px;
        height: 1044px;
        // display: flex;
        background: linear-gradient(
            2deg,
            rgba(213, 232, 255, 0) 0%,
            rgba(213, 232, 255,.8) 100%
          ),
          rgba(255, 255, 255, 1);
        box-shadow: 0px 12px 36px 0px #e0e4ee;
        // justify-content: space-between;
        position: absolute;
        z-index: 1;
        padding: 102px 0 0 132px;
        box-sizing: border-box;
        border-radius: 41px 41px 41px 41px;
        // 浮动块展示信息
        .floatInfo-item {
          position: relative;

          border-radius: 8px;
          border: 1px solid transparent;
          border-image: #fff;
          border-image-slice: 1; /* 控制边框图像的填充方式 */
          /* 毛玻璃效果 */
          backdrop-filter: blur(12px); /* 标准语法 */
          -webkit-backdrop-filter: blur(6px); /* WebKit 浏览器的前缀 */
          box-sizing: border-box;
          display: flex;
          // justify-content: center;
          align-items: center;
          font-size: 61px;
          color: #202124;
          line-height: 72px;
          height: 164px;
          margin-bottom: 61px;
          img {
            width: 164px;
            height: 164px;
            margin-right: 61px;
            vertical-align: middle;
            // position: absolute;
            // left: 25px;
            // top: 16px;
          }
          span {
            // position: absolute;
            // left: 85px;
            // top: 30px;
            display: inline-block;
            font-size: 61px;
            color: #202124;
            font-family: "boldfont";
          }
        }
      }
    }
    .one-title {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 316px;
      width: 650px;
      height: 306px;
      z-index: 1;
      text-align: center;
      color: #fff;
    }
    .two-title {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 132px;
      // width: ;
      height: 306px;
      z-index: 1;
      text-align: center;
      color: #fff;
    }
    .three-title {
      position: absolute;
      left: 50%;
      top: 203px;
      transform: translateX(-50%);
      width: 1200px;
      z-index: 1;
      box-sizing: border-box;
      color: #fff;
      font-size: 48px;
      line-height: 56px;
      letter-spacing: 6px;
    }
    .one-icon {
      width: 60px;
      height: 60px;
      background: url("../assets/image/microphone-icn.png");
      background-repeat: no-repeat;
      background-size: cover;
      margin: auto;
    }
    > img {
      position: relative;
      width: 100%;
      height: 100%;
      // height: 100%;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      //   height: 100%;
      // object-fit: contain;
      position: absolute;
    }
  }
  // 技术展示图区域
  .technology-demonstration {
    width: 100%;
    height: 900px;
    background: #f3f8ff;
  }
  // 隐算产品全景图
  .product-panorama {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    // height: 1479px;
    background: #fafcff;
    background: #fff;
    margin-top: 772px;
    .product-panorama-box {
      position: relative;
      width: 100%;

      // background: #59ebff;
      h2 {
        font-family: "boldfont";
        text-align: center;
        font-size: 82px;
        margin: 0px 0 0;
        padding-bottom: 82px;
        background: linear-gradient(135deg, #eef3fd, #eef3fd);
        background: #fafcff;
        strong {
          color: #0066d4;
        }
      }
      .text-with-gradient {
        background: linear-gradient(
          180deg,
          rgba(53, 101, 252, 0) 0%,
          #3665fe 100%
        );
        -webkit-background-clip: text; /* Safari/Chrome */
        -webkit-text-fill-color: transparent; /* Safari/Chrome */
        color: transparent;
        font-size: 64px;
      }
      .two-title {
        display: block;
        font-size: 82px;
        font-family: "boldfont";
        text-align: center;
        // margin-left: 20px;
        margin: 82px 0 160px;
      }
      // 产品示例
      .shili {
        ul {
          overflow: auto;
          height: 2800px;
          margin: 0;
          padding: 0;
          margin-top: -100px;
          li {
            // float: left;
            position: relative;
            margin: 0 auto;
            margin-top: 140px;
            margin-bottom: 271px;
            width: 1613px;
            height: 676px;
            background: #ffffff;
            box-shadow: 0px 0px 23px 0px #e0e4ee;
            border-radius: 12px 12px 12px 12px;
            strong {
              font-family: "boldfont";
              position: absolute;
              left: 82px;
              top: 82px;
              font-size: 82px;
              color: #202124;
            }
            i {
              position: absolute;
              left: 82px;
              top: 220px;
              display: inline-block;
              width: 819px;
              border: none;
              // border-bottom: 1px solid #a9a9a9;
            }
            p {
              font-family: "refont";
              position: absolute;
              left: 82px;
              color: #666666;
              top: 266px;
              margin: 0;
              font-size: 61px;
              width: 1459px;
            }
            img {
              width: 476px;
              height: 476px;
              position: absolute;
              right: 133px;
              top: -215px;
            }
          }
          // li:nth-child(3n) {
          //   margin-right: 0;
          // }
          li:last-child {
            display: none;
            width: 183px;
            height: 144px;
            background: #ffffff !important;
            box-shadow: 0px 0px 23px 0px rgba(57, 92, 145, 0.25);
            border-radius: 12px 12px 12px 12px;
            text-align: center;
            color: rgba(0, 0, 0, 0.85);
            line-height: 144px;
            font-size: 20px;
            color: #999b9f !important;
            font-family: "myfont";
            cursor: initial !important;
          }
          li:hover {
            cursor: pointer;
            background: #3565fc;
            color: #fff;
            strong {
              color: #fff;
            }
            i {
              border: 1px solid #fafcff;
            }
            p {
              color: #fff;
            }
          }
          li:last-child:hover {
            width: 183px;
            height: 144px;
            background: #ffffff;
            box-shadow: 0px 0px 23px 0px rgba(57, 92, 145, 0.25);
            border-radius: 12px 12px 12px 12px;
            text-align: center;
            color: rgba(0, 0, 0, 0.85);
            line-height: 144px;
            font-size: 20px;
            color: #999b9f;
            font-family: "myfont";
          }
        }
      }
      .shili1 {
        ul {
          overflow: auto;
          margin: 0;
          padding: 0;
          height: 2800px;
          margin-top: -100px;
          li {
            // float: left;
            position: relative;
            margin: 0 auto;
            margin-top: 140px;
            margin-bottom: 271px;
            width: 1613px;
            height: 676px;
            background: #ffffff;
            box-shadow: 0px 0px 23px 0px #e0e4ee;
            border-radius: 12px 12px 12px 12px;
            strong {
              font-family: "boldfont";
              position: absolute;
              left: 82px;
              top: 61px;
              font-size: 82px;

              width: 819px;
              line-height: 95px;
              height: 195px;
              color: #202124;
            }
            i {
              position: absolute;
              left: 82px;
              top: 287px;
              display: inline-block;
              width: 819px;
              border: none;
              // border-bottom: 1px solid #a9a9a9;
            }
            p {
              font-family: "refont";
              position: absolute;
              left: 82px;
              color: #666666;
              top: 323px;
              margin: 0;
              font-size: 61px;
              width: 1459px;
            }
            img {
              width: 476px;
              height: 476px;
              position: absolute;
              right: 133px;
              top: -215px;
            }
          }
          // li:nth-child(3n) {
          //   margin-right: 0;
          // }
          li:last-child {
            display: none;
            width: 183px;
            height: 144px;
            background: #ffffff !important;
            box-shadow: 0px 0px 23px 0px rgba(57, 92, 145, 0.25);
            border-radius: 12px 12px 12px 12px;
            text-align: center;
            color: rgba(0, 0, 0, 0.85);
            line-height: 144px;
            font-size: 20px;
            color: #999b9f !important;
            font-family: "myfont";
            cursor: initial !important;
          }
          li:hover {
            cursor: pointer;
            background: #3565fc;
            color: #fff;
            strong {
              color: #fff;
            }
            i {
              border: 1px solid #fafcff;
            }
            p {
              color: #fff;
            }
          }
          li:last-child:hover {
            width: 183px;
            height: 144px;
            background: #ffffff;
            box-shadow: 0px 0px 23px 0px rgba(57, 92, 145, 0.25);
            border-radius: 12px 12px 12px 12px;
            text-align: center;
            color: rgba(0, 0, 0, 0.85);
            line-height: 144px;
            font-size: 20px;
            color: #999b9f;
            font-family: "myfont";
          }
        }
      }
      // 全景图定位盒子

      .acquiesce {
        cursor: pointer;
        width: 370px;
        height: 144px;
        background: #ffffff;
        box-shadow: 0px 0px 23px 0px #e0e4ee;
        border-radius: 12px 12px 12px 12px;
        strong {
          font-family: "refont";
          position: absolute;
          left: 20px;
          top: 36px;
          font-weight: bold;
          font-size: 16px;
          color: rgba(0, 0, 0, 0.85);
        }
        i {
          position: absolute;
          left: 20px;
          top: 67px;
          display: inline-block;
          width: 193px;
          border: none;
          // border-bottom: 1px solid #a9a9a9;
        }
        p {
          font-family: "refont";
          position: absolute;
          left: 20px;
          color: #666666;
          top: 76px;
          margin: 0;
          font-size: 12px;
          width: 330px;
          height: 48px;
        }
        img {
          width: 114px;
          position: absolute;
          right: 28px;
          top: -32px;
        }
      }
      .acquiesce1 {
        width: 370px;
        height: 144px;
        background: #ffffff;
        box-shadow: 0px 0px 23px 0px #e0e4ee;
        border-radius: 12px 12px 12px 12px;
        strong {
          font-family: "refont";
          position: absolute;
          left: 20px;
          top: 36px;
          font-weight: bold;
          font-size: 16px;
          color: rgba(0, 0, 0, 0.85);
        }
        i {
          position: absolute;
          left: 20px;
          top: 67px;
          display: inline-block;
          width: 193px;
          border: none;
          // border-bottom: 1px solid #a9a9a9;
        }
        p {
          font-family: "refont";
          position: absolute;
          left: 20px;
          color: #666666;
          top: 76px;
          margin: 0;
          font-size: 12px;
          width: 330px;
          height: 48px;
        }
        img {
          width: 114px;
          position: absolute;
          right: 28px;
          top: -32px;
        }
      }
      .one {
        position: absolute;
        left: 0;
        top: 215px;
      }
      .two {
        position: absolute;
        left: 428px;
        top: 215px;
      }
      .three {
        position: absolute;
        left: 856px;
        width: 344px;
        top: 215px;
        i {
          width: 169px;
        }
        p {
          width: 304px;
        }
      }
      .four {
        position: absolute;
        left: 593px;
        width: 415px;
        top: 411px;
        i {
          width: 239px;
        }
        p {
          width: 375px;
        }
      }
      .five {
        position: absolute;
        left: 161px;
        width: 404px;
        top: 411px;
        i {
          width: 225px;
        }
        p {
          width: 364px;
        }
      }
      .six {
        position: absolute;
        left: 27px;
        width: 414px;
        top: 607px;
        i {
          width: 238px;
        }
        p {
          width: 374px;
        }
      }
      .seven {
        position: absolute;
        left: 498px;
        width: 392px;
        top: 607px;
        i {
          width: 215px;
        }
        p {
          width: 352px;
        }
      }
      .more {
        position: absolute;
        left: 946px;
        width: 183px;
        top: 607px;
        text-align: center;
        color: rgba(0, 0, 0, 0.85);
        line-height: 144px;
        font-size: 20px;
        color: #999b9f;
        font-family: "myfont";
      }
      .acquiesce:hover {
        background: #3565fc !important;
        color: #fff !important;
        strong {
          color: #fff;
        }
        i {
          border: 1px solid #fafcff;
        }
        p {
          color: #fff;
        }
      }
      // 虚线
      .line1 {
        // margin: 80px 130px 0 100px;
        margin-top: 72px;
        width: 955px;
        height: 1px;
        border: none;
        margin-left: 114px;
        border-bottom: 1px dashed rgba(53, 101, 252, 0.6);
      }
      .line1-two {
        // margin: 80px 130px 0 100px;
        margin-top: 72px;
        width: 912px;
        height: 1px;
        border: none;
        margin-left: 114px;
        border-bottom: 1px dashed rgba(53, 101, 252, 0.6);
      }
      .line2 {
        width: 200px;
        height: 198px;
        border: none;
        margin-left: 1070px;
        border-left: 1px dashed rgba(53, 101, 252, 0.6); /* 虚线的样式和颜色 */
      }
      .line2-two {
        width: 200px;
        height: 198px;
        border: none;
        margin-left: 1027px;
        border-left: 1px dashed rgba(53, 101, 252, 0.6); /* 虚线的样式和颜色 */
      }
      .line3 {
        width: 955px;
        height: 1px;
        border: none;
        margin-left: 114px;
        border-bottom: 1px dashed rgba(53, 101, 252, 0.6); /* 虚线的样式和颜色 */
      }
      .line4 {
        width: 955px;
        height: 197px;
        border: none;
        margin-left: 114px;
        border-left: 1px dashed rgba(53, 101, 252, 0.6); /* 虚线的样式和颜色 */
      }
      .line5 {
        width: 955px;
        height: 1px;
        border: none;
        margin-left: 114px;
        border-bottom: 1px dashed rgba(53, 101, 252, 0.6); /* 虚线的样式和颜色 */
      }
      // 专项
      .zhuangxiang {
        margin-top: 32px;
      }
      .eight {
        position: absolute;
        left: 0px;
        width: 432px;
        top: 882px;
        i {
          width: 254.69px;
        }
        p {
          width: 392px;
        }
      }
      .nine {
        position: absolute;
        left: 490px;
        width: 431px;
        top: 882px;
        i {
          width: 255px;
        }
        p {
          width: 391px;
        }
      }
      .ten {
        position: absolute;
        left: 663px;
        width: 435px;
        top: 1076px;
        i {
          width: 254.69px;
        }
        p {
          width: 395px;
        }
      }
      .eleven {
        position: absolute;
        left: 173px;
        width: 435px;
        top: 1076px;
        i {
          width: 254.69px;
        }
        p {
          width: 395px;
        }
      }
      .twelve {
        position: absolute;
        left: -21px;
        width: 491px;
        top: 1271px;
        i {
          width: 314px;
        }
        p {
          width: 451px;
        }
      }
      .thirteen {
        position: absolute;
        left: 536px;
        width: 434px;
        top: 1271px;
        i {
          width: 254px;
        }
        p {
          width: 394px;
        }
      }
      .more1 {
        position: absolute;
        left: 1018px;
        width: 183px;
        top: 1271px;
        text-align: center;
        color: rgba(0, 0, 0, 0.85);
        line-height: 144px;
        font-size: 20px;
        color: #999b9f;
        font-family: "myfont";
      }
      // -webkit-.title1 {
      //   /* 设置文字颜色为透明 */
      //   color: transparent;

      //   /* 设置背景图片 */
      //   background-image: linear-gradient(
      //     89.99999999999993deg,
      //     rgba(53, 101, 252, 0) 22%,
      //     #3565fc 100%
      //   );

      //   /* 设置背景裁剪方式为文字 */
      //   -webkit-background-clip: text;
      //   background-clip: text;

      //   /* 设置文字填充色，也就是背景渐变的终点颜色 */
      //   text-fill-color: #3565fc;
      // }
    }
  }
  // 新闻动态
  .company-dynamics {
    position: relative;
    background: url("../assets/image/Slice 81@2x.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 2637px;
    margin-top: 123px;
    h2 {
      font-size: 82px;
      font-family: "boldfont";
      text-align: center;
      margin: 102px 0 61px 0;
      span {
        color: #0066d4;
      }
    }
    .news {
      // display: flex;
      // justify-content: space-between;
      // flex-direction: column;
      width: 100%;
      // height: 400px;
      margin: 0 auto;
      .news-text {
        width: 100%;
        height: 100%;
        // background: red;
        .el-button {
          display: none;
        }
        ul {
          width: 100%;
          margin: 0 auto;
          padding: 0;
          margin-top: 16px;
          li {
            width: 1756px;
            height: 246px;
            display: flex;
            align-items: center;
            margin-bottom: 61px;
            margin: 0 auto;
            margin-bottom: 61px;
            .default {
              font-family: "refont";
              position: relative;
              list-style: none;
              width: 1756px;

              padding: 0 0px 0 82px;
              background: rgba(255, 255, 255, 0.1);
              box-shadow: 0px 4px 4px 0px rgba(100, 107, 146, 0.3);
              border-radius: 12px 12px 12px 12px;
              border: 1px solid #ffffff;
              margin-bottom: 61px;
              box-sizing: border-box;
              font-size: 61px !important;
              height: 246px;
              line-height: 246px;
              img {
                display: none;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
              }
              .news-contant {
                width: 1034px;
                display: -webkit-box;
                -webkit-line-clamp: 1; /* 控制显示行数 */
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                float: right;
                margin-right: 369px;
              }
            }
          }
          li:hover {
            .default {
              height: 64px;
              line-height: 64px;
              padding-left: 76px;
              background: rgba(0, 59, 255, 0.74);
              transition: height 0.3s ease; /* 过渡动画属性 */
              color: #fff;
              img {
                display: block;
              }
              .news-contant {
                width: 269px;
                display: -webkit-box;
                -webkit-line-clamp: 1; /* 控制显示行数 */
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                float: right;
                margin-right: 30px;
              }
              .time-gang {
                position: absolute;
                left: 43px;
                font-size: 32px;
                font-weight: 100;
                font-weight: 200px;
                transform: rotate(20deg);
                transition: font-size 0.3s ease; /* 过渡动画属性 */
                transition: left 0.3s ease; /* 过渡动画属性 */
                transition: transform 0.3s ease; /* 过渡动画属性 */
              }
              .time-top {
                font-size: 20px;
                display: inline-block;
                position: absolute;
                left: 28px;
                top: -8px;
                transition: font-size 0.3s ease; /* 过渡动画属性 */
                transition: left 0.3s ease; /* 过渡动画属性 */
                // transition: transform 0.3s ease; /* 过渡动画属性 */
              }
              .time-bottom {
                font-size: 20px;
                display: inline-block;
                position: absolute;
                left: 54px;
                top: 8px;
                transition: font-size 0.3s ease; /* 过渡动画属性 */
                transition: left 0.3s ease; /* 过渡动画属性 */
                // transition: transform 0.3s ease; /* 过渡动画属性 */
              }
            }
          }
          .news-active {
            .default {
              width: 1756px;
              height: 246px;
              line-height: 246px;
              padding-left: 76px;
              background: rgba(0, 59, 255, 0.74);
              transition: height 0.3s ease; /* 过渡动画属性 */
              color: #fff;
              img {
                display: block;
              }
              .news-contant {
                width: 1034px;
                display: -webkit-box;
                -webkit-line-clamp: 1; /* 控制显示行数 */
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                float: right;
                margin-right: 282px;
              }
              .time-gang {
                position: absolute;
                left: 154px;
                font-weight: 100;
                font-size: 120px;
                transform: rotate(20deg);
                transition: font-size 0.3s ease; /* 过渡动画属性 */
                transition: left 0.3s ease; /* 过渡动画属性 */
                transition: transform 0.3s ease; /* 过渡动画属性 */
              }
              .time-top {
                font-size: 72px;
                display: inline-block;
                position: absolute;
                left: 82px;
                top: -31px;
                transition: font-size 0.3s ease; /* 过渡动画属性 */
                transition: left 0.3s ease; /* 过渡动画属性 */
                // transition: transform 0.3s ease; /* 过渡动画属性 */
              }
              .time-bottom {
                font-size: 72px;
                display: inline-block;
                position: absolute;
                left: 200px;
                top: 30px;
                transition: font-size 0.3s ease; /* 过渡动画属性 */
                transition: left 0.3s ease; /* 过渡动画属性 */
                // transition: transform 0.3s ease; /* 过渡动画属性 */
              }
            }
          }
        }
        .el-button {
          margin-top: 14px;
          width: 118px;
          height: 40px;
          background: linear-gradient(
            185deg,
            #00c2ff 0%,
            rgba(0, 59, 255, 0.8) 50%,
            #00c2ff 100%
          );
          border-radius: 8px; /* 或者使用具体的8px 8px 8px 8px，效果相同 */
          color: #fff;
          font-size: 14px;
          font-family: "myfont";
        }
      }
      .news-img {
        width: 1756px;
        height: 983px;
        margin: 0 auto;
        background: rgba(255, 255, 255, 0.3);
        border-radius: 12px 12px 12px 12px;
        border: 2px solid #ffffff;
        box-sizing: border-box;
        /* 毛玻璃效果 */
        backdrop-filter: blur(6px); /* 标准语法 */
        -webkit-backdrop-filter: blur(6px); /* WebKit 浏览器的前缀 */
        box-sizing: border-box;
        padding: 20px;
        box-sizing: border-box;
        img {
          width: 100%;
          height: 100%;
          border-radius: 12px 12px 12px 12px;
          border: 1px solid #ffffff;
        }
      }
    }
  }
  .partner {
    width: 100%;
    height: 1013px;
    background: #f2f7ff;
    h2 {
      font-size: 82px;
      font-family: "boldfont";
      text-align: center;
      margin: 163px 0 87px 0;
      span {
        color: #0066d4;
      }
    }
    .partner-box {
      width: 100%;
      margin: 0 auto;
      overflow: hidden;
      .partner-top {
        margin-bottom: 44px;
        ul {
          // height: 64px;
          margin: 0;
          padding: 0;
          width: 6876px;
          // width: 1104px;
          animation: moveX 25s linear infinite; /* 设置动画属性 */
        }
      }

      ul {
        // height: 64px;
        // display: flex;
        overflow: hidden;
        li {
          float: left;
          list-style: none;
          display: inline-block;
display: flex;
justify-content: center;
align-items: center;
          margin-right: 61px;
          background: linear-gradient( 270deg, #FFFFFF 0%, rgba(255,255,255,0) 100%);
border-radius: 41px 41px 41px 41px;
          position: relative;
          background: #fff;
          width: 512px;
height: 205px;
// background: linear-gradient( 270deg, #FFFFFF 0%, rgba(255,255,255,0) 100%);
          img {
            position: absolute;
          //  width: 435px;
           height: 192px;
            vertical-align: middle;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
      .partner-bottom ul {
        // height: 64px;
        margin: 0;
        padding: 0;
        width: 6876px;
        // width: 1104px;
        margin-top: 67px;
        animation: the-moveX 25s linear infinite; /* 设置动画属性 */
      }
      .partner-top {
      }
      .partner-bottom {
      }
    }
  }

  .dynamicState-btn:hover {
    // font-size: 18px !important;
    // transform: scale(1.03);
    // transition: transform 1s ease;
  }

  .dynamicState-box {
    position: relative;
    width: 1193px;
    display: flex;
    justify-content: space-between;
    .dynamicState-line {
      position: absolute;
      width: 1px;
      height: 300px;
      background: #1450bb;
      left: 50%;
      top: 62px;
    }
    //  动态左侧内容
    .dynamicState-left:hover {
      transform: scale(1.02);
      transition: transform 1s ease;
    }
    .dynamicState-left {
      color: #fff;
      box-sizing: border-box;
      padding: 24px;
      width: 551px;
      height: 424px;
      background: #1450bb;
      box-shadow: 0px 5px 5px 1px rgba(35, 44, 69, 0.2);
      border-radius: 12px 12px 12px 12px;
      .convention {
        width: 503px;
        height: 278px;
        // background: url("../assets/image/convention.jpg");
        background-repeat: no-repeat;
        background-size: cover;
      }
      p {
        // width: 2rem;
        display: -webkit-box;
        -webkit-line-clamp: 1; /* 控制显示行数 */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .convention-time {
        font-size: 14px;
        margin-top: 27px;
        width: 503px;
        display: flex;
        justify-content: space-between;
      }
    }
    // 动态右侧区域
    .dynamicState-right {
      width: 552px;
      height: 424px;
      ul {
        height: 424px;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 8px;
        li:hover {
          transform: scale(1.03);
          transition: transform 1s ease;
        }
        li {
          p {
            // width: 2rem;
            display: -webkit-box;
            -webkit-line-clamp: 1; /* 控制显示行数 */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .state {
            background: url("../assets/image/state1.png");
            background-repeat: no-repeat;
            background-size: cover;
          }
          .state2 {
            background: url("../assets/image/state2.png");
            background-repeat: no-repeat;
            background-size: cover;
          }
          .state3 {
            background: url("../assets/image/state3.png");
            background-repeat: no-repeat;
            background-size: cover;
          }
          p {
            font-size: 20px;
          }
          span {
            font-size: 14px;
          }
          padding: 8px 0;
          box-sizing: border-box;
          background: #ffffff;
          box-shadow: 0px 5px 5px 1px rgba(35, 44, 69, 0.2);
          border-radius: 12px 12px 12px 12px;
          display: flex;
          justify-content: space-around;
        }
      }
    }
  }
  // 公司信息
  .Company-info {
    width: 100%;
    height: 834px;
    background-color: #fff;
    margin-top: 242px;
    .origin {
      margin-right: 9px;
      display: inline-block;
      width: 12px;
      height: 12px;
      background: #1450bb;
      border-radius: 33px 33px 33px 33px;
    }
    .Company-box {
      width: 1193px;
      height: 480px;
      margin: 160px auto;
      display: flex;
      justify-content: space-between;
      p {
        font-family: myfont;
      }
    }
    .Company-img {
      width: 590px;
      height: 480px;
      background: url("../assets/image/show-Company.jpg");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  // 产品介绍
  .product-box {
    width: 100%;
    height: 840px;
    background: linear-gradient(135deg, #252d45 0%, #1e2843 100%);
    .product-content {
      box-sizing: border-box;
      width: 1200px;
      height: 607px;
      margin: 107px auto;
    }
    .product-left {
      float: left;
      margin-right: 36px;
      width: 488px;
      height: 456px;
      // background-color: yellow;
      ul {
        margin: 0;
        padding: 0;
        .old-li {
          font-size: 24px;
          width: 488px;
          height: 76px;
          color: #fff;
          line-height: 76px;
          // margin-bottom: 40px;
          background-color: transparent;
          transition: background-color 0.3s ease;
          .openBox1 {
            display: inline-block;
          }
          .openBox {
            display: none;
          }

          .triangle {
            display: inline-block;
            margin-right: 19px;
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 20px solid #59ebff; /* 可以将颜色修改为你想要的颜色 */
          }
        }
        .new-li {
          position: relative;
          width: 488px;
          font-size: 24px;
          height: 161px;
          color: #fff;
          line-height: 161px;
          background-color: #2f4165;
          border-radius: 12px 12px 12px 12px;
        }
        .triangleNew {
          border: 0;
          display: inline-block;
          width: 8px;
          vertical-align: middle;
          height: 100px;
          background-color: #59ebff;
          border-radius: 12px 12px 12px 12px;
        }
        .openBox {
          display: block;
          width: 408px;
          height: 102px;
          position: absolute;
          left: 40px;
          top: 50%;
          transform: translateY(-50%);
        }
        // li:hover .openBox1 {
        //   display: none;
        // }
        .line-top {
          width: 100%;
          display: flex;
          height: 48px;
          line-height: 48px;
          justify-content: space-between;
        }
        .line-bottom {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 100%;
          // display: flex;
          height: 24px;
          line-height: 24px;
          margin-top: 19px;
          // justify-content: space-between;
        }
        .line {
          line-height: 0;
          margin-top: 9px;
          // transform: translateY(-50%);
          width: 408px;
          height: 1px;
          background: rgba(255, 255, 255, 0.2);
        }
      }
    }
    .product-right {
      float: left;
      width: 674px;
      height: 456px;
      background: rgba(20, 80, 187, 0.2);
      border-radius: 16px 16px 16px 16px;
      border: 1px solid #3982f7;
      padding: 12px;
      box-sizing: border-box;
      .camera-home1 {
        width: 652px;
        height: 430px;
        border-radius: 16px 16px 16px 16px;
        background: url("../assets/image/pro/product-one.jpg");
        background-repeat: no-repeat;
        background-size: cover;
      }
      .camera-home2 {
        width: 652px;
        height: 430px;
        border-radius: 16px 16px 16px 16px;
        background: url("../assets/image/pro/product-two.jpg");
        background-repeat: no-repeat;
        background-size: cover;
      }
      .camera-home3 {
        width: 652px;
        height: 430px;
        border-radius: 16px 16px 16px 16px;
        background: url("../assets/image/pro/product-three.jpg");
        background-repeat: no-repeat;
        background-size: cover;
      }
      .camera-home4 {
        width: 652px;
        height: 430px;
        border-radius: 16px 16px 16px 16px;
        background: url("../assets/image/pro/product-four.jpg");
        background-repeat: no-repeat;
        background-size: cover;
      }
      .camera-home5 {
        width: 652px;
        height: 430px;
        border-radius: 16px 16px 16px 16px;
        background: url("../assets/image/pro/product-five.jpg");
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
  //合作伙伴
  .buddy-box {
    width: 100%;
    height: 553px;
    background-color: #fff;
    .buddy-content {
      box-sizing: border-box;
      width: 1200px;
      margin: 120px auto;
      .buddy-title {
        height: 60px;
        font-size: 40px;
        font-weight: bold;
        color: #000000;
        line-height: 47px;
        margin-bottom: 88px;
      }
      // 合作方
      .buddy-partner {
        overflow: hidden;
        width: 1200px;
        height: 201px;
        > div > div {
          display: flex;
          align-items: center;
          float: left;
          margin-right: 84px;
          width: 240px;
          height: 132px;
          line-height: 132px;
          padding: 26px 0;
          box-sizing: border-box;
          text-align: center;
        }
        // display: flex;
        // justify-content: space-between;
        .partner > div {
          width: 240px;
          height: 132px;
          display: flex;
          justify-content: center;
          align-items: center;
          float: left;
        }
      }
    }
  }
  // 轮播图箭头
  /deep/.el-carousel__arrow {
    width: 80px;
    height: 80px;
    font-size: 36px;
  }
  .zoom {
    animation: zoomAnimation 2s infinite;
  }

  @keyframes zoomAnimation {
    0%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.3);
    }
  }
  // .el-caro
  .dynamicState-btn {
    font-size: 20px;
    position: relative;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    opacity: 1;
    color: #1450bb;
    border: 1px solid #1450bb;
    box-sizing: border-box;
  }
  // .circle {
  //   position: absolute;
  //   top: 0;
  //   left: 50%;
  //   transform: translateX(-50%);
  //   width: 10px;
  //   height: 10px;
  //   background-color: #f00;
  //   border-radius: 50%;
  //   animation: cir 2s linear infinite;
  // }

  // @keyframes cir {
  //   0% {
  //     transform: translate(0px,0px) rotate(0deg);
  //   }
  //   50% {
  //     transform: translate(50px,50px) rotate(90deg);
  //   }
  //   100% {
  //     transform: translate(0px, 100px) rotate(180deg);
  //   }
  // }
}

/* 在中屏幕上的样式 */
@media (min-width: 768px) and (max-width: 1023px) {
  /* 添加适合中屏幕的样式 */
  /* 添加适合大屏幕的样式 */
  @keyframes moveX {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-1104px);
    }
  }
  @keyframes the-moveX {
    0% {
      transform: translateX(-1104px);
    }
    100% {
      transform: translateX(0);
    }
  }
  .block {
    width: 100%;
    height: 100%;
    // 首页背景图
    .home-bg {
      position: relative;
      width: 100%;
      height: 620px;
      background: url("../assets/image/Slice 80.png");
      background-repeat: no-repeat;
      background-size: cover;
      .bg-info {
        position: absolute;
        width: 1200px;
        left: 50%;
        top: 175px;
        transform: translate(-50%, 0);
        h2 {
          color: #fff;
          font-family: "boldfont";
          font-weight: 400;
          font-size: 40px;
          margin: 0;
        }
        p {
          margin: 0;
          margin-top: 18px;
          margin-bottom: 111px;
          color: #fff;
          font-size: 20px;
          font-family: "refont";
        }
        .el-button {
          background: url("../assets/image/Slice 85.png");
          background-repeat: no-repeat;
          background-size: cover;
          color: #fff;
          border: none;
        }
      }
      // 浮动块父盒子
      .floatInfo {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
        width: 1200px;
        height: 85px;
        display: flex;
        justify-content: space-between;
        position: absolute;
        z-index: 1;
        // 浮动块展示信息
        .floatInfo-item {
          position: relative;
          width: 285px;
          height: 85px;
          background: linear-gradient(
              2deg,
              rgba(213, 232, 255, 0) 0%,
              #d5e8ff 100%
            ),
            rgba(255, 255, 255, 0.8);
          box-shadow: 0px 12px 36px 0px #e0e4ee;
          border-radius: 8px;
          border: 1px solid transparent;
          border-image: #fff;
          border-image-slice: 1; /* 控制边框图像的填充方式 */
          /* 毛玻璃效果 */
          backdrop-filter: blur(12px); /* 标准语法 */
          -webkit-backdrop-filter: blur(6px); /* WebKit 浏览器的前缀 */
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 48px;
            height: 48px;
            margin-right: 12px;
            // position: absolute;
            // left: 25px;
            // top: 16px;
          }
          span {
            // position: absolute;
            // left: 85px;
            // top: 30px;
            display: inline-block;
            font-size: 16px;
            color: #202124;
            font-family: "boldfont";
          }
        }
      }
    }
    .one-title {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 316px;
      width: 650px;
      height: 306px;
      z-index: 1;
      text-align: center;
      color: #fff;
    }
    .two-title {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 132px;
      // width: ;
      height: 306px;
      z-index: 1;
      text-align: center;
      color: #fff;
    }
    .three-title {
      position: absolute;
      left: 50%;
      top: 203px;
      transform: translateX(-50%);
      width: 1200px;
      z-index: 1;
      box-sizing: border-box;
      color: #fff;
      font-size: 48px;
      line-height: 56px;
      letter-spacing: 6px;
    }
    .one-icon {
      width: 60px;
      height: 60px;
      background: url("../assets/image/microphone-icn.png");
      background-repeat: no-repeat;
      background-size: cover;
      margin: auto;
    }
    > img {
      position: relative;
      width: 100%;
      height: 100%;
      // height: 100%;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      //   height: 100%;
      // object-fit: contain;
      position: absolute;
    }
  }
  // 技术展示图区域
  .technology-demonstration {
    width: 100%;
    height: 900px;
    background: #f3f8ff;
  }
  // 隐算产品全景图
  .product-panorama {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 1479px;
    background: #fafcff;
    background: #f3f8ff;
    padding-top: 100px;
    .product-panorama-box {
      position: relative;
      width: 1200px;
      // background: #59ebff;
      h2 {
        font-family: "boldfont";
        text-align: center;
        font-size: 36px;
        margin: 60px 0 0;
        strong {
          color: #0066d4;
        }
      }
      .text-with-gradient {
        background: linear-gradient(
          180deg,
          rgba(53, 101, 252, 0) 0%,
          #3665fe 100%
        );
        -webkit-background-clip: text; /* Safari/Chrome */
        -webkit-text-fill-color: transparent; /* Safari/Chrome */
        color: transparent;
        font-size: 64px;
      }
      .two-title {
        font-size: 24px;
        color: rgba(0, 0, 0, 0.85);
        // margin-left: 20px;
      }
      // 产品示例
      .shili {
        ul {
          overflow: hidden;
          margin: 0;
          padding: 0;
          margin-top: 16px;
          li {
            float: left;
            position: relative;
            margin: 20px 45px 37px 0;
            width: 370px;
            height: 144px;
            background: #ffffff;
            box-shadow: 0px 0px 23px 0px #e0e4ee;
            border-radius: 12px 12px 12px 12px;
            strong {
              font-family: "boldfont";
              position: absolute;
              left: 20px;
              top: 31px;
              font-size: 16px;
              font-size: 16px;
              color: #202124;
            }
            i {
              position: absolute;
              left: 20px;
              top: 67px;
              display: inline-block;
              width: 193px;
              border: none;
              // border-bottom: 1px solid #a9a9a9;
            }
            p {
              font-family: "refont";
              position: absolute;
              left: 20px;
              color: #666666;
              top: 76px;
              margin: 0;
              font-size: 12px;
              width: 330px;
              height: 48px;
            }
            img {
              width: 114px;
              height: 114px;
              position: absolute;
              right: 28px;
              top: -28px;
            }
          }
          li:nth-child(3n) {
            margin-right: 0;
          }
          li:last-child {
            width: 183px;
            height: 144px;
            background: #ffffff !important;
            box-shadow: 0px 0px 23px 0px rgba(57, 92, 145, 0.25);
            border-radius: 12px 12px 12px 12px;
            text-align: center;
            color: rgba(0, 0, 0, 0.85);
            line-height: 144px;
            font-size: 20px;
            color: #999b9f !important;
            font-family: "myfont";
            cursor: initial !important;
          }
          li:hover {
            cursor: pointer;
            background: #3565fc;
            color: #fff;
            strong {
              color: #fff;
            }
            i {
              border: 1px solid #fafcff;
            }
            p {
              color: #fff;
            }
          }
          li:last-child:hover {
            width: 183px;
            height: 144px;
            background: #ffffff;
            box-shadow: 0px 0px 23px 0px rgba(57, 92, 145, 0.25);
            border-radius: 12px 12px 12px 12px;
            text-align: center;
            color: rgba(0, 0, 0, 0.85);
            line-height: 144px;
            font-size: 20px;
            color: #999b9f;
            font-family: "myfont";
          }
        }
      }
      .shili1 {
        ul {
          overflow: hidden;
          margin: 0;
          padding: 0;
          margin-top: 16px;
          li {
            float: left;
            position: relative;
            margin: 20px 45px 37px 0;
            width: 370px;
            height: 144px;
            background: #ffffff;
            box-shadow: 0px 0px 23px 0px #e0e4ee;
            border-radius: 12px 12px 12px 12px;
            strong {
              font-family: "boldfont";
              position: absolute;
              left: 20px;
              top: 31px;
              font-size: 16px;
              font-size: 16px;
              color: #202124;
            }
            i {
              position: absolute;
              left: 20px;
              top: 67px;
              display: inline-block;
              width: 193px;
              border: none;
              // border-bottom: 1px solid #a9a9a9;
            }
            p {
              font-family: "refont";
              position: absolute;
              left: 20px;
              color: #666666;
              top: 76px;
              margin: 0;
              font-size: 12px;
              width: 330px;
              height: 48px;
            }
            img {
              width: 114px;
              height: 114px;
              position: absolute;
              right: 28px;
              top: -28px;
            }
          }
          li:nth-child(3n) {
            margin-right: 0;
          }
          li:last-child {
            width: 183px;
            height: 144px;
            background: #ffffff !important;
            box-shadow: 0px 0px 23px 0px rgba(57, 92, 145, 0.25);
            border-radius: 12px 12px 12px 12px;
            text-align: center;
            color: rgba(0, 0, 0, 0.85);
            line-height: 144px;
            font-size: 20px;
            color: #999b9f !important;
            font-family: "myfont";
            cursor: initial !important;
          }
          li:hover {
            cursor: pointer;
            background: #3565fc;
            color: #fff;
            strong {
              color: #fff;
            }
            i {
              border: 1px solid #fafcff;
            }
            p {
              color: #fff;
            }
          }
          li:last-child:hover {
            width: 183px;
            height: 144px;
            background: #ffffff;
            box-shadow: 0px 0px 23px 0px rgba(57, 92, 145, 0.25);
            border-radius: 12px 12px 12px 12px;
            text-align: center;
            color: rgba(0, 0, 0, 0.85);
            line-height: 144px;
            font-size: 20px;
            color: #999b9f;
            font-family: "myfont";
          }
        }
      }
      // 全景图定位盒子

      .acquiesce {
        cursor: pointer;
        width: 370px;
        height: 144px;
        background: #ffffff;
        box-shadow: 0px 0px 23px 0px #e0e4ee;
        border-radius: 12px 12px 12px 12px;
        strong {
          font-family: "refont";
          position: absolute;
          left: 20px;
          top: 36px;
          font-weight: bold;
          font-size: 16px;
          color: rgba(0, 0, 0, 0.85);
        }
        i {
          position: absolute;
          left: 20px;
          top: 67px;
          display: inline-block;
          width: 193px;
          border: none;
          // border-bottom: 1px solid #a9a9a9;
        }
        p {
          font-family: "refont";
          position: absolute;
          left: 20px;
          color: #666666;
          top: 76px;
          margin: 0;
          font-size: 12px;
          width: 330px;
          height: 48px;
        }
        img {
          width: 114px;
          position: absolute;
          right: 28px;
          top: -32px;
        }
      }
      .acquiesce1 {
        width: 370px;
        height: 144px;
        background: #ffffff;
        box-shadow: 0px 0px 23px 0px #e0e4ee;
        border-radius: 12px 12px 12px 12px;
        strong {
          font-family: "refont";
          position: absolute;
          left: 20px;
          top: 36px;
          font-weight: bold;
          font-size: 16px;
          color: rgba(0, 0, 0, 0.85);
        }
        i {
          position: absolute;
          left: 20px;
          top: 67px;
          display: inline-block;
          width: 193px;
          border: none;
          // border-bottom: 1px solid #a9a9a9;
        }
        p {
          font-family: "refont";
          position: absolute;
          left: 20px;
          color: #666666;
          top: 76px;
          margin: 0;
          font-size: 12px;
          width: 330px;
          height: 48px;
        }
        img {
          width: 114px;
          position: absolute;
          right: 28px;
          top: -32px;
        }
      }
      .one {
        position: absolute;
        left: 0;
        top: 215px;
      }
      .two {
        position: absolute;
        left: 428px;
        top: 215px;
      }
      .three {
        position: absolute;
        left: 856px;
        width: 344px;
        top: 215px;
        i {
          width: 169px;
        }
        p {
          width: 304px;
        }
      }
      .four {
        position: absolute;
        left: 593px;
        width: 415px;
        top: 411px;
        i {
          width: 239px;
        }
        p {
          width: 375px;
        }
      }
      .five {
        position: absolute;
        left: 161px;
        width: 404px;
        top: 411px;
        i {
          width: 225px;
        }
        p {
          width: 364px;
        }
      }
      .six {
        position: absolute;
        left: 27px;
        width: 414px;
        top: 607px;
        i {
          width: 238px;
        }
        p {
          width: 374px;
        }
      }
      .seven {
        position: absolute;
        left: 498px;
        width: 392px;
        top: 607px;
        i {
          width: 215px;
        }
        p {
          width: 352px;
        }
      }
      .more {
        position: absolute;
        left: 946px;
        width: 183px;
        top: 607px;
        text-align: center;
        color: rgba(0, 0, 0, 0.85);
        line-height: 144px;
        font-size: 20px;
        color: #999b9f;
        font-family: "myfont";
      }
      .acquiesce:hover {
        background: #3565fc !important;
        color: #fff !important;
        strong {
          color: #fff;
        }
        i {
          border: 1px solid #fafcff;
        }
        p {
          color: #fff;
        }
      }
      // 虚线
      .line1 {
        // margin: 80px 130px 0 100px;
        margin-top: 72px;
        width: 955px;
        height: 1px;
        border: none;
        margin-left: 114px;
        border-bottom: 1px dashed rgba(53, 101, 252, 0.6);
      }
      .line1-two {
        // margin: 80px 130px 0 100px;
        margin-top: 72px;
        width: 912px;
        height: 1px;
        border: none;
        margin-left: 114px;
        border-bottom: 1px dashed rgba(53, 101, 252, 0.6);
      }
      .line2 {
        width: 200px;
        height: 198px;
        border: none;
        margin-left: 1070px;
        border-left: 1px dashed rgba(53, 101, 252, 0.6); /* 虚线的样式和颜色 */
      }
      .line2-two {
        width: 200px;
        height: 198px;
        border: none;
        margin-left: 1027px;
        border-left: 1px dashed rgba(53, 101, 252, 0.6); /* 虚线的样式和颜色 */
      }
      .line3 {
        width: 955px;
        height: 1px;
        border: none;
        margin-left: 114px;
        border-bottom: 1px dashed rgba(53, 101, 252, 0.6); /* 虚线的样式和颜色 */
      }
      .line4 {
        width: 955px;
        height: 197px;
        border: none;
        margin-left: 114px;
        border-left: 1px dashed rgba(53, 101, 252, 0.6); /* 虚线的样式和颜色 */
      }
      .line5 {
        width: 955px;
        height: 1px;
        border: none;
        margin-left: 114px;
        border-bottom: 1px dashed rgba(53, 101, 252, 0.6); /* 虚线的样式和颜色 */
      }
      // 专项
      .zhuangxiang {
        margin-top: 32px;
      }
      .eight {
        position: absolute;
        left: 0px;
        width: 432px;
        top: 882px;
        i {
          width: 254.69px;
        }
        p {
          width: 392px;
        }
      }
      .nine {
        position: absolute;
        left: 490px;
        width: 431px;
        top: 882px;
        i {
          width: 255px;
        }
        p {
          width: 391px;
        }
      }
      .ten {
        position: absolute;
        left: 663px;
        width: 435px;
        top: 1076px;
        i {
          width: 254.69px;
        }
        p {
          width: 395px;
        }
      }
      .eleven {
        position: absolute;
        left: 173px;
        width: 435px;
        top: 1076px;
        i {
          width: 254.69px;
        }
        p {
          width: 395px;
        }
      }
      .twelve {
        position: absolute;
        left: -21px;
        width: 491px;
        top: 1271px;
        i {
          width: 314px;
        }
        p {
          width: 451px;
        }
      }
      .thirteen {
        position: absolute;
        left: 536px;
        width: 434px;
        top: 1271px;
        i {
          width: 254px;
        }
        p {
          width: 394px;
        }
      }
      .more1 {
        position: absolute;
        left: 1018px;
        width: 183px;
        top: 1271px;
        text-align: center;
        color: rgba(0, 0, 0, 0.85);
        line-height: 144px;
        font-size: 20px;
        color: #999b9f;
        font-family: "myfont";
      }
      // -webkit-.title1 {
      //   /* 设置文字颜色为透明 */
      //   color: transparent;

      //   /* 设置背景图片 */
      //   background-image: linear-gradient(
      //     89.99999999999993deg,
      //     rgba(53, 101, 252, 0) 22%,
      //     #3565fc 100%
      //   );

      //   /* 设置背景裁剪方式为文字 */
      //   -webkit-background-clip: text;
      //   background-clip: text;

      //   /* 设置文字填充色，也就是背景渐变的终点颜色 */
      //   text-fill-color: #3565fc;
      // }
    }
  }
  // 新闻动态
  .company-dynamics {
    position: relative;
    background: url("../assets/image/Slice 81.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 652px;
    h2 {
      font-size: 36px;
      font-family: "boldfont";
      text-align: center;
      margin: 72px 0 54px 0;
      span {
        color: #0066d4;
      }
    }
    .news {
      display: flex;
      justify-content: space-between;
      width: 1200px;
      height: 400px;
      margin: 0 auto;
      .news-text {
        width: 397px;
        height: 100%;
        // background: red;
        ul {
          margin: 0;
          padding: 0;
          margin-top: 16px;
          li {
            width: 397px;
            height: 64px;
            display: flex;
            align-items: center;
            margin-bottom: 18px;
            .default {
              font-family: "refont";
              position: relative;
              list-style: none;
              height: 48px;
              width: 397px;
              line-height: 48px;
              padding: 0 0px 0 20px;
              background: rgba(255, 255, 255, 0.1);
              box-shadow: 0px 4px 4px 0px rgba(100, 107, 146, 0.3);
              border-radius: 12px 12px 12px 12px;
              border: 1px solid #ffffff;
              margin-bottom: 12px;
              box-sizing: border-box;
              font-size: 16px;
              img {
                display: none;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
              }
              .news-contant {
                width: 269px;
                display: -webkit-box;
                -webkit-line-clamp: 1; /* 控制显示行数 */
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                float: right;
                margin-right: 50px;
              }
            }
          }
          li:hover {
            .default {
              height: 64px;
              line-height: 64px;
              padding-left: 76px;
              background: rgba(0, 59, 255, 0.74);
              transition: height 0.3s ease; /* 过渡动画属性 */
              color: #fff;
              img {
                display: block;
              }
              .news-contant {
                width: 269px;
                display: -webkit-box;
                -webkit-line-clamp: 1; /* 控制显示行数 */
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                float: right;
                margin-right: 30px;
              }
              .time-gang {
                position: absolute;
                left: 43px;
                font-size: 32px;
                font-weight: 100;
                transform: rotate(20deg);
                transition: font-size 0.3s ease; /* 过渡动画属性 */
                transition: left 0.3s ease; /* 过渡动画属性 */
                transition: transform 0.3s ease; /* 过渡动画属性 */
              }
              .time-top {
                font-size: 20px;
                display: inline-block;
                position: absolute;
                left: 28px;
                top: -8px;
                transition: font-size 0.3s ease; /* 过渡动画属性 */
                transition: left 0.3s ease; /* 过渡动画属性 */
                // transition: transform 0.3s ease; /* 过渡动画属性 */
              }
              .time-bottom {
                font-size: 20px;
                display: inline-block;
                position: absolute;
                left: 54px;
                top: 8px;
                transition: font-size 0.3s ease; /* 过渡动画属性 */
                transition: left 0.3s ease; /* 过渡动画属性 */
                // transition: transform 0.3s ease; /* 过渡动画属性 */
              }
            }
          }
          .news-active {
            .default {
              height: 64px;
              line-height: 64px;
              padding-left: 76px;
              background: rgba(0, 59, 255, 0.74);
              transition: height 0.3s ease; /* 过渡动画属性 */
              color: #fff;
              img {
                display: block;
              }
              .news-contant {
                width: 269px;
                display: -webkit-box;
                -webkit-line-clamp: 1; /* 控制显示行数 */
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                float: right;
                margin-right: 30px;
              }
              .time-gang {
                position: absolute;
                left: 43px;
                font-size: 32px;
                font-weight: 100;
                transform: rotate(20deg);
                transition: font-size 0.3s ease; /* 过渡动画属性 */
                transition: left 0.3s ease; /* 过渡动画属性 */
                transition: transform 0.3s ease; /* 过渡动画属性 */
              }
              .time-top {
                font-size: 20px;
                display: inline-block;
                position: absolute;
                left: 28px;
                top: -8px;
                transition: font-size 0.3s ease; /* 过渡动画属性 */
                transition: left 0.3s ease; /* 过渡动画属性 */
                // transition: transform 0.3s ease; /* 过渡动画属性 */
              }
              .time-bottom {
                font-size: 20px;
                display: inline-block;
                position: absolute;
                left: 54px;
                top: 8px;
                transition: font-size 0.3s ease; /* 过渡动画属性 */
                transition: left 0.3s ease; /* 过渡动画属性 */
                // transition: transform 0.3s ease; /* 过渡动画属性 */
              }
            }
          }
        }
        .el-button {
          margin-top: 14px;
          width: 118px;
          height: 40px;
          background: linear-gradient(
            185deg,
            #00c2ff 0%,
            rgba(0, 59, 255, 0.8) 50%,
            #00c2ff 100%
          );
          border-radius: 8px; /* 或者使用具体的8px 8px 8px 8px，效果相同 */
          color: #fff;
          font-size: 14px;
          font-family: "myfont";
        }
      }
      .news-img {
        width: 717px;
        height: 100%;
        background: rgba(255, 255, 255, 0.3);
        border-radius: 12px 12px 12px 12px;
        border: 2px solid #ffffff;
        box-sizing: border-box;
        /* 毛玻璃效果 */
        backdrop-filter: blur(6px); /* 标准语法 */
        -webkit-backdrop-filter: blur(6px); /* WebKit 浏览器的前缀 */
        box-sizing: border-box;
        padding: 20px;
        box-sizing: border-box;
        img {
          width: 100%;
          height: 100%;
          border-radius: 12px 12px 12px 12px;
          border: 1px solid #ffffff;
        }
      }
    }
  }
  .partner {
    width: 100%;
    height: 420px;
    background: #f2f7ff;
    h2 {
      font-size: 36px;
      font-family: "boldfont";
      text-align: center;
      margin: 72px 0 54px 0;
      span {
        color: #0066d4;
      }
    }
    .partner-box {
      width: 1200px;
      margin: 0 auto;
      overflow: hidden;
      .partner-top {
        margin-bottom: 44px;
        ul {
          height: 64px;
          margin: 0;
          padding: 0;
          width: 2208px;
          // width: 1104px;
          animation: moveX 15s linear infinite; /* 设置动画属性 */
        }
      }

      ul {
        height: 64px;
        display: flex;
        li {
          list-style: none;
          display: inline-block;
          width: 160px;
          height: 64px;
          margin-right: 24px;
          background: #fff;
          border-radius: 15px 15px 15px 15px;
          position: relative;
          img {
            position: absolute;
            width: 160px;
            height: 64px;
            vertical-align: middle;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
      .partner-bottom ul {
        height: 64px;
        margin: 0;
        padding: 0;
        width: 2208px;
        // width: 1104px;
        animation: the-moveX 15s linear infinite; /* 设置动画属性 */
      }
      .partner-top {
      }
      .partner-bottom {
      }
    }
  }

  .dynamicState-btn:hover {
    // font-size: 18px !important;
    // transform: scale(1.03);
    // transition: transform 1s ease;
  }

  .dynamicState-box {
    position: relative;
    width: 1193px;
    display: flex;
    justify-content: space-between;
    .dynamicState-line {
      position: absolute;
      width: 1px;
      height: 300px;
      background: #1450bb;
      left: 50%;
      top: 62px;
    }
    //  动态左侧内容
    .dynamicState-left:hover {
      transform: scale(1.02);
      transition: transform 1s ease;
    }
    .dynamicState-left {
      color: #fff;
      box-sizing: border-box;
      padding: 24px;
      width: 551px;
      height: 424px;
      background: #1450bb;
      box-shadow: 0px 5px 5px 1px rgba(35, 44, 69, 0.2);
      border-radius: 12px 12px 12px 12px;
      .convention {
        width: 503px;
        height: 278px;
        // background: url("../assets/image/convention.jpg");
        background-repeat: no-repeat;
        background-size: cover;
      }
      p {
        // width: 2rem;
        display: -webkit-box;
        -webkit-line-clamp: 1; /* 控制显示行数 */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .convention-time {
        font-size: 14px;
        margin-top: 27px;
        width: 503px;
        display: flex;
        justify-content: space-between;
      }
    }
    // 动态右侧区域
    .dynamicState-right {
      width: 552px;
      height: 424px;
      ul {
        height: 424px;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 8px;
        li:hover {
          transform: scale(1.03);
          transition: transform 1s ease;
        }
        li {
          p {
            // width: 2rem;
            display: -webkit-box;
            -webkit-line-clamp: 1; /* 控制显示行数 */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .state {
            background: url("../assets/image/state1.png");
            background-repeat: no-repeat;
            background-size: cover;
          }
          .state2 {
            background: url("../assets/image/state2.png");
            background-repeat: no-repeat;
            background-size: cover;
          }
          .state3 {
            background: url("../assets/image/state3.png");
            background-repeat: no-repeat;
            background-size: cover;
          }
          p {
            font-size: 20px;
          }
          span {
            font-size: 14px;
          }
          padding: 8px 0;
          box-sizing: border-box;
          background: #ffffff;
          box-shadow: 0px 5px 5px 1px rgba(35, 44, 69, 0.2);
          border-radius: 12px 12px 12px 12px;
          display: flex;
          justify-content: space-around;
        }
      }
    }
  }
  // 公司信息
  .Company-info {
    width: 100%;
    height: 834px;
    background-color: #fff;
    margin-top: 242px;
    .origin {
      margin-right: 9px;
      display: inline-block;
      width: 12px;
      height: 12px;
      background: #1450bb;
      border-radius: 33px 33px 33px 33px;
    }
    .Company-box {
      width: 1193px;
      height: 480px;
      margin: 160px auto;
      display: flex;
      justify-content: space-between;
      p {
        font-family: myfont;
      }
    }
    .Company-img {
      width: 590px;
      height: 480px;
      background: url("../assets/image/show-Company.jpg");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  // 产品介绍
  .product-box {
    width: 100%;
    height: 840px;
    background: linear-gradient(135deg, #252d45 0%, #1e2843 100%);
    .product-content {
      box-sizing: border-box;
      width: 1200px;
      height: 607px;
      margin: 107px auto;
    }
    .product-left {
      float: left;
      margin-right: 36px;
      width: 488px;
      height: 456px;
      // background-color: yellow;
      ul {
        margin: 0;
        padding: 0;
        .old-li {
          font-size: 24px;
          width: 488px;
          height: 76px;
          color: #fff;
          line-height: 76px;
          // margin-bottom: 40px;
          background-color: transparent;
          transition: background-color 0.3s ease;
          .openBox1 {
            display: inline-block;
          }
          .openBox {
            display: none;
          }

          .triangle {
            display: inline-block;
            margin-right: 19px;
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 20px solid #59ebff; /* 可以将颜色修改为你想要的颜色 */
          }
        }
        .new-li {
          position: relative;
          width: 488px;
          font-size: 24px;
          height: 161px;
          color: #fff;
          line-height: 161px;
          background-color: #2f4165;
          border-radius: 12px 12px 12px 12px;
        }
        .triangleNew {
          border: 0;
          display: inline-block;
          width: 8px;
          vertical-align: middle;
          height: 100px;
          background-color: #59ebff;
          border-radius: 12px 12px 12px 12px;
        }
        .openBox {
          display: block;
          width: 408px;
          height: 102px;
          position: absolute;
          left: 40px;
          top: 50%;
          transform: translateY(-50%);
        }
        // li:hover .openBox1 {
        //   display: none;
        // }
        .line-top {
          width: 100%;
          display: flex;
          height: 48px;
          line-height: 48px;
          justify-content: space-between;
        }
        .line-bottom {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 100%;
          // display: flex;
          height: 24px;
          line-height: 24px;
          margin-top: 19px;
          // justify-content: space-between;
        }
        .line {
          line-height: 0;
          margin-top: 9px;
          // transform: translateY(-50%);
          width: 408px;
          height: 1px;
          background: rgba(255, 255, 255, 0.2);
        }
      }
    }
    .product-right {
      float: left;
      width: 674px;
      height: 456px;
      background: rgba(20, 80, 187, 0.2);
      border-radius: 16px 16px 16px 16px;
      border: 1px solid #3982f7;
      padding: 12px;
      box-sizing: border-box;
      .camera-home1 {
        width: 652px;
        height: 430px;
        border-radius: 16px 16px 16px 16px;
        background: url("../assets/image/pro/product-one.jpg");
        background-repeat: no-repeat;
        background-size: cover;
      }
      .camera-home2 {
        width: 652px;
        height: 430px;
        border-radius: 16px 16px 16px 16px;
        background: url("../assets/image/pro/product-two.jpg");
        background-repeat: no-repeat;
        background-size: cover;
      }
      .camera-home3 {
        width: 652px;
        height: 430px;
        border-radius: 16px 16px 16px 16px;
        background: url("../assets/image/pro/product-three.jpg");
        background-repeat: no-repeat;
        background-size: cover;
      }
      .camera-home4 {
        width: 652px;
        height: 430px;
        border-radius: 16px 16px 16px 16px;
        background: url("../assets/image/pro/product-four.jpg");
        background-repeat: no-repeat;
        background-size: cover;
      }
      .camera-home5 {
        width: 652px;
        height: 430px;
        border-radius: 16px 16px 16px 16px;
        background: url("../assets/image/pro/product-five.jpg");
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
  //合作伙伴
  .buddy-box {
    width: 100%;
    height: 553px;
    background-color: #fff;
    .buddy-content {
      box-sizing: border-box;
      width: 1200px;
      margin: 120px auto;
      .buddy-title {
        height: 60px;
        font-size: 40px;
        font-weight: bold;
        color: #000000;
        line-height: 47px;
        margin-bottom: 88px;
      }
      // 合作方
      .buddy-partner {
        overflow: hidden;
        width: 1200px;
        height: 201px;
        > div > div {
          display: flex;
          align-items: center;
          float: left;
          margin-right: 84px;
          width: 240px;
          height: 132px;
          line-height: 132px;
          padding: 26px 0;
          box-sizing: border-box;
          text-align: center;
        }
        // display: flex;
        // justify-content: space-between;
        .partner > div {
          width: 240px;
          height: 132px;
          display: flex;
          justify-content: center;
          align-items: center;
          float: left;
        }
      }
    }
  }
  // 轮播图箭头
  /deep/.el-carousel__arrow {
    width: 80px;
    height: 80px;
    font-size: 36px;
  }
  .zoom {
    animation: zoomAnimation 2s infinite;
  }

  @keyframes zoomAnimation {
    0%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.3);
    }
  }
  // .el-caro
  .dynamicState-btn {
    font-size: 20px;
    position: relative;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    opacity: 1;
    color: #1450bb;
    border: 1px solid #1450bb;
    box-sizing: border-box;
  }
  // .circle {
  //   position: absolute;
  //   top: 0;
  //   left: 50%;
  //   transform: translateX(-50%);
  //   width: 10px;
  //   height: 10px;
  //   background-color: #f00;
  //   border-radius: 50%;
  //   animation: cir 2s linear infinite;
  // }

  // @keyframes cir {
  //   0% {
  //     transform: translate(0px,0px) rotate(0deg);
  //   }
  //   50% {
  //     transform: translate(50px,50px) rotate(90deg);
  //   }
  //   100% {
  //     transform: translate(0px, 100px) rotate(180deg);
  //   }
  // }
}

/* 在大屏幕上的样式 */
@media (min-width: 1024px) {
  /* 添加适合大屏幕的样式 */
  @keyframes moveX {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-1104px);
    }
  }
  @keyframes the-moveX {
    0% {
      transform: translateX(-1104px);
    }
    100% {
      transform: translateX(0);
    }
  }
  .block {
    width: 100%;
    height: 100%;
    // 首页背景图
    .home-bg {
      position: relative;
      width: 100%;
      height: 620px;
      background: url("../assets/image/Slice 80.png");
      background-repeat: no-repeat;
      background-size: cover;
      .bg-info {
        position: absolute;
        width: 1200px;
        left: 50%;
        top: 175px;
        transform: translate(-50%, 0);
        h2 {
          color: #fff;
          font-family: "boldfont";
          font-weight: 400;
          font-size: 40px;
          margin: 0;
        }
        p {
          margin: 0;
          margin-top: 18px;
          margin-bottom: 111px;
          color: #fff;
          font-size: 20px;
          font-family: "refont";
        }
        .el-button {
          background: url("../assets/image/Slice 85.png");
          background-repeat: no-repeat;
          background-size: cover;
          color: #fff;
          border: none;
        }
      }
      // 浮动块父盒子
      .floatInfo {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
        width: 1200px;
        height: 85px;
        display: flex;
        justify-content: space-between;
        position: absolute;
        z-index: 1;
        // 浮动块展示信息
        .floatInfo-item {
          position: relative;
          width: 285px;
          height: 85px;
          background: linear-gradient(
              2deg,
              rgba(213, 232, 255, 0) 0%,
              #d5e8ff 100%
            ),
            rgba(255, 255, 255, 0.8);
          box-shadow: 0px 12px 36px 0px #e0e4ee;
          border-radius: 8px;
          border: 1px solid transparent;
          border-image: #fff;
          border-image-slice: 1; /* 控制边框图像的填充方式 */
          /* 毛玻璃效果 */
          backdrop-filter: blur(12px); /* 标准语法 */
          -webkit-backdrop-filter: blur(6px); /* WebKit 浏览器的前缀 */
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 48px;
            height: 48px;
            margin-right: 12px;
            // position: absolute;
            // left: 25px;
            // top: 16px;
          }
          span {
            // position: absolute;
            // left: 85px;
            // top: 30px;
            display: inline-block;
            font-size: 16px;
            color: #202124;
            font-family: "boldfont";
          }
        }
      }
    }
    .one-title {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 316px;
      width: 650px;
      height: 306px;
      z-index: 1;
      text-align: center;
      color: #fff;
    }
    .two-title {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 132px;
      // width: ;
      height: 306px;
      z-index: 1;
      text-align: center;
      color: #fff;
    }
    .three-title {
      position: absolute;
      left: 50%;
      top: 203px;
      transform: translateX(-50%);
      width: 1200px;
      z-index: 1;
      box-sizing: border-box;
      color: #fff;
      font-size: 48px;
      line-height: 56px;
      letter-spacing: 6px;
    }
    .one-icon {
      width: 60px;
      height: 60px;
      background: url("../assets/image/microphone-icn.png");
      background-repeat: no-repeat;
      background-size: cover;
      margin: auto;
    }
    > img {
      position: relative;
      width: 100%;
      height: 100%;
      // height: 100%;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      //   height: 100%;
      // object-fit: contain;
      position: absolute;
    }
  }
  // 技术展示图区域
  .technology-demonstration {
    width: 100%;
    height: 900px;
    background: #f3f8ff;
  }
  // 隐算产品全景图
  .product-panorama {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 1479px;
    background: #fafcff;
    background: #f3f8ff;
    padding-top: 100px;
    .product-panorama-box {
      position: relative;
      width: 1200px;
      // background: #59ebff;
      h2 {
        font-family: "boldfont";
        text-align: center;
        font-size: 36px;
        margin: 60px 0 0;
        strong {
          color: #0066d4;
        }
      }
      .text-with-gradient {
        background: linear-gradient(
          180deg,
          rgba(53, 101, 252, 0) 0%,
          #3665fe 100%
        );
        -webkit-background-clip: text; /* Safari/Chrome */
        -webkit-text-fill-color: transparent; /* Safari/Chrome */
        color: transparent;
        font-size: 64px;
      }
      .two-title {
        font-size: 24px;
        color: rgba(0, 0, 0, 0.85);
        // margin-left: 20px;
      }
      // 产品示例
      .shili {
        ul {
          overflow: hidden;
          margin: 0;
          padding: 0;
          margin-top: 16px;
          li {
            float: left;
            position: relative;
            margin: 20px 45px 37px 0;
            width: 370px;
            height: 144px;
            background: #ffffff;
            box-shadow: 0px 0px 23px 0px #e0e4ee;
            border-radius: 12px 12px 12px 12px;
            strong {
              font-family: "boldfont";
              position: absolute;
              left: 20px;
              top: 31px;
              font-size: 16px;
              font-size: 16px;
              color: #202124;
            }
            i {
              position: absolute;
              left: 20px;
              top: 67px;
              display: inline-block;
              width: 193px;
              border: none;
              // border-bottom: 1px solid #a9a9a9;
            }
            p {
              font-family: "refont";
              position: absolute;
              left: 20px;
              color: #666666;
              top: 76px;
              margin: 0;
              font-size: 12px;
              width: 330px;
              height: 48px;
            }
            img {
              width: 114px;
              height: 114px;
              position: absolute;
              right: 28px;
              top: -28px;
            }
          }
          li:nth-child(3n) {
            margin-right: 0;
          }
          li:last-child {
            width: 183px;
            height: 144px;
            background: #ffffff !important;
            box-shadow: 0px 0px 23px 0px rgba(57, 92, 145, 0.25);
            border-radius: 12px 12px 12px 12px;
            text-align: center;
            color: rgba(0, 0, 0, 0.85);
            line-height: 144px;
            font-size: 20px;
            color: #999b9f !important;
            font-family: "myfont";
            cursor: initial !important;
          }
          li:hover {
            cursor: pointer;
            background: #3565fc;
            color: #fff;
            strong {
              color: #fff;
            }
            i {
              border: 1px solid #fafcff;
            }
            p {
              color: #fff;
            }
          }
          li:last-child:hover {
            width: 183px;
            height: 144px;
            background: #ffffff;
            box-shadow: 0px 0px 23px 0px rgba(57, 92, 145, 0.25);
            border-radius: 12px 12px 12px 12px;
            text-align: center;
            color: rgba(0, 0, 0, 0.85);
            line-height: 144px;
            font-size: 20px;
            color: #999b9f;
            font-family: "myfont";
          }
        }
      }
      .shili1 {
        ul {
          overflow: hidden;
          margin: 0;
          padding: 0;
          margin-top: 16px;
          li {
            float: left;
            position: relative;
            margin: 20px 45px 37px 0;
            width: 370px;
            height: 144px;
            background: #ffffff;
            box-shadow: 0px 0px 23px 0px #e0e4ee;
            border-radius: 12px 12px 12px 12px;
            strong {
              font-family: "boldfont";
              position: absolute;
              left: 20px;
              top: 31px;
              font-size: 16px;
              font-size: 16px;
              color: #202124;
            }
            i {
              position: absolute;
              left: 20px;
              top: 67px;
              display: inline-block;
              width: 193px;
              border: none;
              // border-bottom: 1px solid #a9a9a9;
            }
            p {
              font-family: "refont";
              position: absolute;
              left: 20px;
              color: #666666;
              top: 76px;
              margin: 0;
              font-size: 12px;
              width: 330px;
              height: 48px;
            }
            img {
              width: 114px;
              height: 114px;
              position: absolute;
              right: 28px;
              top: -28px;
            }
          }
          li:nth-child(3n) {
            margin-right: 0;
          }
          li:last-child {
            width: 183px;
            height: 144px;
            background: #ffffff !important;
            box-shadow: 0px 0px 23px 0px rgba(57, 92, 145, 0.25);
            border-radius: 12px 12px 12px 12px;
            text-align: center;
            color: rgba(0, 0, 0, 0.85);
            line-height: 144px;
            font-size: 20px;
            color: #999b9f !important;
            font-family: "myfont";
            cursor: initial !important;
          }
          li:hover {
            cursor: pointer;
            background: #3565fc;
            color: #fff;
            strong {
              color: #fff;
            }
            i {
              border: 1px solid #fafcff;
            }
            p {
              color: #fff;
            }
          }
          li:last-child:hover {
            width: 183px;
            height: 144px;
            background: #ffffff;
            box-shadow: 0px 0px 23px 0px rgba(57, 92, 145, 0.25);
            border-radius: 12px 12px 12px 12px;
            text-align: center;
            color: rgba(0, 0, 0, 0.85);
            line-height: 144px;
            font-size: 20px;
            color: #999b9f;
            font-family: "myfont";
          }
        }
      }
      // 全景图定位盒子

      .acquiesce {
        cursor: pointer;
        width: 370px;
        height: 144px;
        background: #ffffff;
        box-shadow: 0px 0px 23px 0px #e0e4ee;
        border-radius: 12px 12px 12px 12px;
        strong {
          font-family: "refont";
          position: absolute;
          left: 20px;
          top: 36px;
          font-weight: bold;
          font-size: 16px;
          color: rgba(0, 0, 0, 0.85);
        }
        i {
          position: absolute;
          left: 20px;
          top: 67px;
          display: inline-block;
          width: 193px;
          border: none;
          // border-bottom: 1px solid #a9a9a9;
        }
        p {
          font-family: "refont";
          position: absolute;
          left: 20px;
          color: #666666;
          top: 76px;
          margin: 0;
          font-size: 12px;
          width: 330px;
          height: 48px;
        }
        img {
          width: 114px;
          position: absolute;
          right: 28px;
          top: -32px;
        }
      }
      .acquiesce1 {
        width: 370px;
        height: 144px;
        background: #ffffff;
        box-shadow: 0px 0px 23px 0px #e0e4ee;
        border-radius: 12px 12px 12px 12px;
        strong {
          font-family: "refont";
          position: absolute;
          left: 20px;
          top: 36px;
          font-weight: bold;
          font-size: 16px;
          color: rgba(0, 0, 0, 0.85);
        }
        i {
          position: absolute;
          left: 20px;
          top: 67px;
          display: inline-block;
          width: 193px;
          border: none;
          // border-bottom: 1px solid #a9a9a9;
        }
        p {
          font-family: "refont";
          position: absolute;
          left: 20px;
          color: #666666;
          top: 76px;
          margin: 0;
          font-size: 12px;
          width: 330px;
          height: 48px;
        }
        img {
          width: 114px;
          position: absolute;
          right: 28px;
          top: -32px;
        }
      }
      .one {
        position: absolute;
        left: 0;
        top: 215px;
      }
      .two {
        position: absolute;
        left: 428px;
        top: 215px;
      }
      .three {
        position: absolute;
        left: 856px;
        width: 344px;
        top: 215px;
        i {
          width: 169px;
        }
        p {
          width: 304px;
        }
      }
      .four {
        position: absolute;
        left: 593px;
        width: 415px;
        top: 411px;
        i {
          width: 239px;
        }
        p {
          width: 375px;
        }
      }
      .five {
        position: absolute;
        left: 161px;
        width: 404px;
        top: 411px;
        i {
          width: 225px;
        }
        p {
          width: 364px;
        }
      }
      .six {
        position: absolute;
        left: 27px;
        width: 414px;
        top: 607px;
        i {
          width: 238px;
        }
        p {
          width: 374px;
        }
      }
      .seven {
        position: absolute;
        left: 498px;
        width: 392px;
        top: 607px;
        i {
          width: 215px;
        }
        p {
          width: 352px;
        }
      }
      .more {
        position: absolute;
        left: 946px;
        width: 183px;
        top: 607px;
        text-align: center;
        color: rgba(0, 0, 0, 0.85);
        line-height: 144px;
        font-size: 20px;
        color: #999b9f;
        font-family: "myfont";
      }
      .acquiesce:hover {
        background: #3565fc !important;
        color: #fff !important;
        strong {
          color: #fff;
        }
        i {
          border: 1px solid #fafcff;
        }
        p {
          color: #fff;
        }
      }
      // 虚线
      .line1 {
        // margin: 80px 130px 0 100px;
        margin-top: 72px;
        width: 955px;
        height: 1px;
        border: none;
        margin-left: 114px;
        border-bottom: 1px dashed rgba(53, 101, 252, 0.6);
      }
      .line1-two {
        // margin: 80px 130px 0 100px;
        margin-top: 72px;
        width: 912px;
        height: 1px;
        border: none;
        margin-left: 114px;
        border-bottom: 1px dashed rgba(53, 101, 252, 0.6);
      }
      .line2 {
        width: 200px;
        height: 198px;
        border: none;
        margin-left: 1070px;
        border-left: 1px dashed rgba(53, 101, 252, 0.6); /* 虚线的样式和颜色 */
      }
      .line2-two {
        width: 200px;
        height: 198px;
        border: none;
        margin-left: 1027px;
        border-left: 1px dashed rgba(53, 101, 252, 0.6); /* 虚线的样式和颜色 */
      }
      .line3 {
        width: 955px;
        height: 1px;
        border: none;
        margin-left: 114px;
        border-bottom: 1px dashed rgba(53, 101, 252, 0.6); /* 虚线的样式和颜色 */
      }
      .line4 {
        width: 955px;
        height: 197px;
        border: none;
        margin-left: 114px;
        border-left: 1px dashed rgba(53, 101, 252, 0.6); /* 虚线的样式和颜色 */
      }
      .line5 {
        width: 955px;
        height: 1px;
        border: none;
        margin-left: 114px;
        border-bottom: 1px dashed rgba(53, 101, 252, 0.6); /* 虚线的样式和颜色 */
      }
      // 专项
      .zhuangxiang {
        margin-top: 32px;
      }
      .eight {
        position: absolute;
        left: 0px;
        width: 432px;
        top: 882px;
        i {
          width: 254.69px;
        }
        p {
          width: 392px;
        }
      }
      .nine {
        position: absolute;
        left: 490px;
        width: 431px;
        top: 882px;
        i {
          width: 255px;
        }
        p {
          width: 391px;
        }
      }
      .ten {
        position: absolute;
        left: 663px;
        width: 435px;
        top: 1076px;
        i {
          width: 254.69px;
        }
        p {
          width: 395px;
        }
      }
      .eleven {
        position: absolute;
        left: 173px;
        width: 435px;
        top: 1076px;
        i {
          width: 254.69px;
        }
        p {
          width: 395px;
        }
      }
      .twelve {
        position: absolute;
        left: -21px;
        width: 491px;
        top: 1271px;
        i {
          width: 314px;
        }
        p {
          width: 451px;
        }
      }
      .thirteen {
        position: absolute;
        left: 536px;
        width: 434px;
        top: 1271px;
        i {
          width: 254px;
        }
        p {
          width: 394px;
        }
      }
      .more1 {
        position: absolute;
        left: 1018px;
        width: 183px;
        top: 1271px;
        text-align: center;
        color: rgba(0, 0, 0, 0.85);
        line-height: 144px;
        font-size: 20px;
        color: #999b9f;
        font-family: "myfont";
      }
      // -webkit-.title1 {
      //   /* 设置文字颜色为透明 */
      //   color: transparent;

      //   /* 设置背景图片 */
      //   background-image: linear-gradient(
      //     89.99999999999993deg,
      //     rgba(53, 101, 252, 0) 22%,
      //     #3565fc 100%
      //   );

      //   /* 设置背景裁剪方式为文字 */
      //   -webkit-background-clip: text;
      //   background-clip: text;

      //   /* 设置文字填充色，也就是背景渐变的终点颜色 */
      //   text-fill-color: #3565fc;
      // }
    }
  }
  // 新闻动态
  .company-dynamics {
    position: relative;
    background: url("../assets/image/Slice 81.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 652px;
    h2 {
      font-size: 36px;
      font-family: "boldfont";
      text-align: center;
      margin: 72px 0 54px 0;
      span {
        color: #0066d4;
      }
    }
    .news {
      display: flex;
      justify-content: space-between;
      width: 1200px;
      height: 400px;
      margin: 0 auto;
      .news-text {
        width: 397px;
        height: 100%;
        // background: red;
        ul {
          margin: 0;
          padding: 0;
          margin-top: 16px;
          li {
            width: 397px;
            height: 64px;
            display: flex;
            align-items: center;
            margin-bottom: 18px;
            .default {
              font-family: "refont";
              position: relative;
              list-style: none;
              height: 48px;
              width: 397px;
              line-height: 48px;
              padding: 0 0px 0 20px;
              background: rgba(255, 255, 255, 0.1);
              box-shadow: 0px 4px 4px 0px rgba(100, 107, 146, 0.3);
              border-radius: 12px 12px 12px 12px;
              border: 1px solid #ffffff;
              margin-bottom: 12px;
              box-sizing: border-box;
              font-size: 16px;
              img {
                display: none;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
              }
              .news-contant {
                width: 269px;
                display: -webkit-box;
                -webkit-line-clamp: 1; /* 控制显示行数 */
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                float: right;
                margin-right: 50px;
              }
            }
          }
          li:hover {
            .default {
              height: 64px;
              line-height: 64px;
              padding-left: 76px;
              background: rgba(0, 59, 255, 0.74);
              transition: height 0.3s ease; /* 过渡动画属性 */
              color: #fff;
              img {
                display: block;
              }
              .news-contant {
                width: 269px;
                display: -webkit-box;
                -webkit-line-clamp: 1; /* 控制显示行数 */
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                float: right;
                margin-right: 30px;
              }
              .time-gang {
                position: absolute;
                left: 43px;
                font-size: 32px;
                font-weight: 100;
                transform: rotate(20deg);
                transition: font-size 0.3s ease; /* 过渡动画属性 */
                transition: left 0.3s ease; /* 过渡动画属性 */
                transition: transform 0.3s ease; /* 过渡动画属性 */
              }
              .time-top {
                font-size: 20px;
                display: inline-block;
                position: absolute;
                left: 28px;
                top: -8px;
                transition: font-size 0.3s ease; /* 过渡动画属性 */
                transition: left 0.3s ease; /* 过渡动画属性 */
                // transition: transform 0.3s ease; /* 过渡动画属性 */
              }
              .time-bottom {
                font-size: 20px;
                display: inline-block;
                position: absolute;
                left: 54px;
                top: 8px;
                transition: font-size 0.3s ease; /* 过渡动画属性 */
                transition: left 0.3s ease; /* 过渡动画属性 */
                // transition: transform 0.3s ease; /* 过渡动画属性 */
              }
            }
          }
          .news-active {
            .default {
              height: 64px;
              line-height: 64px;
              padding-left: 76px;
              background: rgba(0, 59, 255, 0.74);
              transition: height 0.3s ease; /* 过渡动画属性 */
              color: #fff;
              img {
                display: block;
              }
              .news-contant {
                width: 269px;
                display: -webkit-box;
                -webkit-line-clamp: 1; /* 控制显示行数 */
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                float: right;
                margin-right: 30px;
              }
              .time-gang {
                position: absolute;
                left: 43px;
                font-size: 32px;
                font-weight: 100;
                transform: rotate(20deg);
                transition: font-size 0.3s ease; /* 过渡动画属性 */
                transition: left 0.3s ease; /* 过渡动画属性 */
                transition: transform 0.3s ease; /* 过渡动画属性 */
              }
              .time-top {
                font-size: 20px;
                display: inline-block;
                position: absolute;
                left: 28px;
                top: -8px;
                transition: font-size 0.3s ease; /* 过渡动画属性 */
                transition: left 0.3s ease; /* 过渡动画属性 */
                // transition: transform 0.3s ease; /* 过渡动画属性 */
              }
              .time-bottom {
                font-size: 20px;
                display: inline-block;
                position: absolute;
                left: 54px;
                top: 8px;
                transition: font-size 0.3s ease; /* 过渡动画属性 */
                transition: left 0.3s ease; /* 过渡动画属性 */
                // transition: transform 0.3s ease; /* 过渡动画属性 */
              }
            }
          }
        }
        .el-button {
          margin-top: 14px;
          width: 118px;
          height: 40px;
          background: linear-gradient(
            185deg,
            #00c2ff 0%,
            rgba(0, 59, 255, 0.8) 50%,
            #00c2ff 100%
          );
          border-radius: 8px; /* 或者使用具体的8px 8px 8px 8px，效果相同 */
          color: #fff;
          font-size: 14px;
          font-family: "myfont";
        }
      }
      .news-img {
        width: 717px;
        height: 100%;
        background: rgba(255, 255, 255, 0.3);
        border-radius: 12px 12px 12px 12px;
        border: 2px solid #ffffff;
        box-sizing: border-box;
        /* 毛玻璃效果 */
        backdrop-filter: blur(6px); /* 标准语法 */
        -webkit-backdrop-filter: blur(6px); /* WebKit 浏览器的前缀 */
        box-sizing: border-box;
        padding: 20px;
        box-sizing: border-box;
        img {
          width: 100%;
          height: 100%;
          border-radius: 12px 12px 12px 12px;
          border: 1px solid #ffffff;
        }
      }
    }
  }
  .partner {
    width: 100%;
    height: 420px;
    background: #f2f7ff;
    h2 {
      font-size: 36px;
      font-family: "boldfont";
      text-align: center;
      margin: 72px 0 54px 0;
      span {
        color: #0066d4;
      }
    }
    .partner-box {
      width: 1200px;
      margin: 0 auto;
      overflow: hidden;
      .partner-top {
        margin-bottom: 44px;
        ul {
          height: 64px;
          margin: 0;
          padding: 0;
          width: 2208px;
          // width: 1104px;
          animation: moveX 15s linear infinite; /* 设置动画属性 */
        }
      }

      ul {
        height: 64px;
        display: flex;
        li {
          list-style: none;
          display: inline-block;
          width: 160px;
          height: 64px;
          margin-right: 24px;
          background: #fff;
          border-radius: 15px 15px 15px 15px;
          position: relative;
          img {
            position: absolute;
            width: 160px;
            height: 64px;
            vertical-align: middle;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
      .partner-bottom ul {
        height: 64px;
        margin: 0;
        padding: 0;
        width: 2208px;
        // width: 1104px;
        animation: the-moveX 15s linear infinite; /* 设置动画属性 */
      }
      .partner-top {
      }
      .partner-bottom {
      }
    }
  }

  .dynamicState-btn:hover {
    // font-size: 18px !important;
    // transform: scale(1.03);
    // transition: transform 1s ease;
  }

  .dynamicState-box {
    position: relative;
    width: 1193px;
    display: flex;
    justify-content: space-between;
    .dynamicState-line {
      position: absolute;
      width: 1px;
      height: 300px;
      background: #1450bb;
      left: 50%;
      top: 62px;
    }
    //  动态左侧内容
    .dynamicState-left:hover {
      transform: scale(1.02);
      transition: transform 1s ease;
    }
    .dynamicState-left {
      color: #fff;
      box-sizing: border-box;
      padding: 24px;
      width: 551px;
      height: 424px;
      background: #1450bb;
      box-shadow: 0px 5px 5px 1px rgba(35, 44, 69, 0.2);
      border-radius: 12px 12px 12px 12px;
      .convention {
        width: 503px;
        height: 278px;
        // background: url("../assets/image/convention.jpg");
        background-repeat: no-repeat;
        background-size: cover;
      }
      p {
        // width: 2rem;
        display: -webkit-box;
        -webkit-line-clamp: 1; /* 控制显示行数 */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .convention-time {
        font-size: 14px;
        margin-top: 27px;
        width: 503px;
        display: flex;
        justify-content: space-between;
      }
    }
    // 动态右侧区域
    .dynamicState-right {
      width: 552px;
      height: 424px;
      ul {
        height: 424px;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 8px;
        li:hover {
          transform: scale(1.03);
          transition: transform 1s ease;
        }
        li {
          p {
            // width: 2rem;
            display: -webkit-box;
            -webkit-line-clamp: 1; /* 控制显示行数 */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .state {
            background: url("../assets/image/state1.png");
            background-repeat: no-repeat;
            background-size: cover;
          }
          .state2 {
            background: url("../assets/image/state2.png");
            background-repeat: no-repeat;
            background-size: cover;
          }
          .state3 {
            background: url("../assets/image/state3.png");
            background-repeat: no-repeat;
            background-size: cover;
          }
          p {
            font-size: 20px;
          }
          span {
            font-size: 14px;
          }
          padding: 8px 0;
          box-sizing: border-box;
          background: #ffffff;
          box-shadow: 0px 5px 5px 1px rgba(35, 44, 69, 0.2);
          border-radius: 12px 12px 12px 12px;
          display: flex;
          justify-content: space-around;
        }
      }
    }
  }
  // 公司信息
  .Company-info {
    width: 100%;
    height: 834px;
    background-color: #fff;
    margin-top: 242px;
    .origin {
      margin-right: 9px;
      display: inline-block;
      width: 12px;
      height: 12px;
      background: #1450bb;
      border-radius: 33px 33px 33px 33px;
    }
    .Company-box {
      width: 1193px;
      height: 480px;
      margin: 160px auto;
      display: flex;
      justify-content: space-between;
      p {
        font-family: myfont;
      }
    }
    .Company-img {
      width: 590px;
      height: 480px;
      background: url("../assets/image/show-Company.jpg");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  // 产品介绍
  .product-box {
    width: 100%;
    height: 840px;
    background: linear-gradient(135deg, #252d45 0%, #1e2843 100%);
    .product-content {
      box-sizing: border-box;
      width: 1200px;
      height: 607px;
      margin: 107px auto;
    }
    .product-left {
      float: left;
      margin-right: 36px;
      width: 488px;
      height: 456px;
      // background-color: yellow;
      ul {
        margin: 0;
        padding: 0;
        .old-li {
          font-size: 24px;
          width: 488px;
          height: 76px;
          color: #fff;
          line-height: 76px;
          // margin-bottom: 40px;
          background-color: transparent;
          transition: background-color 0.3s ease;
          .openBox1 {
            display: inline-block;
          }
          .openBox {
            display: none;
          }

          .triangle {
            display: inline-block;
            margin-right: 19px;
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 20px solid #59ebff; /* 可以将颜色修改为你想要的颜色 */
          }
        }
        .new-li {
          position: relative;
          width: 488px;
          font-size: 24px;
          height: 161px;
          color: #fff;
          line-height: 161px;
          background-color: #2f4165;
          border-radius: 12px 12px 12px 12px;
        }
        .triangleNew {
          border: 0;
          display: inline-block;
          width: 8px;
          vertical-align: middle;
          height: 100px;
          background-color: #59ebff;
          border-radius: 12px 12px 12px 12px;
        }
        .openBox {
          display: block;
          width: 408px;
          height: 102px;
          position: absolute;
          left: 40px;
          top: 50%;
          transform: translateY(-50%);
        }
        // li:hover .openBox1 {
        //   display: none;
        // }
        .line-top {
          width: 100%;
          display: flex;
          height: 48px;
          line-height: 48px;
          justify-content: space-between;
        }
        .line-bottom {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 100%;
          // display: flex;
          height: 24px;
          line-height: 24px;
          margin-top: 19px;
          // justify-content: space-between;
        }
        .line {
          line-height: 0;
          margin-top: 9px;
          // transform: translateY(-50%);
          width: 408px;
          height: 1px;
          background: rgba(255, 255, 255, 0.2);
        }
      }
    }
    .product-right {
      float: left;
      width: 674px;
      height: 456px;
      background: rgba(20, 80, 187, 0.2);
      border-radius: 16px 16px 16px 16px;
      border: 1px solid #3982f7;
      padding: 12px;
      box-sizing: border-box;
      .camera-home1 {
        width: 652px;
        height: 430px;
        border-radius: 16px 16px 16px 16px;
        background: url("../assets/image/pro/product-one.jpg");
        background-repeat: no-repeat;
        background-size: cover;
      }
      .camera-home2 {
        width: 652px;
        height: 430px;
        border-radius: 16px 16px 16px 16px;
        background: url("../assets/image/pro/product-two.jpg");
        background-repeat: no-repeat;
        background-size: cover;
      }
      .camera-home3 {
        width: 652px;
        height: 430px;
        border-radius: 16px 16px 16px 16px;
        background: url("../assets/image/pro/product-three.jpg");
        background-repeat: no-repeat;
        background-size: cover;
      }
      .camera-home4 {
        width: 652px;
        height: 430px;
        border-radius: 16px 16px 16px 16px;
        background: url("../assets/image/pro/product-four.jpg");
        background-repeat: no-repeat;
        background-size: cover;
      }
      .camera-home5 {
        width: 652px;
        height: 430px;
        border-radius: 16px 16px 16px 16px;
        background: url("../assets/image/pro/product-five.jpg");
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
  //合作伙伴
  .buddy-box {
    width: 100%;
    height: 553px;
    background-color: #fff;
    .buddy-content {
      box-sizing: border-box;
      width: 1200px;
      margin: 120px auto;
      .buddy-title {
        height: 60px;
        font-size: 40px;
        font-weight: bold;
        color: #000000;
        line-height: 47px;
        margin-bottom: 88px;
      }
      // 合作方
      .buddy-partner {
        overflow: hidden;
        width: 1200px;
        height: 201px;
        > div > div {
          display: flex;
          align-items: center;
          float: left;
          margin-right: 84px;
          width: 240px;
          height: 132px;
          line-height: 132px;
          padding: 26px 0;
          box-sizing: border-box;
          text-align: center;
        }
        // display: flex;
        // justify-content: space-between;
        .partner > div {
          width: 240px;
          height: 132px;
          display: flex;
          justify-content: center;
          align-items: center;
          float: left;
        }
      }
    }
  }
  // 轮播图箭头
  /deep/.el-carousel__arrow {
    width: 80px;
    height: 80px;
    font-size: 36px;
  }
  .zoom {
    animation: zoomAnimation 2s infinite;
  }

  @keyframes zoomAnimation {
    0%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.3);
    }
  }
  // .el-caro
  .dynamicState-btn {
    font-size: 20px;
    position: relative;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    opacity: 1;
    color: #1450bb;
    border: 1px solid #1450bb;
    box-sizing: border-box;
  }
  // .circle {
  //   position: absolute;
  //   top: 0;
  //   left: 50%;
  //   transform: translateX(-50%);
  //   width: 10px;
  //   height: 10px;
  //   background-color: #f00;
  //   border-radius: 50%;
  //   animation: cir 2s linear infinite;
  // }

  // @keyframes cir {
  //   0% {
  //     transform: translate(0px,0px) rotate(0deg);
  //   }
  //   50% {
  //     transform: translate(50px,50px) rotate(90deg);
  //   }
  //   100% {
  //     transform: translate(0px, 100px) rotate(180deg);
  //   }
  // }
}
</style>