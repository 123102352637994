<template>
  <div
    style="
      width: 100%;
      background-color: #eef3fd;
      display: flex;
      flex-direction: column;
      align-items: center;
    "
  >
    <!-- 加入我们背景图 -->
    <div class="joinus-bg">
      <div class="joinus-fontBox">
        <div class="joinus-font">加入我们</div>
      </div>
    </div>
    <!-- 职位要求 -->
    <div class="job-box">
      <ul>
        <li v-for="item in acquirerecruitList" :key="item.recruitId">
          <div
            style="
              display: flex;
              justify-content: space-between;
              font-size: .1333rem;
            "
          >
            <div style="font-weight: bold; font-family: 'boldfont'">
              {{ item.jobName }}
            </div>
            <span
              style="color: rgba(20, 80, 187, 1); font-family: 'boldfont'"
              >{{ item.jobSalary }}</span
            >
          </div>
          <div style="font-size: .0583rem; margin-top: .0333rem; margin-bottom: .0958rem">
            <span style="margin-right: .0875rem">{{ item.jobYear }}</span
            ><span>{{ item.education }}</span>
          </div>
          <p
            style="
              color: rgba(20, 80, 187, 1);
              margin-bottom: .0333rem;
              font-family: 'boldfont';
            "
          >
            职位描述
          </p>
          <p style="margin-bottom: .1167rem">
            {{ item.jobDescription }}
            <!-- <span
              style="display: inline-block; margin-right: .0833rem"
              v-for="newitem in item.positionDescription"
              :key="newitem"
              >{{ newitem }}</span
            > -->
          </p>
          <p
            style="
              color: rgba(20, 80, 187, 1);
              margin-bottom: .0333rem;
              font-family: 'boldfont';
            "
          >
            岗位职责
          </p>
          <p style="white-space: pre-line;" >
            {{ item.jobResponsibility }}
            <!-- <span v-for="dutyItem in item.positionDuty" :key="dutyItem"
              >{{ dutyItem }}<br
            /></span> -->
          </p>
          <!-- <p
            v-if="item.jobRequirements.length != 0"
            style="
              color: rgba(20, 80, 187, 1);
              margin-top: .1167rem;
              font-family: 'boldfont';
            "
          > -->
          <p v-if="item.jobRequirement != null && item.jobRequirement != ''"         style="
              color: rgba(20, 80, 187, 1);
              margin-top: .1167rem;
              font-family: 'boldfont';
            ">
            任职要求
          </p>
          <p style="white-space: pre-line;">
            {{ item.jobRequirement }}
            <!-- <span v-for="jobItem in item.jobRequirements" :key="jobItem"
              >{{ jobItem }}<br
            /></span> -->
          </p>
        </li>
      </ul>
    </div>
    <!-- 公司福利以及相关信息 -->
    <div class="understand">
      <ul>
        <li v-for="(item, index) in understandData" :key="index">
          <!-- :src='"../assets/image/joinus/companyi-icon" + (index + 1)  + ".png"' -->
          <div class="understand-item">
            <img
              style="width: .7083rem; height: .7083rem"
              :src='"../assets/image/joinus/companyi-icon" + (index + 1)  + ".png"'
              alt=""
            />
            <p style="text-align: center; font-size: .1rem; color: #1450bb">
              {{ item.itemname }}
            </p>
            <p style="font-size: .0667rem; text-align: center">
              {{ item.itemcontent }}
            </p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
          
    <script>
    import {acquirerecruit} from '../api/recruit'
export default {
  data() {
    return {
      acquirerecruitList:[], // 招聘信息列表数据
      // 职位数据
      positionData: [
        {
          positionName: "rfid工程师",
          positionPay: "10-15k",
          educationalBackground: "大专",
          agelimit: "3-5年",
          positionDescription: [
            "c",
            "c++",
            "Python",
            "计算机相关专业",
            "通信相关专业",
            "嵌入式/单片机开发经验",
          ],
          positionDuty: [
            "1、根据客户需求，撰写相应解决方案，负责标签、读写器选型及测试验证",
            "2、确定HF、UHF标签规格，制定RFID标签编码规则。",
            "3、参与及组织相关人员进行RFID系统读写器及相关设备编程，完成集群搭建及系统部署。",
            "4、参与及组织相关人员完成RFID相关设备客户端后端开发工作。",
          ],
          jobRequirements: [
            "1、年龄35周岁以下，通讯、电子、自动化、电磁场等相关专业，统招大学专科以上学历。",
            "2、熟悉RFID电子标签行业技术规范，熟悉国内外主流RFID标签和RFID相关设备。",
            "3、熟悉通讯接口与编程；熟悉RFID相关标准协议。",
            "4、具备三年以上RFID系统设计调试经验，能调试相关读卡器和标签，懂得电路和软硬件。",
            "5、语言表达能力强，团队协调能力强，具有敬业精神。",
          ],
        },
        {
          positionName: "密码算法工程师",
          positionPay: "25-40k",
          educationalBackground: "硕士",
          agelimit: "1-3年",
          positionDescription: [
            "c/c++",
            "搜索/推荐",
            "机器学习算法/工程化经验",
            "计算机相关专业",
            "业务导向",
            "云计算",
          ],
          positionDuty: [
            "1、负责全同态加密等前沿密码算法、隐私计算协议的工程实现及性能优化；",
            "2、与算法团队合作，完成隐私保护机器学习算法框架的设计与研发。",
          ],
          jobRequirements: [
            "1、硕士及以上学历，密码学、数学、计算机、网络空间安全等相关专业；",
            "2、熟悉C/C++下的密码算法开发，具有良好的编码习惯；",
            "3、熟悉对称/非对称密码体制及其基本原理；",
            "4、了解同态加密、安全多方计算、联邦学习等隐私计算基础技术的基本原理和实现方式；",
            "5、了解密码算法的安全实现及安全性评价指标（如安全参数、可证明安全、安全规约）；",
            "6、了解常用开源密码库的实现或移植；",
            "7、有密码工程相关开发经验优先，熟悉GPU编程开发、x86/arm下密码工程开发及交叉编译优先；",
            "8、在密码学三大会议（美密欧密亚密）、网络空间安全领域Big4会议（CCS/S&P/U-Security/NDSS）上有论文发表经历的优先。",
          ],
        },
        {
          positionName: "视觉算法工程师",
          positionPay: "20-30k",
          educationalBackground: "本科",
          agelimit: "1-3年",
          positionDescription: [
            "TensorFlow/PyTorch",
            "OpenCV",
            "计算机相关专业",
            "ISP/3A算法经验",
          ],
          positionDuty: [
            " 1.研究最前沿的计算机视觉技术，包括但不限于人脸检测识别、人体检测分割、语义分割、Re_ID、姿态估计、目标跟踪、风格迁移、图像检索、图像生成等计算机视觉算法方向；",
            "2 团队专注于计算机视觉、机器学习、深度学习等领域的技术研发，探索技术前沿的创新成果与落地，负责研发计算机视觉技术软件，进行产学研成果转化",
          ],
          jobRequirements: [
            "1. 计算机类及相关专业毕业；",
            "2. 具有扎实的计算机视觉算法基础，熟悉主流模型和算法，关注领域内的最新进展，动手能力强，能够跟进和实现新的算法能力；",
            "3. 有扎实的算法与数理基础以及工程实现能力，熟练应用tensorflow/ pytorch/ Paddle/ MindSpore等深度学习框架之一，熟悉OpenCV、numpy、pyqt等工具，对计算机视觉算法研究感兴趣，并能快速实现和调试自定义算法，具有构建计算机视觉数据集、模型应用、实际项目部署与优化经验；",
            "4.有 ACM编程竞赛、天池算法竞赛、Kaggle、COCO、ImageNet等竞赛获奖，具有相关领域顶会（ICML/NIPS/ICLR/ICCV/CVPR/ECCV/...）论文发表经验优先录取；",
            "5.有良好的团队协作精神和沟通能力；具有较强的学习、分析和解决问题能力，可以迅速掌握业务逻辑并转化为技术方案，能够独立承担任务；",
          ],
        },
        {
          positionName: "产品解决方案工程师",
          positionPay: "15-20k",
          educationalBackground: "硕士",
          agelimit: "1-3年",
          positionDescription: [
            "云服务",
            "计算机/通信相关专业",
            "信息系统项目管理师",
          ],
          positionDuty: [
            "1、针对市场需求，统筹隐私计算先进技术的落地部署工作，参与和协调后端测试、研发工作，推进研发进度以及处理方案落地中的问题；",
            "2、关注隐私计算发展方向和科研课题，优化和完善隐私计算解决方案，负责方案的设计、可行性评估、沟通、引导等工作；",
            "3、面向不同行业客户，介绍推广基于公司技术的针对性解决方案，涉及大数据、数据库、业务软件等方面；",
            "4、关注客户业务发展情况，收集客户反馈的技术和产品问题，与产品团队紧密合作，优化和完善公司产品",
          ],
          jobRequirements: [],
        },
      ],
      understandData: [
        {
          itemname: "公司福利",
          itemcontent:
            "9:00-18:00、双休、员工旅游、带薪年假、企业年金、股票期权、底薪加提成、保底工资、绩效奖金、年终奖、补充医疗保险、五险一金",
        },
        {
          itemname: "公司地址",
          itemcontent: "北京市大兴区盛坊路1号北京隐算科技有限公司",
        },
        {
          itemname: "联系电话",
          itemcontent: " 13801309931",
        },
        {
          itemname: "邮箱",
          itemcontent: "liyi@yinsuankeji.com",
        },
      ],
    };
  },
  created() {
    this.acquirerecruitEvent()
  },
  methods: {
    async acquirerecruitEvent() {
      const {data} =await acquirerecruit(1,20,1)
      this.acquirerecruitList = data.data.content
      // console.log(data.data.content[2].jobRequirement.split('\n'));
    }
  }
};
</script>
          
          <style lang="less" scoped>
.joinus-bg {
  color: #fff;
  width: 100%;
  height: 400px;
  background: url("../assets/image/joinus/joinus-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  .joinus-fontBox {
    width: 1200px;
    margin: 0 auto;
  }
  .joinus-font {
    float: left;
    height: 96px;
    width: 100%;
    margin-top: 104px;
    font-size: 64px;
    letter-spacing: 8px;
  }
}
.job-box::after {
  content: "";
  margin: 80p 0 107px; /* 在交叉轴方向上放置外边距 */
}
.job-box {
  width: 1200px;
  margin: 80px 0 61px;
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    li {
      margin-right: 80px;
      margin-bottom: 46px;
      padding: 48px 49px 52px 50px;
      width: 560px;
      box-sizing: border-box;
      //   height: 600px;
      background: #ffffff;
      border-radius: 16px 16px 16px 16px;
      p {
        margin: 0;
        font-size: 14px;
        font-family: "myfont";
      }
    }
    li:nth-child(2n) {
      margin-right: 0;
    }
  }
}
.understand {
  margin-bottom: 160px;
  width: 1200px;
  .understand-item {
    width: 170px;
  }
  .understand-item p:nth-child(2) {
    margin: 28px 0 20px;
  }
  ul {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin: 0;
    padding: 0;
    img {
      vertical-align: bottom;
    }
  }
}
</style>
    