<template>
  <div
    style="
      width: 100%;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
    "
  >
    <div class="hexin-bg">
      <div class="title">
        <el-button @click="$router.push('/Customization')">技术咨询</el-button>
      </div>
    </div>
    <div class="contant">
      <div class="contant-box">
        <div class="chuantong">
          <div class="text">
            <h2>传统加密技术</h2>
            <p>
              传统加密技术是指对数据进行加密，使得未经授权的用户无法读取数据；常见的传统加密算法包括对称加密和非对称加密
            </p>
            <img
              width="100%"
              src="../assets/image/hexinjishu/Slice 91.png"
              alt=""
            />
            <div class="img"></div>
          </div>
          <div class="img">
            <img
              width="100%"
              src="../assets/image/hexinjishu/Slice 83.png"
              alt=""
            />
          </div>
        </div>
        <div class="chuantong">
          <div class="img1" style="margin: 0; margin-right: 52px">
            <img
              width="100%"
              src="../assets/image/hexinjishu/Slice 83 (1).png"
              alt=""
            />
          </div>
          <div class="text">
            <h2>半同态加密技术</h2>
            <p>
              半同态加密（Partially Homomorphic
              Encryption，PHE）允许在加密数据上执行特定的数学运算（如加法或乘法），而无需解密数据；但这种技术仅支持某一类运算，而不是全部运算
            </p>
          </div>
          <div class="img2" style="margin: 0; margin-right: 52px">
            <img
              width="100%"
              src="../assets/image/hexinjishu/Slice 83 (1).png"
              alt=""
            />
          </div>
        </div>
        <div class="quan">
          <h2>全同态加密技术</h2>
          <div class="tu">
            <div class="tu-l">
              <img
                width="100%"
                src="../assets/image/hexinjishu/Slice 92.png"
                alt=""
              />
            </div>
            <div class="tu-r">
              <img
                width="100%"
                src="../assets/image/hexinjishu/Slice 92 (1).png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="botton-text">
          <p>
            全同态加密技术基于数学同态的概念，同态加密的核心思想是允许对加密数据执行特定运算，并且这些运算的结果在解密后与在未加密数据上直接执行相同运算的结果相同<br />
            在全同态加密中，算法支持任意的算术运算（如加法和乘法），这意味着可以在加密数据上执行复杂的计算，而不需要解密数据
          </p>
          <ul>
            <li class="bold">特点</li>
            <li>
              <img src="../assets/image/hexinjishu/Slice 88.png" alt="" />
              <div>
                <span class="bold">任意运算支持：</span
                >允许在加密数据上执行任意类型的运算（加法、乘法等），并保证解密后的结果与在明文上执行相同运算的结果一致
              </div>
            </li>
            <li>
              <img src="../assets/image/hexinjishu/Slice 88.png" alt="" />
              <div>
                <span class="bold">高安全性：</span
                >数据在整个计算过程中始终保持加密状态，确保数据在传输和处理中的隐私和安全
              </div>
            </li>
            <!-- <li>
              <img src="../assets/image/hexinjishu/Slice 88.png" alt="" />
              <div>
                <span class="bold">计算复杂性高：</span
                >全同态加密的计算复杂度较高，导致运算速度慢，需要大量计算资源
              </div>
            </li>
            <li>
              <img src="../assets/image/hexinjishu/Slice 88.png" alt="" />
              <div>
                <span class="bold">密文扩展问题：</span
                >每次运算都会增加密文的长度和复杂度，需要有效的优化技术来控制密文扩展
              </div>
            </li> -->
            <li>
              <img src="../assets/image/hexinjishu/Slice 88.png" alt="" />
              <div>
                <span class="bold">灵活性强：</span
                >可以应用于广泛的场景，如云计算、数据分析、隐私保护计算等，允许在不暴露数据内容的情况下进行复杂处理
              </div>
            </li>
          </ul>
        </div>
        <div class="privacyInterconnect">
          <h2>新一代全同态隐私互联技术</h2>
          <h3>第一代：物理互联</h3>
          <h3>第二代：逻辑互联</h3>
          <h3>
            第三代：<span style="color: #2a80e5"
              >隐私互联——隐算科技自主创新</span
            >
          </h3>
          <ul>
            <li>隐私互联：解决网络安全问题</li>
            <li>隐私数据库：解决数据存储安全问题</li>
            <li>隐私智能计算：解决数据计算安全问题</li>
          </ul>
          <p>
            <strong style="color: #2a80e5">········</strong
            >实现多方隐私数据共享、融合
          </p>
          <p>
            <strong style="color: #2a80e5">········</strong
            >提高隐私保护的同时，逐步解决性能瓶颈问题，为各种应用场景提供更加安全、高效的解决方案
          </p>
          <div class="ai"><h2>AI模型与全同态加密算法相结合</h2></div>
          <img src="../assets/image/Slice 103@2x.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showBox1: null,
    };
  },
  methods: {
    toggleBox(e) {
      const liElement = e.target; // 获取当前点击的元素
      const index = Array.from(liElement.parentNode.children).indexOf(
        liElement
      ); // 获取当前元素在父元素中的索引
      this.showBox1 = index;
    },
    toggleBoxout() {
      this.showBox1 = 5;
    },
  },
};
</script>

<style lang="less" scoped>
/* 在小屏幕上的样式 */
@media (max-width: 767px) {
  /* 添加适合小屏幕的样式 */
  .hexin-bg {
    position: relative;
    width: 100%;
    height: 2048px;
    background: url("../assets/image/hexinjishu/Slice 80@2x (3).png");
    background-repeat: no-repeat;
    background-size: cover;
    .title {
      position: absolute;
      left: 50%;
      top: 963px;
      width: 100%;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      .el-button {
        width: 614px;
        height: 184px;
        background: #016eff;
        border-radius: 41px 41px 41px 41px;
        background: #016eff;
        color: #fff;
        font-size: 72px;
      }
    }
  }
  .contant {
    width: 100%;
    // height: 1673px;
    background: #fafcff;
    .contant-box {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      margin-top: 164px;
      padding: 0 82px;
      box-sizing: border-box;
      .chuantong {
        // display: flex;
        // display: flex;
        // flex-direction: column; /* 子盒子上下排列 */
        margin-bottom: 164px;
        .text {
          // flex: 1;
          h2 {
            font-size: 82px;
            color: #202124;
            font-weight: 400;
            font-family: "boldfont";
            margin-bottom: 32px;
          }
          p {
            font-size: 61px;
            color: #566884;
            font-family: "refont";
            margin-top: 24px;
            color: #566884;
            line-height: 123px;
          }
          img {
            display: none;
          }
          .img {
            width: 1756px !important;
            height: 266px;
            background: url("../assets/image/hexinjishu/Slice 91@2x (1).png");
            background-repeat: no-repeat;
            background-size: cover;
            margin: 0;
          }
        }
        .img {
          width: 100%;
          height: 1075px;
          background: #e2edff;
          // margin-left: 52px;
          margin-top: 82px;
          padding: 61px;
          box-sizing: border-box;
        }
        .img1 {
          display: none;
          width: 457px;
          height: 280px;
          background: #e2edff;
          margin-left: 52px;
          padding: 14px 16px;
          box-sizing: border-box;
        }
        .img2 {
          display: block;
          width: 100%;
          height: 1075px;
          background: #e2edff;
          // margin-left: 52px;
          margin-top: 82px;
          padding: 61px;
          box-sizing: border-box;
        }
      }
    }
  }
  .quan {
    position: relative;
    h2 {
      font-size: 82px;
      color: #202124;
      font-weight: 400;
      font-family: "boldfont";
      margin-bottom: 32px;
    }
    .tu {
      margin-bottom: 28px;
      overflow: hidden;
      .tu-l {
        // float: left;
        width: 1203px;
        height: 1183px;
        margin: 0 auto;
      }
      .tu-r {
        // float: left;
        width: 1756px;
        height: 454px;
        // margin: 61px 0 0;
      }
    }
  }
  .botton-text {
    p {
      color: #566884;
      font-family: "refont";
      line-height: 123px;
      font-size: 61px;
      margin-top: 0;
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        display: flex;
        font-family: "refont";
        color: #566884;
        font-size: 61px;
        margin-bottom: 61px;
        img {
          float: left;
          width: 61px;
          height: 61px;
          margin-right: 31px;
        }
      }
    }
    .bold {
      color: #202124;
      font-family: "boldfont";
    }
  }
  .privacyInterconnect {
    margin-top: 164px;
    h2 {
      font-size: 82px;
      color: #202124;
      font-weight: 400;
      font-family: "boldfont";
      margin-bottom: 32px;
    }
    h3 {
      font-size: 61px;
      color: #202124;
      line-height: 72px;
      font-weight: 400;
      font-family: "myfont";
    }
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      li {
        text-align: center;

        color: #202124;
        font-family: "myfont";
        width: 1275px;
        height: 225px;
        line-height: 225px;
        background: #e9f3ff;
        box-shadow: 0px 20 20px 0px #e1e6ed;
        border-radius: 41px 41px 41px 41px;
        border: 5px solid #ffffff;
        margin-bottom: 61px;
        font-size: 61px;
      }
    }
    p {
      display: flex;
      font-size: 20px;
      color: #202124;
      font-family: "refont";
      margin-top: 61px;
      font-size: 61px;
      color: #566884;
      line-height: 90px;
    }
    .ai {
      margin-top: 164px;
    }
    img {
      width: 1756px;
      height: 1771px;
      margin: 0px auto 100px;
      display: block;
    }
  }
  .fade-in {
    animation: fadeIn 1.5s;
  }
  .fade-in2 {
    animation: fadeIn2 1.5s;
  }

  .fade-out {
    animation: fadeOut 1.5s;
  }
  .fade-out2 {
    animation: fadeOut2 1.5s;
  }

  @keyframes fadeIn {
    from {
      margin-left: 360px;
      opacity: 0;
    }
    to {
      margin-left: 0;
      opacity: 1;
    }
  }
  @keyframes fadeIn2 {
    from {
      margin-left: 0;
      opacity: 1;
    }
    to {
      margin-left: 360px;
      opacity: 0;
    }
  }

  @keyframes fadeOut {
    from {
      margin-left: 0;
      opacity: 1;
    }
    to {
      margin-left: -360px;
      opacity: 0;
    }
  }
  @keyframes fadeOut2 {
    from {
      margin-left: -360px;
      opacity: 0;
    }
    to {
      margin-left: 0;
      opacity: 1;
    }
  }
}

/* 在中屏幕上的样式 */
@media (min-width: 768px) and (max-width: 1023px) {
  /* 添加适合中屏幕的样式 */
  .hexin-bg {
    position: relative;
    width: 100%;
    height: 550px;
    background: url("../assets/image/hexinjishu/Slice 82.png");
    background-repeat: no-repeat;
    background-size: cover;
    .title {
      position: absolute;
      left: 50%;
      top: 352px;
      width: 1200px;
      transform: translateX(-50%);
      .el-button {
        width: 240px;
        height: 60px;
        background: #016eff;
        color: #fff;
        font-size: 18px;
        border-radius: 8px 8px 8px 8px;
      }
    }
  }
  .contant {
    width: 100%;
    // height: 1673px;
    background: #fafcff;
    .contant-box {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      margin-top: 72px;
      .chuantong {
        display: flex;
        margin-bottom: 72px;
        .text {
          flex: 1;
          h2 {
            font-size: 24px;
            color: #202124;
            font-weight: 400;
            font-family: "boldfont";
            margin-bottom: 32px;
          }
          p {
            font-size: 20px;
            font-family: "refont";
            margin-top: 24px;
            color: #566884;
            line-height: 40px;
          }
          .img {
            display: none;
          }
        }
        .img {
          width: 457px;
          height: 280px;
          background: #e2edff;
          margin-left: 52px;
          padding: 14px 16px;
          box-sizing: border-box;
        }
        .img1 {
          display: block;
          width: 457px;
          height: 280px;
          background: #e2edff;
          margin-left: 52px;
          padding: 14px 16px;
          box-sizing: border-box;
        }
        .img2 {
          display: none;
          width: 457px;
          height: 280px;
          background: #e2edff;
          margin-left: 52px;
          padding: 14px 16px;
          box-sizing: border-box;
        }
      }
    }
  }
  .quan {
    position: relative;
    h2 {
      font-size: 24px;
      color: #202124;
      font-weight: 400;
      font-family: "boldfont";
      margin-bottom: 22px;
      text-align: center;
    }
    .tu {
      margin-bottom: 28px;
      overflow: hidden;
      .tu-l {
        float: left;
        width: 295px;
        height: 290px;
        margin-left: 146px;
      }
      .tu-r {
        float: left;
        width: 627px;
        height: 162px;
        margin: 28px 0 0 73px;
      }
    }
  }
  .botton-text {
    p {
      font-size: 20px;
      color: #566884;
      font-family: "refont";
      line-height: 40px;
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        display: flex;
        font-family: "refont";
        color: #566884;
        font-size: 20px;
        margin-bottom: 20px;
        img {
          width: 16px;
          height: 16px;
          margin-right: 13px;
        }
      }
    }
    .bold {
      color: #202124;
      font-family: "myfont";
    }
  }
  .privacyInterconnect {
    margin-top: 72px;
    h2 {
      font-size: 24px;
      color: #202124;
      font-weight: 400;
      font-family: "boldfont";
      margin-bottom: 22px;
      text-align: center;
    }
    h3 {
      font-size: 20px;
      color: #202124;
      line-height: 23px;
      font-weight: 400;
      font-family: "myfont";
    }
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: space-between;
      li {
        width: 387px;
        height: 60px;
        background: #e9f3ff;
        box-shadow: 0px 4px 4px 0px #e1e6ed;
        border-radius: 8px 8px 8px 8px;
        border: 1px solid #ffffff;
        text-align: center;
        line-height: 60px;
        font-size: 20px;
        color: #202124;
        font-family: "myfont";
      }
    }
    p {
      font-size: 20px;
      color: #202124;
      line-height: 23px;
      font-family: "refont";
      margin-top: 20px;
    }
    .ai {
      margin-top: 60px;
    }
    img {
      width: 840px;
      height: 847px;
      margin: 0px auto 100px;
      display: block;
    }
  }
  .fade-in {
    animation: fadeIn 1.5s;
  }
  .fade-in2 {
    animation: fadeIn2 1.5s;
  }

  .fade-out {
    animation: fadeOut 1.5s;
  }
  .fade-out2 {
    animation: fadeOut2 1.5s;
  }

  @keyframes fadeIn {
    from {
      margin-left: 360px;
      opacity: 0;
    }
    to {
      margin-left: 0;
      opacity: 1;
    }
  }
  @keyframes fadeIn2 {
    from {
      margin-left: 0;
      opacity: 1;
    }
    to {
      margin-left: 360px;
      opacity: 0;
    }
  }

  @keyframes fadeOut {
    from {
      margin-left: 0;
      opacity: 1;
    }
    to {
      margin-left: -360px;
      opacity: 0;
    }
  }
  @keyframes fadeOut2 {
    from {
      margin-left: -360px;
      opacity: 0;
    }
    to {
      margin-left: 0;
      opacity: 1;
    }
  }
}

/* 在大屏幕上的样式 */
@media (min-width: 1024px) {
  /* 添加适合大屏幕的样式 */
  .hexin-bg {
    position: relative;
    width: 100%;
    height: 550px;
    background: url("../assets/image/hexinjishu/Slice 82.png");
    background-repeat: no-repeat;
    background-size: cover;
    .title {
      position: absolute;
      left: 50%;
      top: 352px;
      width: 1200px;
      transform: translateX(-50%);
      .el-button {
        width: 240px;
        height: 60px;
        background: #016eff;
        color: #fff;
        font-size: 18px;
        border-radius: 8px 8px 8px 8px;
      }
    }
  }
  .contant {
    width: 100%;
    // height: 1673px;
    background: #fafcff;
    .contant-box {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      margin-top: 72px;
      .chuantong {
        display: flex;
        margin-bottom: 72px;
        .text {
          flex: 1;
          h2 {
            font-size: 24px;
            color: #202124;
            font-weight: 400;
            font-family: "boldfont";
            margin-bottom: 32px;
          }
          p {
            font-size: 20px;
            font-family: "refont";
            margin-top: 24px;
            color: #566884;
            line-height: 40px;
          }
          .img {
            display: none;
          }
        }
        .img {
          width: 457px;
          height: 280px;
          background: #e2edff;
          margin-left: 52px;
          padding: 14px 16px;
          box-sizing: border-box;
        }
        .img1 {
          display: block;
          width: 457px;
          height: 280px;
          background: #e2edff;
          margin-left: 52px;
          padding: 14px 16px;
          box-sizing: border-box;
        }
        .img2 {
          display: none;
          width: 457px;
          height: 280px;
          background: #e2edff;
          margin-left: 52px;
          padding: 14px 16px;
          box-sizing: border-box;
        }
      }
    }
  }
  .quan {
    position: relative;
    h2 {
      font-size: 24px;
      color: #202124;
      font-weight: 400;
      font-family: "boldfont";
      margin-bottom: 22px;
      text-align: center;
    }
    .tu {
      margin-bottom: 28px;
      overflow: hidden;
      .tu-l {
        float: left;
        width: 295px;
        height: 290px;
        margin-left: 146px;
      }
      .tu-r {
        float: left;
        width: 627px;
        height: 162px;
        margin: 28px 0 0 73px;
      }
    }
  }
  .botton-text {
    p {
      font-size: 20px;
      color: #566884;
      font-family: "refont";
      line-height: 40px;
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        display: flex;
        font-family: "refont";
        color: #566884;
        font-size: 20px;
        margin-bottom: 20px;
        img {
          width: 16px;
          height: 16px;
          margin-right: 13px;
        }
      }
    }
    .bold {
      color: #202124;
      font-family: "myfont";
    }
  }
  .privacyInterconnect {
    margin-top: 72px;
    h2 {
      font-size: 24px;
      color: #202124;
      font-weight: 400;
      font-family: "boldfont";
      margin-bottom: 22px;
      text-align: center;
    }
    h3 {
      font-size: 20px;
      color: #202124;
      line-height: 23px;
      font-weight: 400;
      font-family: "myfont";
    }
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: space-between;
      li {
        width: 387px;
        height: 60px;
        background: #e9f3ff;
        box-shadow: 0px 4px 4px 0px #e1e6ed;
        border-radius: 8px 8px 8px 8px;
        border: 1px solid #ffffff;
        text-align: center;
        line-height: 60px;
        font-size: 20px;
        color: #202124;
        font-family: "myfont";
      }
    }
    p {
      font-size: 20px;
      color: #202124;
      line-height: 23px;
      font-family: "refont";
      margin-top: 20px;
    }
    .ai {
      margin-top: 60px;
    }
    img {
      width: 840px;
      height: 847px;
      margin: 0px auto 100px;
      display: block;
    }
  }
  .fade-in {
    animation: fadeIn 1.5s;
  }
  .fade-in2 {
    animation: fadeIn2 1.5s;
  }

  .fade-out {
    animation: fadeOut 1.5s;
  }
  .fade-out2 {
    animation: fadeOut2 1.5s;
  }

  @keyframes fadeIn {
    from {
      margin-left: 360px;
      opacity: 0;
    }
    to {
      margin-left: 0;
      opacity: 1;
    }
  }
  @keyframes fadeIn2 {
    from {
      margin-left: 0;
      opacity: 1;
    }
    to {
      margin-left: 360px;
      opacity: 0;
    }
  }

  @keyframes fadeOut {
    from {
      margin-left: 0;
      opacity: 1;
    }
    to {
      margin-left: -360px;
      opacity: 0;
    }
  }
  @keyframes fadeOut2 {
    from {
      margin-left: -360px;
      opacity: 0;
    }
    to {
      margin-left: 0;
      opacity: 1;
    }
  }
}
</style>