<template>
  <div
    style="
      width: 100%;
      background-color: #eef3fd;
      display: flex;
      flex-direction: column;
      align-items: center;
    "
  >
    <!-- 产品中心背景图 -->
    <div class="news-bg">
      <div class="news-fontBox">
        <h2>{{ newsTitle }}</h2>
        <div>
          <span v-if="newsType == 0" class="type">行业新闻</span
          ><span v-else class="type1">公司新闻</span
          ><span class="text">{{ newsUpdateDate }}</span>
        </div>
        <div class="line"></div>
        <!-- <div class="news-font">行业动态</div>
        <div class="back" @click="$router.go(-1)">
          <i
            class="el-icon-arrow-left"
            style="font-size: 0.2rem; margin: auto; color: #fff"
          ></i>
        </div> -->
      </div>
    </div>
    <!-- 产品介绍 -->
    <div class="news-news">
      <!-- <div class="news-top"></div> -->
      <div class="detailsOne-content">
        <div v-html="acquireNewsInfoContent"></div>
      </div>
    </div>
  </div>
</template>
        
<script>
import { acquireNewsInfo } from "../api/news";
export default {
  data() {
    return {
      acquireNewsInfoContent: null,
      newsTitle: null,
      newsType: 1,
      newsUpdateDate: null,
    };
  },
  async created() {
    const { data } = await acquireNewsInfo(this.$route.query.id);
    this.acquireNewsInfoContent = data.data.newsContent;
    this.newsTitle = data.data.newsTitle;
    this.newsType = data.data.newsType;
    this.newsUpdateDate = data.data.newsUpdateDate;
  },
};
</script>
<style lang="less">
// Slice 80@2x (22)
/* 在小屏幕上的样式 */
@media (max-width: 767px) {
  /* 添加适合小屏幕的样式 */
  .news-bg {
    color: #fff;
    width: 100%;
    // height: 2048px;
    // position: fixed;
    background: url("../assets/image/news-new/Slice 80@2x (22).png");
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    .news-fontBox {
      width: 100%;
      padding: 0 82px;
      box-sizing: border-box;
      margin: 0 auto;
      h2 {
        font-weight: 400;
        font-family: "boldfont";
        font-size: 102px;
        color: #202124;
        margin-top: 532px;
        margin-bottom: 123px;
      }
      .type {
        display: inline-block;

        text-align: center;
        background: #3565fc;

        color: #ffffff;
        font-family: "boldfont";
        margin-right: 61px;
        width: 410px;
        height: 143px;
        line-height: 143px;
        background: #3565fc;
        border-radius: 31px 31px 31px 31px;
        font-size: 61px;
      }
      .type1 {
        display: inline-block;

        text-align: center;
        background: #3565fc;

        color: #ffffff;
        font-family: "boldfont";
        margin-right: 61px;
        width: 410px;
        height: 143px;
        font-size: 61px;
        line-height: 143px;
        border-radius: 31px 31px 31px 31px;
        text-align: center;
        background: #25bbdd;
        color: #ffffff;
        font-family: "boldfont";
      }
      .text {
        display: inline-block;
        font-size: 61px;
        color: #566884;
        font-family: "refont";
      }
      .line {
        width: 100%;
        height: 102px;
        border-bottom: 1px solid #000;
      }
    }
    .news-font {
      float: left;
      height: 96px;
      width: 100%;
      margin-top: 104px;
      font-size: 64px;
      letter-spacing: 8px;
    }
    .back {
      cursor: pointer;
      line-height: 80px;
      text-align: center;
      float: left;
      margin-top: 28px;
      width: 60px;
      height: 60px;
      background: rgba(89, 235, 255, 0.2);
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      border: 1px solid #59ebff;
    }
  }
  .news-news {
    width: 100%;
    margin: 102px 0;
    padding: 0 82px;
    // margin-top: -135px;
    background-color: transparent;
    border-radius: 20px 20px 20px 20px;
    box-sizing: border-box;
    .news-top {
      float: left;
      left: 0;
      width: 1200px;
      height: 40px;
      background: linear-gradient(117deg, #c9daeb 0%, #dcebfa 100%);
      border-radius: 20px 20px 0px 0px;
    }
    .detailsOne-content {
      width: 100%;
      height: 100%;
      padding: 0px 40px 80px;
      box-sizing: border-box;
      .img-newsbox {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      img {
        width: 100%;
        height: 100%;
      }
      h3 {
        text-align: center;
        font-size: 82px;
        width: 100%;
        margin: 0 auto;
      }
      p {
        font-family: "refont";
        font-size: 61px;
        margin: 102px 0;
      }
    }
  }
  .textCenter {
    text-align: center;
  }
  .textIndent {
    text-indent: 2em;
  }
  .ql-align-center {
    text-align: center !important;
  }
  .ql-align-justify {
    // text-align: center !important;
  }
  .detailsOne-content {
    width: 100%;
    height: 100%;
    padding: 0 40px 80px;
    box-sizing: border-box;
    .img-newsbox {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      // height: 100%;
      max-width: 100%;
    }
    h3 {
      text-align: center;
      font-size: 82px;
      width: 855px;
      margin: 0 auto;
    }
    p {
      font-family: "refont";
      font-size: 61px;
      margin: 16px 0;
    }
  }
  .textCenter {
    text-align: center;
  }
  .textIndent {
    text-indent: 2em;
  }
}

/* 在中屏幕上的样式 */
@media (min-width: 768px) and (max-width: 1023px) {
  /* 添加适合中屏幕的样式 */
  .news-bg {
    color: #fff;
    width: 100%;
    // height: 50px;
    background: url("../assets/image/news-new/Slice 90.png");
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    .news-fontBox {
      width: 1200px;
      margin: 0 auto;
      h2 {
        font-weight: 400;
        font-family: "boldfont";
        font-size: 40px;
        color: #202124;
        margin-top: 180px;
        margin-bottom: 36px;
      }
      .type {
        display: inline-block;
        width: 72px;
        height: 26px;
        line-height: 26px;
        text-align: center;
        background: #3565fc;
        border-radius: 6px 6px 6px 6px;
        font-size: 12px;
        color: #ffffff;
        font-family: "boldfont";
        margin-right: 8px;
      }
      .type1 {
        display: inline-block;
        width: 72px;
        height: 26px;
        line-height: 26px;
        text-align: center;
        background: #25bbdd;
        border-radius: 6px 6px 6px 6px;
        font-size: 12px;
        color: #ffffff;
        font-family: "boldfont";
        margin-right: 8px;
      }
      .text {
        display: inline-block;
        font-size: 14px;
        color: #566884;
        font-family: "refont";
      }
      .line {
        width: 100%;
        height: 27px;
        border-bottom: 1px solid #000;
      }
    }
    .news-font {
      float: left;
      height: 96px;
      width: 100%;
      margin-top: 104px;
      font-size: 64px;
      letter-spacing: 8px;
    }
    .back {
      cursor: pointer;
      line-height: 80px;
      text-align: center;
      float: left;
      margin-top: 28px;
      width: 60px;
      height: 60px;
      background: rgba(89, 235, 255, 0.2);
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      border: 1px solid #59ebff;
    }
  }
  .news-news {
    width: 1200px;
    margin: 40px 0;
    // margin-top: -135px;
    background-color: transparent;
    border-radius: 20px 20px 20px 20px;
    box-sizing: border-box;
    .news-top {
      float: left;
      left: 0;
      width: 1200px;
      height: 40px;
      background: linear-gradient(117deg, #c9daeb 0%, #dcebfa 100%);
      border-radius: 20px 20px 0px 0px;
    }
    .detailsOne-content {
      width: 100%;
      height: 100%;
      padding: 0px 40px 80px;
      box-sizing: border-box;
      .img-newsbox {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      img {
        width: 100%;
        height: 100%;
      }
      h3 {
        text-align: center;
        font-size: 40px;
        width: 855px;
        margin: 0 auto;
      }
      p {
        font-family: "refont";
        font-size: 20px;
        margin: 16px 0;
      }
    }
  }
  .textCenter {
    text-align: center;
  }
  .textIndent {
    text-indent: 2em;
  }
  .ql-align-center {
    text-align: center !important;
  }
  .ql-align-justify {
    // text-align: center !important;
  }
  .detailsOne-content {
    width: 100%;
    height: 100%;
    padding: 0 40px 80px;
    box-sizing: border-box;
    .img-newsbox {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      // height: 100%;
      max-width: 100%;
    }
    h3 {
      text-align: center;
      font-size: 40px;
      width: 855px;
      margin: 0 auto;
    }
    p {
      font-family: "refont";
      font-size: 20px;
      margin: 16px 0;
    }
  }
  .textCenter {
    text-align: center;
  }
  .textIndent {
    text-indent: 2em;
  }
}

/* 在大屏幕上的样式 */
@media (min-width: 1024px) {
  /* 添加适合大屏幕的样式 */
  .news-bg {
    color: #fff;
    width: 100%;
    // height: 50px;
    background: url("../assets/image/news-new/Slice 90.png");
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    .news-fontBox {
      width: 1200px;
      margin: 0 auto;
      h2 {
        font-weight: 400;
        font-family: "boldfont";
        font-size: 40px;
        color: #202124;
        margin-top: 180px;
        margin-bottom: 36px;
      }
      .type {
        display: inline-block;
        width: 72px;
        height: 26px;
        line-height: 26px;
        text-align: center;
        background: #3565fc;
        border-radius: 6px 6px 6px 6px;
        font-size: 12px;
        color: #ffffff;
        font-family: "boldfont";
        margin-right: 8px;
      }
      .type1 {
        display: inline-block;
        width: 72px;
        height: 26px;
        line-height: 26px;
        text-align: center;
        background: #25bbdd;
        border-radius: 6px 6px 6px 6px;
        font-size: 12px;
        color: #ffffff;
        font-family: "boldfont";
        margin-right: 8px;
      }
      .text {
        display: inline-block;
        font-size: 14px;
        color: #566884;
        font-family: "refont";
      }
      .line {
        width: 100%;
        height: 27px;
        border-bottom: 1px solid #000;
      }
    }
    .news-font {
      float: left;
      height: 96px;
      width: 100%;
      margin-top: 104px;
      font-size: 64px;
      letter-spacing: 8px;
    }
    .back {
      cursor: pointer;
      line-height: 80px;
      text-align: center;
      float: left;
      margin-top: 28px;
      width: 60px;
      height: 60px;
      background: rgba(89, 235, 255, 0.2);
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      border: 1px solid #59ebff;
    }
  }
  .news-news {
    width: 1200px;
    margin: 40px 0;
    // margin-top: -135px;
    background-color: transparent;
    border-radius: 20px 20px 20px 20px;
    box-sizing: border-box;
    .news-top {
      float: left;
      left: 0;
      width: 1200px;
      height: 40px;
      background: linear-gradient(117deg, #c9daeb 0%, #dcebfa 100%);
      border-radius: 20px 20px 0px 0px;
    }
    .detailsOne-content {
      width: 100%;
      height: 100%;
      padding: 0px 40px 80px;
      box-sizing: border-box;
      .img-newsbox {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      img {
        width: 100%;
        height: 100%;
      }
      h3 {
        text-align: center;
        font-size: 40px;
        width: 855px;
        margin: 0 auto;
      }
      p {
        font-family: "refont";
        font-size: 20px;
        margin: 16px 0;
      }
    }
  }
  .textCenter {
    text-align: center;
  }
  .textIndent {
    text-indent: 2em;
  }
  .ql-align-center {
    text-align: center !important;
  }
  .ql-align-justify {
    // text-align: center !important;
  }
  .detailsOne-content {
    width: 100%;
    height: 100%;
    padding: 0 40px 80px;
    box-sizing: border-box;
    .img-newsbox {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      // height: 100%;
      max-width: 100%;
    }
    h3 {
      text-align: center;
      font-size: 40px;
      width: 855px;
      margin: 0 auto;
    }
    p {
      font-family: "refont";
      font-size: 20px;
      margin: 16px 0;
    }
  }
  .textCenter {
    text-align: center;
  }
  .textIndent {
    text-indent: 2em;
  }
}
</style>     
<style lang="less" scoped>
</style>