
<template>
  <div
    style="
      width: 100%;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
    "
  >
    <div class="hexin-bg">
      <div class="title">
        <el-button @click="$router.push('/Customization')">立即咨询</el-button>
      </div>
    </div>
    <div class="contant">
      <div class="contant-box">
        <div class="chuantong">
          <div class="text">
            <h2>密文身份识别</h2>
            <p>
              密文门禁系统不同于以往“明文门禁系统”的数据存储和页面交互，密文门禁系统的关键特征在于其关注点为“数据安全处理”，它可以保证在不解密的情况下对密文进行计算，使得解密后的结果与明文执行相同计算得到的结果相同，保护了用户的隐私，保证了用户数据的安全性
            </p>
          </div>
          <div class="img">
            <div class="img-contant">
              <img
                width="100%"
                src="../../assets/image/fuwu/shenfeng1.png"
                alt=""
              />
              <p>密文门禁设备</p>
            </div>
            <div class="img-contant">
              <img
                width="100%"
                src="../../assets/image/fuwu/shenfeng2.png"
                alt=""
              />
              <p>隐算门禁后台管理系统</p>
            </div>
          </div>
        </div>
        <div class="chuantong2">
          <h2>产品架构图</h2>
          <div class="img" style="margin: 0">
            <img
              width="100%"
              src="../../assets/image/fuwu/Slice 98 (3).png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
    <script>
export default {
  data() {
    return {
      showBox1: null,
    };
  },
  methods: {
    toggleBox(e) {
      const liElement = e.target; // 获取当前点击的元素
      const index = Array.from(liElement.parentNode.children).indexOf(
        liElement
      ); // 获取当前元素在父元素中的索引
      this.showBox1 = index;
    },
    toggleBoxout() {
      this.showBox1 = 5;
    },
  },
};
</script>
    
    <style lang="less" scoped>
//
/* 在小屏幕上的样式 */
@media (max-width: 767px) {
  /* 添加适合小屏幕的样式 */
  .hexin-bg {
    position: relative;
    width: 100%;
    height: 2048px;
    background: url("../../assets/image/fuwu/Slice 80@2x (10).png");
    background-repeat: no-repeat;
    background-size: cover;
    .title {
      position: absolute;
      left: 50%;
      top: 922px;
      // width: 100%;
      transform: translateX(-50%);
      .el-button {
        width: 614px;
        height: 184px;
        background: #016eff;
        color: #fff;
        font-size: 72px;
        border-radius: 41px 41px 41px 41px;
      }
    }
  }
  .contant {
    width: 100%;
    // height: 1081px;
    background: #fafcff;
    .contant-box {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      margin-top: 164px;
      padding: 0 82px;
      box-sizing: border-box;
      .chuantong {
        // display: flex;
        margin-bottom: 164px;
        .text {
          // flex: 1;
          h2 {
            font-size: 82px;
            color: #202124;
            font-weight: 400;
            font-family: "boldfont";
            margin: 0;
            margin-bottom: 61px;
          }
          p {
            font-family: "refont";
            margin-top: 0;
            font-size: 61px;
            color: #566884;
            line-height: 123px;
          }
        }
        .img {
          width: 100%;
          height: 696px;
          display: flex;
          justify-content: space-between;
          .img-contant {
            padding: 41px;
            position: relative;
            box-sizing: border-box;
            width: 840px;
            height: 696px;
            background: #e2edff;
            p {
              position: absolute;
              bottom: -202px;
              font-size: 61px;
              left: 50%;
              transform: translateX(-50%);
              width: 100%;
              text-align: center;
            }
          }
        }
      }
      .chuantong2 {
        margin-top: 300px;
        display: flex;
        margin-bottom: 307px;
        flex-direction: column;
        h2 {
          font-size: 82px;
          color: #202124;
          font-weight: 400;
          font-family: "boldfont";
          margin: 0;
          margin-bottom: 61px;
        }
        .text {
          flex: 1;
          h2 {
            font-size: 24px;
            color: #202124;
            font-weight: 400;
            font-family: "boldfont";
            margin-bottom: 32px;
          }
          p {
            font-size: 20px;
            font-family: "myfont";
            margin-top: 24px;
          }
        }
        img {
          width: 100%;
        }
      }
    }
  }
  .quan {
    position: relative;
    h2 {
      font-size: 24px;
      color: #202124;
      font-weight: 400;
      font-family: "boldfont";
      margin-bottom: 22px;
      text-align: center;
    }
    .tu {
      margin-bottom: 28px;
      overflow: hidden;
      .tu-l {
        float: left;
        width: 295px;
        height: 290px;
        margin-left: 146px;
      }
      .tu-r {
        float: left;
        width: 627px;
        height: 162px;
        margin: 28px 0 0 73px;
      }
    }
  }
  .botton-text {
    p {
      font-size: 20px;
      color: #566884;
      font-family: "refont";
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        font-family: "refont";
        color: #566884;
        font-size: 20px;
        margin-bottom: 20px;
        img {
          width: 16px;
          height: 16px;
          margin-right: 13px;
        }
      }
    }
    .bold {
      color: #202124;
      font-family: "boldfont";
    }
  }
  .fade-in {
    animation: fadeIn 1.5s;
  }
  .fade-in2 {
    animation: fadeIn2 1.5s;
  }

  .fade-out {
    animation: fadeOut 1.5s;
  }
  .fade-out2 {
    animation: fadeOut2 1.5s;
  }

  @keyframes fadeIn {
    from {
      margin-left: 360px;
      opacity: 0;
    }
    to {
      margin-left: 0;
      opacity: 1;
    }
  }
  @keyframes fadeIn2 {
    from {
      margin-left: 0;
      opacity: 1;
    }
    to {
      margin-left: 360px;
      opacity: 0;
    }
  }

  @keyframes fadeOut {
    from {
      margin-left: 0;
      opacity: 1;
    }
    to {
      margin-left: -360px;
      opacity: 0;
    }
  }
  @keyframes fadeOut2 {
    from {
      margin-left: -360px;
      opacity: 0;
    }
    to {
      margin-left: 0;
      opacity: 1;
    }
  }
}

/* 在中屏幕上的样式 */
@media (min-width: 768px) and (max-width: 1023px) {
  /* 添加适合中屏幕的样式 */
  .hexin-bg {
    position: relative;
    width: 100%;
    height: 550px;
    background: url("../../assets/image/fuwu/Slice 82@2x (9).png");
    background-repeat: no-repeat;
    background-size: cover;
    .title {
      position: absolute;
      left: 50%;
      top: 352px;
      width: 1200px;
      transform: translateX(-50%);
      .el-button {
        width: 240px;
        height: 60px;
        background: #016eff;
        color: #fff;
        font-size: 18px;
        border-radius: 8px 8px 8px 8px;
      }
    }
  }
  .contant {
    width: 100%;
    // height: 1205px;
    background: #fafcff;
    .contant-box {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      margin-top: 72px;
      .chuantong {
        display: flex;
        margin-bottom: 72px;
        .text {
          flex: 1;
          h2 {
            font-size: 24px;
            color: #202124;
            font-weight: 400;
            font-family: "boldfont";
            margin-bottom: 32px;
          }
          p {
            font-size: 20px;
            font-family: "refont";
            margin-top: 24px;
            color: #566884;
            line-height: 40px;
          }
        }
        .img {
          position: relative;
          width: 709px;
          height: 280px;
          margin-left: 52px;
          display: flex;
          justify-content: space-between;
          .img-contant {
            position: relative;
            padding: 14px 16px;
            box-sizing: border-box;
            width: 338px;
            height: 289px;
            background: #e2edff;
            p {
              position: absolute;
              bottom: -80px;
              font-size: 24px;
              left: 50%;
              transform: translateX(-50%);
              width: 100%;
              text-align: center;
            }
          }
        }
      }
      .chuantong2 {
        display: flex;
        margin-bottom: 72px;
        flex-direction: column;
        align-items: center;
        h2 {
          font-size: 24px;
          color: #202124;
          font-weight: 400;
          font-family: "boldfont";
          margin: 0 0 32px;
        }
        .text {
          flex: 1;
          h2 {
            font-size: 24px;
            color: #202124;
            font-weight: 400;
            font-family: "boldfont";
            margin-bottom: 32px;
          }
          p {
            font-size: 20px;
            font-family: "myfont";
            margin-top: 24px;
          }
        }
        img {
          width: 817px;
        }
      }
    }
  }
  .quan {
    position: relative;
    h2 {
      font-size: 24px;
      color: #202124;
      font-weight: 400;
      font-family: "boldfont";
      margin-bottom: 22px;
      text-align: center;
    }
    .tu {
      margin-bottom: 28px;
      overflow: hidden;
      .tu-l {
        float: left;
        width: 295px;
        height: 290px;
        margin-left: 146px;
      }
      .tu-r {
        float: left;
        width: 627px;
        height: 162px;
        margin: 28px 0 0 73px;
      }
    }
  }
  .botton-text {
    p {
      font-size: 20px;
      color: #566884;
      font-family: "refont";
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        font-family: "refont";
        color: #566884;
        font-size: 20px;
        margin-bottom: 20px;
        img {
          width: 16px;
          height: 16px;
          margin-right: 13px;
        }
      }
    }
    .bold {
      color: #202124;
      font-family: "boldfont";
    }
  }
  .fade-in {
    animation: fadeIn 1.5s;
  }
  .fade-in2 {
    animation: fadeIn2 1.5s;
  }

  .fade-out {
    animation: fadeOut 1.5s;
  }
  .fade-out2 {
    animation: fadeOut2 1.5s;
  }

  @keyframes fadeIn {
    from {
      margin-left: 360px;
      opacity: 0;
    }
    to {
      margin-left: 0;
      opacity: 1;
    }
  }
  @keyframes fadeIn2 {
    from {
      margin-left: 0;
      opacity: 1;
    }
    to {
      margin-left: 360px;
      opacity: 0;
    }
  }

  @keyframes fadeOut {
    from {
      margin-left: 0;
      opacity: 1;
    }
    to {
      margin-left: -360px;
      opacity: 0;
    }
  }
  @keyframes fadeOut2 {
    from {
      margin-left: -360px;
      opacity: 0;
    }
    to {
      margin-left: 0;
      opacity: 1;
    }
  }
}

/* 在大屏幕上的样式 */
@media (min-width: 1024px) {
  /* 添加适合大屏幕的样式 */
  .hexin-bg {
    position: relative;
    width: 100%;
    height: 550px;
    background: url("../../assets/image/fuwu/Slice 82@2x (9).png");
    background-repeat: no-repeat;
    background-size: cover;
    .title {
      position: absolute;
      left: 50%;
      top: 352px;
      width: 1200px;
      transform: translateX(-50%);
      .el-button {
        width: 240px;
        height: 60px;
        background: #016eff;
        color: #fff;
        font-size: 18px;
        border-radius: 8px 8px 8px 8px;
      }
    }
  }
  .contant {
    width: 100%;
    // height: 1205px;
    background: #fafcff;
    .contant-box {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      margin-top: 72px;
      .chuantong {
        display: flex;
        margin-bottom: 72px;
        .text {
          flex: 1;
          h2 {
            font-size: 24px;
            color: #202124;
            font-weight: 400;
            font-family: "boldfont";
            margin-bottom: 32px;
          }
          p {
            font-size: 20px;
            font-family: "refont";
            margin-top: 24px;
            color: #566884;
            line-height: 40px;
          }
        }
        .img {
          position: relative;
          width: 709px;
          height: 280px;
          margin-left: 52px;
          display: flex;
          justify-content: space-between;
          .img-contant {
            position: relative;
            padding: 14px 16px;
            box-sizing: border-box;
            width: 338px;
            height: 289px;
            background: #e2edff;
            p {
              position: absolute;
              bottom: -80px;
              font-size: 24px;
              left: 50%;
              transform: translateX(-50%);
              width: 100%;
              text-align: center;
            }
          }
        }
      }
      .chuantong2 {
        display: flex;
        margin-bottom: 72px;
        flex-direction: column;
        align-items: center;
        h2 {
          font-size: 24px;
          color: #202124;
          font-weight: 400;
          font-family: "boldfont";
          margin: 0 0 32px;
        }
        .text {
          flex: 1;
          h2 {
            font-size: 24px;
            color: #202124;
            font-weight: 400;
            font-family: "boldfont";
            margin-bottom: 32px;
          }
          p {
            font-size: 20px;
            font-family: "myfont";
            margin-top: 24px;
          }
        }
        img {
          width: 817px;
        }
      }
    }
  }
  .quan {
    position: relative;
    h2 {
      font-size: 24px;
      color: #202124;
      font-weight: 400;
      font-family: "boldfont";
      margin-bottom: 22px;
      text-align: center;
    }
    .tu {
      margin-bottom: 28px;
      overflow: hidden;
      .tu-l {
        float: left;
        width: 295px;
        height: 290px;
        margin-left: 146px;
      }
      .tu-r {
        float: left;
        width: 627px;
        height: 162px;
        margin: 28px 0 0 73px;
      }
    }
  }
  .botton-text {
    p {
      font-size: 20px;
      color: #566884;
      font-family: "refont";
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        font-family: "refont";
        color: #566884;
        font-size: 20px;
        margin-bottom: 20px;
        img {
          width: 16px;
          height: 16px;
          margin-right: 13px;
        }
      }
    }
    .bold {
      color: #202124;
      font-family: "boldfont";
    }
  }
  .fade-in {
    animation: fadeIn 1.5s;
  }
  .fade-in2 {
    animation: fadeIn2 1.5s;
  }

  .fade-out {
    animation: fadeOut 1.5s;
  }
  .fade-out2 {
    animation: fadeOut2 1.5s;
  }

  @keyframes fadeIn {
    from {
      margin-left: 360px;
      opacity: 0;
    }
    to {
      margin-left: 0;
      opacity: 1;
    }
  }
  @keyframes fadeIn2 {
    from {
      margin-left: 0;
      opacity: 1;
    }
    to {
      margin-left: 360px;
      opacity: 0;
    }
  }

  @keyframes fadeOut {
    from {
      margin-left: 0;
      opacity: 1;
    }
    to {
      margin-left: -360px;
      opacity: 0;
    }
  }
  @keyframes fadeOut2 {
    from {
      margin-left: -360px;
      opacity: 0;
    }
    to {
      margin-left: 0;
      opacity: 1;
    }
  }
}
</style>