import Vue from "vue";
import {
  Button,
  Select,
  Carousel,
  CarouselItem,
  Menu,
  MenuItem,
  Submenu,
  Tabs,
  TabPane,
  Pagination,
  Drawer,
  Form,
  FormItem,  Timeline,
  TimelineItem,
  Input,
  Notification

} from "element-ui";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueRouter from "vue-router";
import "amfe-flexible";
// font引入
import "./assets/font/SourceHanSansCN-Bold.otf";
import "./assets/font/SourceHanSansCN-Medium.otf";
import "./assets/font/SourceHanSansCN-Regular.otf";
import VueLazyload from "vue-lazyload";
Vue.use(VueLazyload, {
  preLoad: 1.3, // 预加载的高度比例
  error: "", // 加载失败时显示的图片
  loading: "", // 加载时显示的图片
  attempt: 1, // 加载重试次数
});

// 关闭 Vue.js 在生产环境下的提示信息
Vue.config.productionTip = false;
Vue.use(Button);
Vue.use(Select);
Vue.use(Carousel); // 走马灯
Vue.use(CarouselItem); // 走马灯项
Vue.use(Menu); // 导航菜单
Vue.use(Submenu); // 导航菜单下拉项
Vue.use(MenuItem); // 导航菜单项
Vue.use(Tabs); // 标签页
Vue.use(TabPane); // 标签页项
Vue.use(Pagination); // 分页
Vue.use(Drawer); // 抽屉
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Timeline);
Vue.use(TimelineItem);
Vue.use(Input);
// Vue.use(Input);
Vue.prototype.$notify = Notification;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
