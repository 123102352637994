<template>
  <div
    style="
      width: 100%;
      background-color: #eef3fd;
      display: flex;
      flex-direction: column;
      align-items: center;
    "
  >
    <!-- 产品中心背景图 -->
    <div class="product-bg">
      <div class="product-fontBox">
        <p style="font-size: .0833rem; margin-top: .2875rem">隐算</p>
        <div class="product-font">加密芯片</div>
        <div class="back" @click="$router.go(-1)">
          <i
            class="el-icon-arrow-left"
            style="font-size: .2rem; margin: auto; color: #fff"
          ></i>
        </div>
      </div>
    </div>
    <!-- 产品介绍 -->
    <div class="product-introduce">
      <div class="introduce-top"></div>
      <div class="introduce-content">
        <div style="width: 2.75rem; display: inline-block; margin-right: .125rem">
          <h2 style="font-size: .1667rem; color: #000000;font-family: 'boldfont'">产品介绍</h2>
          <p style="font-size: .0833rem; color: #000;font-family:'refont'">
            隐算加密芯片是针对隐算全同态加密算法进行优化设计的专用集成电路，能够实现密文状态下进行计算而无需解密成明文，不仅保证了数据的安全隐私，还可
            使得多方在加密数据的基础上进行计算和协作
            ，在医疗、金融、科学研究等需要跨多个参与者合作处理敏感数据的场景具有极高价值
          </p>
        </div>
        <div
          style="
            width: 1.7917rem;
            height: 1.325rem;
            display: inline-block;
            vertical-align: middle;
          "
        >
          <img
            style="width: 100%; height: 100%"
            src="../assets/image/pro/product_4.jpg"
            alt=""
          />
        </div>
        <h2 style="font-size: .1667rem; color: #000000;font-family: 'boldfont'">应用场景</h2>
        <ul>
          <li>手机</li>
          <li>电脑</li>
          <li>汽车</li>
          <li>无人机</li>
          <li>摄像头等各种计算机终端</li>
        </ul>
      </div>
    </div>
  </div>
</template>
      
      <script>
export default {};
</script>
      
      <style lang="less" scoped>
.product-bg {
  color: #fff;
  width: 100%;
  height: 400px;
  background: url("../assets/image/pro/product-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  .product-fontBox {
    width: 1200px;
    margin: 0 auto;
  }
  .product-font {
    float: left;
    height: 96px;
    width: 100%;
    margin-top: -20px;
    font-size: 64px;
    letter-spacing: 8px;
  }
  .back {
    cursor: pointer;
    line-height: 80px;
    text-align: center;
    float: left;
    margin-top: 28px;
    width: 60px;
    height: 60px;
    background: rgba(89, 235, 255, 0.2);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid #59ebff;
  }
}
.product-introduce {
  width: 1200px;
  height: 910px;
  margin: 80px 0;
  background-color: #fff;
  border-radius: 20px 20px 20px 20px;
  box-sizing: border-box;
  .introduce-top {
    float: left;
    left: 0;
    width: 1200px;
    height: 40px;
    background: linear-gradient(117deg, #c9daeb 0%, #dcebfa 100%);
    border-radius: 20px 20px 0px 0px;
  }
  .introduce-content {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 80px 40px 70px;
    ul {
      margin: 0;
      padding: 0;
      li {
        font-size: 24px;
        text-align: center;
        box-sizing: border-box;
        float: left;
        margin-right: 30px;
        margin-bottom: 30px;
        width: 200px;
        height: 132px;
        line-height: 132px;
        background: linear-gradient(
          98deg,
          #eef3fd 0%,
          #f8f9fc 51%,
          #eef3fd 100%
        );
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
        border-radius: 12px 12px 12px 12px;
        opacity: 1;
        border: 2px solid;
        border-image: linear-gradient(
            236deg,
            rgba(232, 238, 243, 1),
            rgba(255, 255, 255, 0)
          )
          2 2;
      }
      li:nth-child(5n) {
        margin-right: 0;
      }
      li:last-child {
        line-height: 36px;
        padding: 30px 25px;
        box-sizing: border-box;
      }
    }
  }
}
</style>