import axios from "axios";

axios.withCredentials = true;
axios.defaults.withCredentials = true;
const service = axios.create({
  // baseURL: window.setting.baseURL,
  //   baseURL: window.apiHdUrl.url,
  // baseURL: '/api',
  // baseURL: 'http://192.168.0.39:8082',
  // baseURL: 'https://yinsuankeji.com',
  baseURL: process.env.VUE_APP_BASE_API,
  // baseURL: apiHdUrl,
  timeout: 60000,
  withCredentials: true,
});

service.interceptors.request.use(
  (config) => {
    // Cookies.set('uid', '1')/**/
    // console.log(config.url)
    // const Token = JSON.parse(window.localStorage.getItem('TOKEN'))
    // const newDATA = JSON.parse(window.localStorage.getItem('DATA'))
    // if (Token) {
    //   config.headers.token = Token
    //   // config.headers.token = Token
    //   // config.headers['Access-Control-Allow-Origin'] = true
    // }
    // if (newDATA) {
    //   config.headers.id = newDATA.userInfo.id
    //   // config.headers.username = encodeURIComponent(newDATA.userInfo.username) //编码中文
    //   // config.headers.token = Token
    //   // config.headers['Access-Control-Allow-Origin'] = true
    // }
    return config;
  },
  (error) => {
    console.log("请求失败:" + error);
    Promise.reject(error);
  }
);
// response 拦截器
service.interceptors.response.use((response) => {
  return response;
  // }
});
export default service;
