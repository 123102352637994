import request from './https/https.js'


// 招聘信息
// 获取招聘信息列表

export function acquirerecruit(page, size,status) {
    return request({
      url: 'website/recruit/' + page + '/' + size+'/'+status,
      method: 'get',
    })
  }