<template>
  <div
    style="
      width: 100%;
      background-color: #eef3fd;
      display: flex;
      flex-direction: column;
      align-items: center;
    "
  >
    <!-- 产品中心背景图 -->
    <div class="news-bg">
      <div class="news-fontBox">
        <div class="news-font">行业动态</div>
        <div class="back" @click="$router.go(-1)">
          <i
            class="el-icon-arrow-left"
            style="font-size: .2rem; margin: auto; color: #fff"
          ></i>
        </div>
      </div>
    </div>
    <!-- 产品介绍 -->
    <div class="news-news">
      <div class="news-top"></div>
      <div class="detailsOne-content">
        <h3 style="font-family: 'boldfont'">北京金融科技产业联盟发布</h3>
        <h3 style="font-family: 'boldfont'">《金融业隐私计算互联互通技术研究报告》</h3>
        <p class="textCenter">2023年05月06号</p>
        <p class="textIndent" style="margin: .25rem 0 .1667rem">
          近日，由中国银联股份有限公司牵头，以商业银行为主的金融机构、科技公司、互联网公司、电信运营商、检测机构和隐私计算开源社区等50家课题单位共同参与完成的<span
            style="font-family: 'boldfont'"
            >《金融业隐私计算互联互通技术研究报告》（以下简称“报告”）</span
          >，正式面向行业发布！
        </p>
        <div class="img-newsbox">
          <div style="width: 3.325rem; height: 1.7292rem">
            <img src="../assets/image/news/news-new4.jpg" alt="" />
            <img src="../assets/image/news/news-new1.jpg" alt="" />
          </div>
        </div>
        <p style="font-size: .0667rem" class="textCenter">
          《金融业隐私计算互联互通技术研究报告》正式发布
        </p>
        <p class="textIndent" style="margin: .1667rem 0 .125rem">
          本次大会将公开正式发布《隐私计算应用研究报告（2023）》、“隐私计算互联互通标准及第二批实践示范征集项目”以及《隐私计算应用
          面向政务场景的应用要求》、可信数据流通网络测试床等成果，还将进行首届“星河杯”隐私计算大赛颁奖典礼，并解读第八批可信隐私计算评估评测结果。
        </p>
        <p class="textIndent">
          隐私计算技术凭借其“可用不可见、可控可计量”特点在推动数据价值的流通与共享过程中发挥重要支撑作用。中国人民银行发布的《金融科技发展规划（2022—2025年）》（以下简称《规划》）指出，要探索建立跨主体数据安全共享隐私计算平台，在保障原始数据不出域前提下规范开展数据共享应用。
        </p>
        <p class="textIndent" style="margin: .125rem 0">
          北京金融科技产业联盟（以下简称“联盟”）数据专委会为落实《规划》要求，推动金融业数据要素在异构隐私计算平台间的有序流通共享，在2022年度组织设立了《金融业隐私计算互联互通技术研究报告》与《金融行业异构隐私计算互联互通平台技术规范》2项重点课题。
        </p>
        <p class="textIndent" style="margin: .125rem 0">
          2项课题均由中国银联股份有限公司牵头，以商业银行为主的金融机构、科技公司、互联网公司、电信运营商、检测机构和隐私计算开源社区等50家课题单位共同参与进行联合攻关，编制完成了《金融业隐私计算互联互通技术研究报告》。
        </p>
        <p class="textIndent" style="margin: 0 0 .1667rem">
          <span style="font-family: 'boldfont'">
            报告立足于行业级隐私计算互联互通，阐述了其内涵与推进思路。</span
          >在充分调研应用机构、技术提供方、检测机构等产业方诉求基础上，报告创新提出了<span
            style="font-family: 'boldfont'"
            >“管理面与数据面切分，管理面分模块定义，数据面逐步解耦”</span
          >核心理念的隐私计算互联互通统一框架方案。
        </p>
        <div class="img-newsbox" style="margin-top: .1667rem">
          <div style="width: 2.2958rem; height: 1.875rem">
            <img src="../assets/image/news/ecosystem.jpg" alt="" />
          </div>
        </div>
        <p style="font-size: .0667rem; margin-bottom: .1667rem" class="textCenter">
          行业级隐私计算互联互通示意
        </p>
        <p class="textIndent">
          课题在框架的基础上分解形成了<span style="font-family: 'boldfont'"
            >“管理面元素与接口研究”“流程调度接口与算法容器加载研究”“安全算子接口与服务研究”“传输接口与报文研究”“异构算法协议研究”“TEE互联互通技术研究”“互联互通生态体系研究”等7项子课题及10大关键技术点</span
          >，并进一步就互联互通落地后的产业生态进行展望及提出相应政策建议。
        </p>
        <div class="img-newsbox" style="margin-top: .1667rem">
          <div style="width: 2.2958rem; height: 1.875rem">
            <img src="../assets/image/news/Salvage.jpg" alt="" />
          </div>
        </div>
        <p style="font-size: .0667rem; margin-bottom: .1667rem" class="textCenter">
          行业级互联互通框架及研究点分解
        </p>
        <p class="textIndent">
          <span style="font-family: 'boldfont'">
            该报告在隐私计算互联互通研究领域具有领先地位，涵盖了隐私计算的各层级和技术路线，提出的互联互通框架具有普适性和可扩展性。</span
          >接下来，课题组将以该报告的研究成果为基础，通过技术和标准的双轮驱动，推动行业级互联互通统一框架的实施，并加快制定《金融行业异构隐私计算互联互通平台技术规范》的团体标准以及互联互通验证工作。与此同时，课题组将继续秉持“安全互通、兼容开放、生态共荣”的原则，发挥组织保障优势，加强与行业各方的协同合作，探索共建隐私计算互联互通生态，为金融数据要素的安全有序流动提供坚实保障。
        </p>
        <p class="textIndent" style="margin: .125rem 0">
          该报告的发布为金融行业的隐私计算互联互通研究提供了深度和广度的指导。它为行业提供了一个通用且可扩展的互联互通框架，并将通过技术和标准的推动来实现该框架的落地。随着该报告的成果得以应用，金融行业将迈向更安全、有序的数据流动，并进一步促进行业内的创新与发展。
        </p>
        <p>《金融业隐私计算互联互通技术研究报告》</p>
        <div style="width: 100%; margin-bottom: .1667rem">
          <img src="../assets/image/news/Report.jpg" alt="" />
        </div>
        <p style="margin-top: .1667rem">文章来源于微信公众号“开放隐私计算”</p>
        <p>关注公众号，后台回复关键词：20230506，即可下载完整版报告</p>
      </div>
    </div>
  </div>
</template>
          
          <script>
export default {};
</script>
          
          <style lang="less" scoped>
.news-bg {
  color: #fff;
  width: 100%;
  height: 400px;
  background: url("../assets/image/news-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  .news-fontBox {
    width: 1200px;
    margin: 0 auto;
  }
  .news-font {
    float: left;
    height: 96px;
    width: 100%;
    margin-top: 104px;
    font-size: 64px;
    letter-spacing: 8px;
  }
  .back {
    cursor: pointer;
    line-height: 80px;
    text-align: center;
    float: left;
    margin-top: 28px;
    width: 60px;
    height: 60px;
    background: rgba(89, 235, 255, 0.2);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid #59ebff;
  }
}
.news-news {
  width: 1200px;
  margin: 80px 0;
  background-color: #fff;
  border-radius: 20px 20px 20px 20px;
  box-sizing: border-box;
  .news-top {
    float: left;
    left: 0;
    width: 1200px;
    height: 40px;
    background: linear-gradient(117deg, #c9daeb 0%, #dcebfa 100%);
    border-radius: 20px 20px 0px 0px;
  }
  .detailsOne-content {
    width: 100%;
    height: 100%;
    padding: 78px 40px 80px;
    box-sizing: border-box;
    .img-newsbox {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      width: 100%;
      height: 100%;
    }
    h3 {
      text-align: center;
      font-size: 40px;
      width: 855px;
      margin: 0 auto;
    }
    p {
        font-family:'refont';
      font-size: 20px;
      margin: 16px 0;
    }
  }
}
.textCenter {
  text-align: center;
}
.textIndent {
  text-indent: 2em;
}
</style>