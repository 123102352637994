<template>
  <div class="bg">
    <div class="customization">
      <div class="left">
        <img src="../assets/image/Slice 2 (1).png" alt="" />
        <h2>新一代全同态隐私互联技术</h2>
        <span>定制化服务</span>
        <div class="text">
          <span style="margin-right: 23px">开创全密时代</span
          ><span>助力数字强国</span>
        </div>
      </div>
      <div class="right">
        <div class="one">
          <img src="../assets/image/Slice 2 (1).png" alt="" />
          <h2>方式一：联系我们</h2>
          <div class="title">联系方式：李女士 18310976836</div>
          <div class="title">邮箱地址：liran@yinsuankeji.com</div>
        </div>
        <h2>方式二：留下联系方式</h2>
        <div class="two">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            class="demo-ruleForm"
          >
            <el-form-item prop="messageName">
              <el-input
                v-model="ruleForm.messageName"
                placeholder="输入姓名"
              ></el-input>
            </el-form-item>
            <el-form-item prop="messagePhone">
              <el-input
                v-model="ruleForm.messagePhone"
                placeholder="输入手机号"
              ></el-input>
            </el-form-item>
            <el-form-item prop="messageCompanyName">
              <el-input
                v-model="ruleForm.messageCompanyName"
                placeholder="输入公司名称"
              ></el-input>
            </el-form-item>
            <el-form-item prop="messageContent">
              <el-input
                v-model="ruleForm.messageContent"
                placeholder="输入需求"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm('ruleForm')"
                >确认</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { websiteMsg } from "../api/patent";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      let phoneNumberRegex = /^1[3456789]\d{9}$/;
      if (phoneNumberRegex.test(value)) {
        callback();
      } else {
        callback(new Error("请输入正确的手机号！"));
      }
    };

    return {
      ruleForm: {
        messageName: null,
        messagePhone: null,
        messageCompanyName: null,
        messageContent: null,
      },
      rules: {
        messageName: [
          { required: true, message: "请输入姓名", trigger: "blur" },
        ],
        messagePhone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: validatePass, trigger: "blur" },
        ],

        messageCompanyName: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        messageContent: [
          { required: true, message: "请输入需求", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    // this.resetForm()
  },
  //   activated () {
  //     this.resetForm()
  //   },
  deactivated() {
    this.resetForm();
  },
  methods: {
    async websiteMsgBtn() {
      const data = await websiteMsg(this.ruleForm);
      console.log(data);
      if (data.data.code == 200) {
        // this.$message({
        //   message: '恭喜你，这是一条成功消息',
        //   type: 'success'
        // });
        // this.$message.success(data.data.message);
        console.log(data.data.message);
        this.$notify({
          title: "成功",
          message: data.data.message,
          type: "success",
        });
      } else {
        this.$notify.error({
          title: "错误",
          message: data.data.message,
        });
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$router.push("/home/HomePage");
          this.websiteMsgBtn();
        } else {
          console.log("error submit!!");
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
  },
  beforeDestroy() {
    this.resetForm();
  },
};
</script>

<style lang="less" scoped>
/* 在小屏幕上的样式 */
@media (max-width: 767px) {
  /* 添加适合小屏幕的样式 */
  .bg {
    width: 100%;
    height: calc(100%);
    background: #f3f4f5;
    display: flex;
    justify-content: center;
    align-items: center;
    .customization {
      display: flex;
      width: 100%;
      height: 100%;
      background: #fff;

      .left {
        display: none;
        position: relative;
        width: 550px;
        background: red;
        background: url("../assets/image/Slice 100.png") no-repeat;
        background-size: contain;
        img {
          position: absolute;
          left: 48px;
          top: 52px;
          width: 143px;
          height: 44px;
        }
        h2 {
          position: absolute;
          left: 48px;
          top: 184px;
          font-weight: 400;
          font-family: "boldfont";
          font-size: 32px;
          color: #202124;
          margin: 0;
        }
        > span {
          position: absolute;
          left: 48px;
          top: 250px;
          font-size: 20px;
          color: #202124;
          font-family: "boldfont";
        }
        .text {
          position: absolute;
          left: 48px;
          top: 312px;
          font-size: 16px;
          color: #566884;
          font-family: "refont";
        }
      }
      .right {
        padding: 123px 102px;
        box-sizing: border-box;
        width: 100%;
        background: #fff;
        form {
          margin-top: 61px;
          .el-button--primary {
            width:  100%;
            height: 246px;
            background: #016eff;
            border-radius: 20px 20px 20px 20px;
            font-size: 61px;
            color: #ffffff;
            margin-top: 82px;
            font-family: "boldfont";
          }
        }
        .one {
          margin-bottom: 133px;
          img {
            height: 128px;
            margin-bottom: 123px;
          }
        }
        h2 {
          color: #202124;
          margin: 0;
          font-weight: 400;
          font-family: "boldfont";
          font-size: 72px;
          color: #202124;
          line-height: 84px;
        }
        .title {
          width: 100%;
          height: 205px;
          line-height: 205px;
          font-size: 61px;
          color: #016eff;
          font-family: "myfont";
          background: #f4f8ff;
          border-radius: 20px 20px 20px 20px;
          margin-top: 61px;
          padding-left: 108px;
          box-sizing: border-box;
        }
        ::v-deep .el-input__inner {
          height: 205px;
          margin-top: 80px;
          font-size: 61px;
          text-indent: 108px;
        }
        ::v-deep .el-form-item__error {
          font-size: 61px;
          margin-left: 118px;
          margin-top: 20px;
        }
      }
    }
  }
}

/* 在中屏幕上的样式 */
@media (min-width: 768px) and (max-width: 1023px) {
  /* 添加适合中屏幕的样式 */
  .bg {
    width: 100%;
    height: calc(100%);
    background: #f3f4f5;
    display: flex;
    justify-content: center;
    align-items: center;
    .customization {
      display: flex;
      width: 1110px;
      height: 750px;
      background: #fff;

      .left {
        position: relative;
        width: 550px;
        background: red;
        background: url("../assets/image/Slice 100.png") no-repeat;
        background-size: contain;
        img {
          position: absolute;
          left: 48px;
          top: 52px;
          width: 143px;
          height: 44px;
        }
        h2 {
          position: absolute;
          left: 48px;
          top: 184px;
          font-weight: 400;
          font-family: "boldfont";
          font-size: 32px;
          color: #202124;
          margin: 0;
        }
        > span {
          position: absolute;
          left: 48px;
          top: 250px;
          font-size: 20px;
          color: #202124;
          font-family: "boldfont";
        }
        .text {
          position: absolute;
          left: 48px;
          top: 312px;
          font-size: 16px;
          color: #566884;
          font-family: "refont";
        }
      }
      .right {
        padding: 86px 96px 0 95px;
        box-sizing: border-box;
        width: 560px;
        background: #fff;
        form {
          margin-top: 16px;
          .el-button--primary {
            width: 368px;
            height: 48px;
            background: #016eff;
            border-radius: 4px 4px 4px 4px;
            font-size: 12px;
            color: #ffffff;
            font-family: "boldfont";
          }
        }
        .one {
          margin-bottom: 48px;
          img {
            display: none;
          }
        }
        h2 {
          font-size: 16px;
          color: #202124;
          margin: 0;
          font-weight: 400;
          font-family: "boldfont";
        }
        .title {
          width: 369px;
          height: 48px;
          line-height: 48px;
          font-size: 12px;
          color: #016eff;
          font-family: "myfont";
          background: #f4f8ff;
          border-radius: 4px 4px 4px 4px;
          margin-top: 16px;
          padding-left: 20px;
          box-sizing: border-box;
        }
      }
    }
  }
}

/* 在大屏幕上的样式 */
@media (min-width: 1024px) {
  /* 添加适合大屏幕的样式 */
  .bg {
    width: 100%;
    height: calc(100%);
    background: #f3f4f5;
    display: flex;
    justify-content: center;
    align-items: center;
    .customization {
      display: flex;
      width: 1110px;
      height: 750px;
      background: #fff;

      .left {
        position: relative;
        width: 550px;
        background: red;
        background: url("../assets/image/Slice 100.png") no-repeat;
        background-size: contain;
        img {
          position: absolute;
          left: 48px;
          top: 52px;
          width: 143px;
          height: 44px;
        }
        h2 {
          position: absolute;
          left: 48px;
          top: 184px;
          font-weight: 400;
          font-family: "boldfont";
          font-size: 32px;
          color: #202124;
          margin: 0;
        }
        > span {
          position: absolute;
          left: 48px;
          top: 250px;
          font-size: 20px;
          color: #202124;
          font-family: "boldfont";
        }
        .text {
          position: absolute;
          left: 48px;
          top: 312px;
          font-size: 16px;
          color: #566884;
          font-family: "refont";
        }
      }
      .right {
        padding: 86px 96px 0 95px;
        box-sizing: border-box;
        width: 560px;
        background: #fff;
        form {
          margin-top: 16px;
          .el-button--primary {
            width: 368px;
            height: 48px;
            background: #016eff;
            border-radius: 4px 4px 4px 4px;
            font-size: 12px;
            color: #ffffff;
            font-family: "boldfont";
          }
        }
        .one {
          margin-bottom: 48px;
          img {
            display: none;
          }
        }
        h2 {
          font-size: 16px;
          color: #202124;
          margin: 0;
          font-weight: 400;
          font-family: "boldfont";
        }
        .title {
          width: 369px;
          height: 48px;
          line-height: 48px;
          font-size: 12px;
          color: #016eff;
          font-family: "myfont";
          background: #f4f8ff;
          border-radius: 4px 4px 4px 4px;
          margin-top: 16px;
          padding-left: 20px;
          box-sizing: border-box;
        }
      }
    }
  }
}
</style>