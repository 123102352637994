import request from './https/https.js'
// 获取专利列表

export function acquirePatentInfo(page, size,patentStatus) {
    return request({
      url: 'website/patent/' + page + '/' + size+'/'+patentStatus,
      method: 'get',
    })
  }
  // 根据id获取专利信息
  export function patentInfo(patentId) {
    return request({
      url: 'website/patent/find/' + patentId,
      method: 'get',
    })
  }
  // 联系我们提交订单信息
  export function websiteMsg(data) {
    return request({
      url: '/website/msg',
      data: data,
      method: 'post',
    })
  }