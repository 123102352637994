<template>
  <div
    style="
      width: 100%;
      background-color: #eef3fd;
      display: flex;
      flex-direction: column;
      align-items: center;
    "
  >
    <!-- 产品中心背景图 -->
    <div class="product-bg">
      <div class="product-fontBox"></div>
    </div>
    <!-- 新闻主体 -->
    <div class="news-box">
      <div style="display: flex; justify-content: center">
        <ul>
          <li
            v-for="item in news"
            :key="item.newsId"
            @click="
              $router.push({
                path: '/home/NewDetailsOne',
                query: { id: item.newsId },
              })
            "
          >
            <!-- :src="window.location.host+item.newsPic" -->
            <div class="news-img" style="width: 100%">
              <!-- <img
                src="../assets/image/0135615ff4239c11013ee04d1a0599.jpg"
                style="width: 100%"
                alt=""
              /> -->

              <img :src="item.newsPic" style="width: 100%" alt="" />
            </div>
            <div class="news-content">
              <div style="font-size: 0.0583rem">
                <!-- 2023年 07月 14日 -->
                <span v-if="item.newsType == 0" class="blue">{{
                  newsTypeObj[item.newsType]
                }}</span>
                <span v-else class="green">{{
                  newsTypeObj[item.newsType]
                }}</span>
                <strong> {{ item.newsCreateDate.slice(0, 10) }}</strong>
              </div>
              <p style="">
                {{ item.newsTitle }}
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div class="block" style="margin: 0.25rem 0 0">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage1"
          next-text="下一页"
          prev-text="上一页"
          :page-size="12"
          layout=" prev, pager, next,total"
          :total="activeName == 'first' ? totalElements : totalElements2"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
      
<script>
import { newsList } from "../api/news.js";
export default {
  data() {
    return {
      newsTypeObj: {
        0: "行业新闻",
        1: "公司新闻",
      },
      totalElements: 0,
      totalElements2: 0,
      currentPage1: 1,
      activeName: "first",
      showNewsBox1: 5,
      conditions: {
        total: 0,
        page: 1,
        pageSize: 12,
        newsType: 0,
      },
      // 新闻列表数据
      news: [], // 行业动态
      news1: [], // 行业动态
      corporation: [], // 公司动态
    };
  },
  created() {
    this.conditions.page = 1;
    this.GetAllUserInfoList();
    this.newGetAllUserInfoList();
  },
  methods: {
    // 查询行业新闻列表
    async GetAllUserInfoList() {
      const { data } = await newsList(
        this.conditions.page,
        this.conditions.pageSize,
        1
      );
      this.news = data.data.content;
      this.totalElements = data.data.totalElements;
    },
    // 查询公司新闻列表
    async newGetAllUserInfoList() {
      const { data } = await newsList(
        this.conditions.page,
        this.conditions.pageSize,
        1
      );
      this.news1 = data.data.content;
      this.totalElements2 = data.data.totalElements;
    },
    handleClick(tab, event) {
      tab.name == "first"
        ? (this.conditions.newsType = 0)
        : (this.conditions.newsType = 1);
      // this.GetAllUserInfoList()
    },
    newsIn(e) {
      const liElement = e.target; // 获取当前点击的元素
      const index = Array.from(liElement.parentNode.children).indexOf(
        liElement
      ); // 获取当前元素在父元素中的索引
      //   console.log(index);
      this.showNewsBox1 = index;
    },
    newsOut() {
      this.showNewsBox1 = 5;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.conditions.page = val;
      this.GetAllUserInfoList();
      this.newGetAllUserInfoList();
      console.log(`当前页: ${val}`);
    },
  },
};
</script>
      
      <style lang="less" scoped>
/* 在小屏幕上的样式 */
@media (max-width: 767px) {
  /* 添加适合小屏幕的样式 */
  .product-bg {
    color: #fff;
    width: 100%;
    height: 2048px;
    background: url("../assets/image/news-new/Slice 80@2x (21).png");
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    .product-fontBox {
      width: 100%;
      margin: 0 auto;
    }
    .product-font {
      float: left;
      height: 96px;
      width: 100%;
      margin-top: 104px;
      font-size: 64px;
      letter-spacing: 8px;
    }
  }
  .news-box {
    width: 100%;
    padding: 0 82px;
    box-sizing: border-box;
    margin: 164px 0 100px;
    .news-content {
      width: 100%;
      // height: 208px;
      background-color: rgba(255, 255, 255, 0.2);
      margin-top: -6px;
      padding: 82px;
      box-sizing: border-box;
      span {
        display: inline-block;
        text-align: center;
        color: #fff;
        // width: 72px;
        // height: 26px;
        // line-height: 26px;
        font-family: "refont";
        border-radius: 6px 6px 6px 6px;
        margin-right: 61px;
        width: 410px;
        height: 143px;
        background: #3565fc;
        border-radius: 31px 31px 31px 31px;
        font-size: 61px;
        color: #ffffff;
        line-height: 143px;
        // line-height: 72px;
      }
      strong {
        font-size: 61px;
        color: #566884;
        font-family: 'refont';
      }
      p {
        font-size: 82px;
        color: #202124;
        margin: 0;
        font-family: "boldfont";
        margin-top: 87px;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* 控制显示行数 */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    ul {
      margin: 0;
      padding: 0;
      height: 5074px;
      overflow: auto;
      margin-bottom: 218px;
      li {
        cursor: pointer;
        // color: #000;
        overflow: hidden;
        // margin-right: 20px;
        // float: left;
        // background: rgba(255, 255, 255, 0.2);
        margin-bottom: 164px;
        // box-shadow: 0px 7px 7px 2px rgba(35, 44, 69, 0.15);
        // border-radius: 12px 12px 12px 12px;
        width: 100%;
        height: 1582px;
        background: #ffffff;
        // box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 20 67px 0px rgba(9, 19, 53, 0.1);
        border-radius: 61px 61px 61px 61px;
        box-sizing: border-box;
        p {
          display: -webkit-box;
          -webkit-line-clamp: 2; /* 控制显示行数 */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      li:hover {
        box-shadow: 0px 4px 17px 0px rgba(9, 19, 53, 0.35);
        transition: box-shadow 0.3s ease; /* 过渡动画属性 */
      }
      li:nth-child(4n) {
        margin-right: 0;
      }
      .news-img img {
        width: 1756px;
        height: 922px;
      }
      // li:hover .news-img img {
      //   height: 316px;
      //   transition: height 0.5s ease 0.2s;
      // }
      // li:hover {
      //   background-color: rgba(20, 80, 187, 1);
      //   color: #fff;
      // }
      // li:hover .news-content {
      //   background-color: rgba(20, 80, 187, 1);
      //   color: #fff;
      // }
    }
  }
  .block {
    display: none;
  }
  .blue {
    background: #3565fc;
  }
  .green {
    background: #25bbdd;
  }
  /deep/ .el-pagination {
    text-align: center;
    height: 40px;
    line-height: 40px;
  }
  /deep/ .el-pagination .btn-next .el-icon,
  .el-pagination .btn-prev .el-icon {
    font-size: 16px !important;
    //   .el-pagination button, .el-pagination span:not([class*=suffix]) {
    //     font-size: 16px;
    //   }
    //   .el-pagination /deep/ button:disabled {
    //     font-size: 16px;
    //   }
    /deep/ .btn-prev {
      font-size: 16px !important;
    }
    /deep/ .el-pagination button:disabled {
      font-size: 16px !important;
    }
    /deep/.el-pagination button {
      font-size: 16px !important;
    }
    .el-pagination button,
    .el-pagination /deep/ span:not([class*="suffix"]) {
      font-size: 16px !important;
    }
    [data-v-9c0eaf7a] .el-pagination button {
      font-size: 16px !important;
    }
  }
  /deep/.el-pager li {
    background-color: transparent;
    font-size: 16px;
    .active {
    }
  }
  /deep/ .el-pager li.active {
    // width: 28px;
    // height: 28px;
    // color: #fff;
    // background: #1450bb;
    // border-radius: 50px;
    // opacity: 1;
    // border: 1px solid #1450bb;
  }
  /deep/.el-pagination button {
    background-color: transparent;
  }
  /deep/.el-tabs__nav-wrap::after {
    height: 0;
  }
  /deep/.el-tabs__nav-scroll {
    display: flex;
    justify-content: center;
    height: 78px;
    line-height: 66px;
    box-sizing: border-box;
    //   .el-tabs__nav {
    //     margin: 0 auto;
    //   }
    .el-tabs__item {
      color: rgba(0, 0, 0, 0.4);
      font-size: 44px;
      height: 66px;
      line-height: 66px;
      box-sizing: border-box;
    }
    .el-tabs__item.is-active {
      color: rgba(0, 0, 0, 1);
    }
    .el-tabs__active-bar {
      width: 60px !important;
      height: 6px;
      background: #1450bb;
      border-radius: 4px 4px 4px 4px;
      left: 56px;
    }
  }
}

/* 在中屏幕上的样式 */
@media (min-width: 768px) and (max-width: 1023px) {
  /* 添加适合中屏幕的样式 */
  .product-bg {
    color: #fff;
    width: 100%;
    height: 550px;
    background: url("../assets/image/news-new/Slice 82 (2).jpg");
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    .product-fontBox {
      width: 1200px;
      margin: 0 auto;
    }
    .product-font {
      float: left;
      height: 96px;
      width: 100%;
      margin-top: 104px;
      font-size: 64px;
      letter-spacing: 8px;
    }
  }
  .news-box {
    width: 1200px;
    margin: 60px 0 100px;
    .news-content {
      width: 100%;
      // height: 208px;
      background-color: rgba(255, 255, 255, 0.2);
      margin-top: -6px;
      padding: 20px;
      box-sizing: border-box;
      span {
        display: inline-block;
        text-align: center;
        color: #fff;
        width: 72px;
        height: 26px;
        line-height: 26px;
        font-family: "refont";
        border-radius: 6px 6px 6px 6px;
        margin-right: 8px;
      }
      strong {
        font-size: 14px;
        color: #566884;
      }
      p {
        font-size: 16px;
        color: #202124;
        margin: 0;
        font-family: "boldfont";
        margin-top: 20px;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* 控制显示行数 */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        cursor: pointer;
        // color: #000;
        overflow: hidden;
        margin-right: 20px;
        float: left;
        // background: rgba(255, 255, 255, 0.2);
        margin-bottom: 20px;
        // box-shadow: 0px 7px 7px 2px rgba(35, 44, 69, 0.15);
        // border-radius: 12px 12px 12px 12px;
        width: 285px;
        height: 304px;
        background: #ffffff;
        // box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.15);
        border-radius: 12px 12px 12px 12px;
        box-sizing: border-box;
        p {
          display: -webkit-box;
          -webkit-line-clamp: 2; /* 控制显示行数 */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      li:hover {
        box-shadow: 0px 4px 17px 0px rgba(9, 19, 53, 0.35);
        transition: box-shadow 0.3s ease; /* 过渡动画属性 */
      }
      li:nth-child(4n) {
        margin-right: 0;
      }
      .news-img img {
        width: 285px;
        height: 152px;
      }
      // li:hover .news-img img {
      //   height: 316px;
      //   transition: height 0.5s ease 0.2s;
      // }
      // li:hover {
      //   background-color: rgba(20, 80, 187, 1);
      //   color: #fff;
      // }
      // li:hover .news-content {
      //   background-color: rgba(20, 80, 187, 1);
      //   color: #fff;
      // }
    }
  }
  .blue {
    background: #3565fc;
  }
  .green {
    background: #25bbdd;
  }
  /deep/ .el-pagination {
    text-align: center;
    height: 40px;
    line-height: 40px;
  }
  /deep/ .el-pagination .btn-next .el-icon,
  .el-pagination .btn-prev .el-icon {
    font-size: 16px !important;
    //   .el-pagination button, .el-pagination span:not([class*=suffix]) {
    //     font-size: 16px;
    //   }
    //   .el-pagination /deep/ button:disabled {
    //     font-size: 16px;
    //   }
    /deep/ .btn-prev {
      font-size: 16px !important;
    }
    /deep/ .el-pagination button:disabled {
      font-size: 16px !important;
    }
    /deep/.el-pagination button {
      font-size: 16px !important;
    }
    .el-pagination button,
    .el-pagination /deep/ span:not([class*="suffix"]) {
      font-size: 16px !important;
    }
    [data-v-9c0eaf7a] .el-pagination button {
      font-size: 16px !important;
    }
  }
  /deep/.el-pager li {
    background-color: transparent;
    font-size: 16px;
    .active {
    }
  }
  /deep/ .el-pager li.active {
    // width: 28px;
    // height: 28px;
    // color: #fff;
    // background: #1450bb;
    // border-radius: 50px;
    // opacity: 1;
    // border: 1px solid #1450bb;
  }
  /deep/.el-pagination button {
    background-color: transparent;
  }
  /deep/.el-tabs__nav-wrap::after {
    height: 0;
  }
  /deep/.el-tabs__nav-scroll {
    display: flex;
    justify-content: center;
    height: 78px;
    line-height: 66px;
    box-sizing: border-box;
    //   .el-tabs__nav {
    //     margin: 0 auto;
    //   }
    .el-tabs__item {
      color: rgba(0, 0, 0, 0.4);
      font-size: 44px;
      height: 66px;
      line-height: 66px;
      box-sizing: border-box;
    }
    .el-tabs__item.is-active {
      color: rgba(0, 0, 0, 1);
    }
    .el-tabs__active-bar {
      width: 60px !important;
      height: 6px;
      background: #1450bb;
      border-radius: 4px 4px 4px 4px;
      left: 56px;
    }
  }
}

/* 在大屏幕上的样式 */
@media (min-width: 1024px) {
  /* 添加适合大屏幕的样式 */
  .product-bg {
    color: #fff;
    width: 100%;
    height: 550px;
    background: url("../assets/image/news-new/Slice 82 (2).jpg");
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    .product-fontBox {
      width: 1200px;
      margin: 0 auto;
    }
    .product-font {
      float: left;
      height: 96px;
      width: 100%;
      margin-top: 104px;
      font-size: 64px;
      letter-spacing: 8px;
    }
  }
  .news-box {
    width: 1200px;
    margin: 60px 0 100px;
    .news-content {
      width: 100%;
      // height: 208px;
      background-color: rgba(255, 255, 255, 0.2);
      margin-top: -6px;
      padding: 20px;
      box-sizing: border-box;
      span {
        display: inline-block;
        text-align: center;
        color: #fff;
        width: 72px;
        height: 26px;
        line-height: 26px;
        font-family: "refont";
        border-radius: 6px 6px 6px 6px;
        margin-right: 8px;
      }
      strong {
        font-size: 14px;
        color: #566884;
      }
      p {
        font-size: 16px;
        color: #202124;
        margin: 0;
        font-family: "boldfont";
        margin-top: 20px;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* 控制显示行数 */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        cursor: pointer;
        // color: #000;
        overflow: hidden;
        margin-right: 20px;
        float: left;
        // background: rgba(255, 255, 255, 0.2);
        margin-bottom: 20px;
        // box-shadow: 0px 7px 7px 2px rgba(35, 44, 69, 0.15);
        // border-radius: 12px 12px 12px 12px;
        width: 285px;
        height: 304px;
        background: #ffffff;
        // box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.15);
        border-radius: 12px 12px 12px 12px;
        box-sizing: border-box;
        p {
          display: -webkit-box;
          -webkit-line-clamp: 2; /* 控制显示行数 */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      li:hover {
        box-shadow: 0px 4px 17px 0px rgba(9, 19, 53, 0.35);
        transition: box-shadow 0.3s ease; /* 过渡动画属性 */
      }
      li:nth-child(4n) {
        margin-right: 0;
      }
      .news-img img {
        width: 285px;
        height: 152px;
      }
      // li:hover .news-img img {
      //   height: 316px;
      //   transition: height 0.5s ease 0.2s;
      // }
      // li:hover {
      //   background-color: rgba(20, 80, 187, 1);
      //   color: #fff;
      // }
      // li:hover .news-content {
      //   background-color: rgba(20, 80, 187, 1);
      //   color: #fff;
      // }
    }
  }
  .blue {
    background: #3565fc;
  }
  .green {
    background: #25bbdd;
  }
  /deep/ .el-pagination {
    text-align: center;
    height: 40px;
    line-height: 40px;
  }
  /deep/ .el-pagination .btn-next .el-icon,
  .el-pagination .btn-prev .el-icon {
    font-size: 16px !important;
    //   .el-pagination button, .el-pagination span:not([class*=suffix]) {
    //     font-size: 16px;
    //   }
    //   .el-pagination /deep/ button:disabled {
    //     font-size: 16px;
    //   }
    /deep/ .btn-prev {
      font-size: 16px !important;
    }
    /deep/ .el-pagination button:disabled {
      font-size: 16px !important;
    }
    /deep/.el-pagination button {
      font-size: 16px !important;
    }
    .el-pagination button,
    .el-pagination /deep/ span:not([class*="suffix"]) {
      font-size: 16px !important;
    }
    [data-v-9c0eaf7a] .el-pagination button {
      font-size: 16px !important;
    }
  }
  /deep/.el-pager li {
    background-color: transparent;
    font-size: 16px;
    .active {
    }
  }
  /deep/ .el-pager li.active {
    // width: 28px;
    // height: 28px;
    // color: #fff;
    // background: #1450bb;
    // border-radius: 50px;
    // opacity: 1;
    // border: 1px solid #1450bb;
  }
  /deep/.el-pagination button {
    background-color: transparent;
  }
  /deep/.el-tabs__nav-wrap::after {
    height: 0;
  }
  /deep/.el-tabs__nav-scroll {
    display: flex;
    justify-content: center;
    height: 78px;
    line-height: 66px;
    box-sizing: border-box;
    //   .el-tabs__nav {
    //     margin: 0 auto;
    //   }
    .el-tabs__item {
      color: rgba(0, 0, 0, 0.4);
      font-size: 44px;
      height: 66px;
      line-height: 66px;
      box-sizing: border-box;
    }
    .el-tabs__item.is-active {
      color: rgba(0, 0, 0, 1);
    }
    .el-tabs__active-bar {
      width: 60px !important;
      height: 6px;
      background: #1450bb;
      border-radius: 4px 4px 4px 4px;
      left: 56px;
    }
  }
}
</style>
