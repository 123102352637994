<template>
  <div
    style="
      width: 100%;
      background-color: #eef3fd;
      display: flex;
      flex-direction: column;
      align-items: center;
    "
  >
    <!-- 产品中心背景图 -->
    <div class="news-bg">
      <div class="news-fontBox">
        <div class="news-font">行业动态</div>
        <div class="back" @click="$router.go(-1)">
          <i
            class="el-icon-arrow-left"
            style="font-size: .2rem; margin: auto; color: #fff"
          ></i>
        </div>
      </div>
    </div>
    <!-- 产品介绍 -->
    <div class="news-news">
      <div class="news-top"></div>
      <div class="detailsOne-content">
        <h3 style="font-family: 'boldfont'">《可信数据流通网络（TDN）白皮书》启动会在京举办</h3>
        <p class="textCenter">2023年05月19号</p>
        <p class="textIndent" style="margin: .25rem 0 .1667rem">
          5月17日下午，中国信通院云计算与大数据研究所、隐私计算联盟、中国通信标准化协会大数据技术标准推进委员会（CCSA
          TC601）WG3数据流通工作组，在京联合组织召开《可信数据流通网络（TDN）白皮书》启动会。来自中国移动、联通数科、天翼电商、国网数科、北数所、浦发银行、工行软开、中信银行、青岛智研院、蚂蚁集团、阿里云、京东、腾讯、华为、洞见科技、富数科技、华控清交、数牍科技、翼方健数、金智塔、京信数科、天枢数链、数秦科技、同盾科技、航天信息等30多家企业的50余名专家共同围绕数据流通的现状、可信数据流通网络总体框架、可信流通技术和应用场景等进行了分享和研讨。
        </p>
        <div class="img-newsbox">
          <div style="width: 3.325rem; height: 1.7292rem">
            <img src="../assets/image/news/news-new2.png" alt="" />
          </div>
        </div>
        <p class="textIndent" style="margin: .2083rem 0 .125rem;">
          中国信通院介绍到，在政策和市场的驱动下，数据流通作为释放数据要素价值的关键一环，需求日益强烈。当前隐私计算、区块链、数据沙箱等可信数据流通技术快速发展，数据交易所、数据运营企业等数据流通场所和服务商不断增多，可信数据流通应用场景和生态逐渐丰富，构建可信数据流通网络的基础条件已经成熟。针对公共数据、企业数据等不同的数据流通模式，通过梳理可信数据流通网络的目标、愿景、核心原则，基于动态的网络形态，连接数据流通中各个参与方，实现数据安全合规流通，具有重要意义。
        </p>
        <p class="textIndent">
            为激活数据价值，保障数据安全合规流通，增强数据的可用、可信、可流通、可追溯水平，可信数据流通网络在互认、互信、互联的原则下“织点成域、联域成网”，构建跨区域、跨行业、跨主体的数据流通基础设施。在通信网络、算力网络的基础上，可信数据流通网络通过连接数据提供方、数据使用方、技术平台提供方、数据交易方以及监管方等各方，实现数据资源的可信登记接入、安全受控流转和全网开放互联互通。
        </p>
        <p class="textIndent" style="margin: .125rem 0;">
            会上对白皮书框架进行了介绍，包括概述、总体框架、技术功能框架、数据流通模式、应用场景、发展展望和行业实践案例参考等内容。与会的行业应用方、基础运营商、数据交易所、公共数据授权运营单位、数据流通技术平台企业等的专家分别对数据流通面临的问题（如确权、安全合规流通的过程、收益分配等）、数据流通中各参与方的角色、数据保护与管理机制、不同的流通模式、应用场景等问题进行讨论，并对白皮书内容提出了宝贵建议。
        </p>
        <p class="textIndent">
            下一步，中国信通院云大所、隐私计算联盟、中国通信标准化协会大数据技术标准推进委员会将进一步协同行业各方明晰可信数据流通网络（TDN）的概念范围,搭建TDN总体框架，构建可信流通技术体系，为建立集约高效的数据流通基础设施提供规范指引，推动国家级可信数据网络建设。
        </p>
        <ul style="margin: 30px 0 0;padding: 0;font-size: .0833rem;">
            <li>联系人：</li>
            <li>中国信通院云计算与大数据研究所</li>
            <li>白老师</li>
            <li>18810275013</li>
            <li>baiyuzhen@caict.ac.cn</li>
        </ul>
        <p style="margin-top: .1667rem">文章来源于微信公众号“隐私计算联盟”</p>
      </div>
    </div>
  </div>
</template>
          
          <script>
export default {};
</script>
          
          <style lang="less" scoped>
.news-bg {
  color: #fff;
  width: 100%;
  height: 400px;
  background: url("../assets/image/news-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  .news-fontBox {
    width: 1200px;
    margin: 0 auto;
  }
  .news-font {
    float: left;
    height: 96px;
    width: 100%;
    margin-top: 104px;
    font-size: 64px;
    letter-spacing: 8px;
  }
  .back {
    cursor: pointer;
    line-height: 80px;
    text-align: center;
    float: left;
    margin-top: 28px;
    width: 60px;
    height: 60px;
    background: rgba(89, 235, 255, 0.2);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid #59ebff;
  }
}
.news-news {
  width: 1200px;
  margin: 80px 0;
  background-color: #fff;
  border-radius: 20px 20px 20px 20px;
  box-sizing: border-box;
  .news-top {
    float: left;
    left: 0;
    width: 1200px;
    height: 40px;
    background: linear-gradient(117deg, #c9daeb 0%, #dcebfa 100%);
    border-radius: 20px 20px 0px 0px;
  }
  .detailsOne-content {
    width: 100%;
    height: 100%;
    padding: 78px 40px 80px;
    box-sizing: border-box;
    .img-newsbox {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      width: 100%;
      height: 100%;
    }
    h3 {
      text-align: center;
      font-size: 40px;
      margin: 0 auto;
    }
    p {
        font-family:'refont';
      font-size: 20px;
      margin: 16px 0;
    }
  }
}
.textCenter {
  text-align: center;
}
.textIndent {
  text-indent: 2em;
}
</style>